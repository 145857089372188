import React, {Component} from "react";
import {useState} from "react";
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import Logo_Register from "../../assets/images/logo-vector.svg";
import UserService from "../../repository/userRepository";
import ErrorMessage from "../Log_in/ErrorMessage";

const SetNewPassword = () => {
    const [newPassword, setNewPassword] = useState({
        newPassword: "",
        confirmNewPassword: "",
        email:"",
    })

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setNewPassword({...newPassword, [name]: value})
    }

    const handleSetNewPassword = () => {
        if (newPassword.newPassword !== newPassword.confirmNewPassword) {
            alert("Passwords do not match!");
        } else {
            UserService.resetNewPassword(newPassword.email, newPassword.newPassword, newPassword.confirmNewPassword).then(() => {
                window.location.href = "/"

            });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }


    return (
        <div className="login">
            <div className="container container_login">
                <div className="row">
                    <div className="col" id="login_main_div">
                        <Card id="login_card">
                            <div className="row">
                                <CardHeader title={"Set New Password"} id="login_header"/>
                            </div>
                            <CardBody className="login_card">
                                <form className="register_form" onSubmit={handleSubmit}>
                                    <div className="row mt-4">
                                        <input
                                            type="email"
                                            className=" form-control register_inputs"
                                            placeholder="Enter email"
                                            name="email"
                                            value={newPassword.email}
                                            onChange={handleInput}
                                            required
                                        />
                                    </div>
                                    <div className="row mt-4">
                                        <input
                                            type="password"
                                            className=" form-control register_inputs"
                                            placeholder="Enter New Password"
                                            name="newPassword"
                                            value={newPassword.newPassword}
                                            onChange={handleInput}
                                            required
                                        />
                                    </div>
                                    <div className="row mt-4">
                                        <input
                                            type="password"
                                            className=" form-control register_inputs"
                                            placeholder="Confirm New Password"
                                            name="confirmNewPassword"
                                            value={newPassword.confirmNewPassword}
                                            onChange={handleInput}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <button type="submit" className="form-control" id="login_button"
                                                onClick={handleSetNewPassword}>
                                            Set Password
                                        </button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col">
                        <img src={Logo_Register} alt="" id="login_logo"/>{" "}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetNewPassword;
