import CardHeader from "@material-ui/core/CardHeader";
import React, { useEffect, useState } from 'react';
import {
    Card, Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {CardBody} from "reactstrap";
import PartsService from "../../repository/partsRepository";
import Select from 'react-select'
import "./PartExpenses.css";
import ExpensesService from "../../repository/partExpensesRepository";
import ConfirmModal from "../PartValidation/ConfirmModal";
import ProjectsService from "../../repository/projectsRepository";
import DeleteExpenses from "./DeleteExpenses";
import Alert from "react-bootstrap/Alert";
import {BiImport} from "react-icons/bi";


const PartValidation = props => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filterValue, setFilterValue] = useState('')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const [openedHeight, setOpenedHeight] = React.useState(0);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [filteredExpenses, setFilteredExpenses] = React.useState([]);
    const [allExpenses, setAllExpenses] = React.useState([]);
    const [selectedProjectId, setSelectedProjectId] = React.useState(0);
    const [allProjects, setAllProjects] = React.useState([]);
    const refreshContent = () => {
        const email = localStorage.getItem('email');
        const role = localStorage.getItem('role');
        if (selectedProjectId !== 0) {
            ExpensesService.getPartExpensesByProjectId(selectedProjectId, role, email).then((partExpenses) => {
                if (partExpenses.data.length !== 0) {
                    filterTable(null, partExpenses.data)
                    setAllExpenses(partExpenses.data);
                } else {
                    setFilteredExpenses([]);
                    setAllExpenses([]);
                }
            });
        }
    }
    const getAllProjects = () => {
        ProjectsService.fetchProjects().then(p => {
            setAllProjects(p.data);
        })
    }


    useEffect(() => {
        if (filteredExpenses.length === 0) {
            refreshContent();
        }
        if (allProjects.length === 0) {
            getAllProjects();
        }
    }, [filteredExpenses, allProjects])


    const [options, setOptions] = React.useState([]);


    const filterTable = (e, expenses) => {
        const value = e?.target?.value ?? filterValue;
        setFilterValue(value)
        const newExpenses = expenses ?? allExpenses
        if (value === null || value === "") {
            setFilteredExpenses(newExpenses);
            setPage(page !== 0 ? page : 0)
        } else {
            let uniList = []
            newExpenses.map((uni) => {
                if (uni.ifesFoCode !== null) {
                    if (uni.ifesFoCode.toLowerCase().includes(value?.toLowerCase()) ||
                        (uni.date !== null && uni.date.toString().toLowerCase().includes(value.toLowerCase())) ||
                        (uni.details !== null && uni.details.toString().toLowerCase().includes(value.toLowerCase()))
                    ) {
                        uniList.push(uni)
                    }
                }
            })
            if (uniList.length > 0) {
                setFilteredExpenses(uniList);
            } else {
                setFilteredExpenses([""]);
            }
            setPage(0);
        }
    }

    const handleSelectChange = (value, action) => {
        if (value.length > 0) {
            const partId = value[0].value;
            const expenseId = action.name;
            ExpensesService.connectExpenseWithSelectedPart(parseInt(expenseId), parseInt(partId)).then(() => {

                refreshContent();
            })

        }
    }

    const selectProject = (e) => {
        const id = e.target.value;
        setSelectedProjectId(id);
        let fillTheParts = [];
        const role = localStorage.getItem('role');
        const email = localStorage.getItem('email');
        if (role === "ROLE_ADMIN") {
            PartsService.getPartsForPartExpensesChoosePart(id, role, email).then((snapshot) => {
                snapshot.data.forEach((part) => {
                    let label = "";
                    if (part.ifesCode !== null && part.ifesCode !== "" && part.ifesCode !== undefined) {
                        label = part.ifesCode + "-" + part.description;
                    } else {
                        label = part.description;
                    }
                    fillTheParts.push({value: part.id, label: label});
                });
                setOptions(fillTheParts);
            })
            ExpensesService.getPartExpensesByProjectId(id, role, email).then((partExpenses) => {
                setFilteredExpenses(partExpenses.data);
                setAllExpenses(partExpenses.data);
            });
        } else if (role === "ROLE_PARTNER") {
            PartsService.getPartsForPartExpensesChoosePart(id, role, email).then((snapshot) => {
                snapshot.data.forEach((part) => {
                    let label = "";
                    if (part.ifesCode !== null && part.ifesCode !== "" && part.ifesCode !== undefined) {
                        label = part.ifesCode + "-" + part.description;
                    } else {
                        label = part.description;
                    }
                    fillTheParts.push({value: part.id, label: label});
                });
                setOptions(fillTheParts);
            })
            ExpensesService.getPartExpensesByProjectId(id, role, email).then((partExpenses) => {
                setFilteredExpenses(partExpenses.data);
                setAllExpenses(partExpenses.data);
            });
        }
    }

    const handleRemovePartForExpense = (e) => {
        const id = e.target.id;
        const role = localStorage.getItem('role');
        if (role === "ROLE_ADMIN") {
            ExpensesService.removePartForExpense(id).then(() => {
                refreshContent();
            });
        }
    }

    const [variant, setVariant] = React.useState('success');

    const handleImportLatestPartExpenses = (id, role, creator) => {
        ExpensesService.importLatestPartExpenses(id, role, creator).then(() => {
            // window.location.href = "/expenses";
            handleCloseConfirmModal();
            refreshContent();
        }).catch(error => {
            console.error(error);
            showAlertMessage('Invalid date(s) or missing exchange course set by date(s)', 'danger');
        })
    }

    const handleImportLatestInternalPartExpenses = (id) => {
        ExpensesService.importLatestInternalPartExpenses(id).then(() => {
            // window.location.href = "/expenses";
            handleCloseConfirmModal();
            refreshContent();
        }).catch(error => {
            console.error(error);
            showAlertMessage('Invalid date(s) or missing exchange course set by date(s)', 'danger');
        })
    }

    const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
    const [id, setId] = React.useState();

    const handleCloseConfirmModal = () => {
        setOpenConfirmModal(false);
    };
    const handleOpenConfirmModal = (e) => {
        setId(e.target.id);
        setOpenConfirmModal(true);
    }

    const confirmModal = (
        <ConfirmModal projectId={selectedProjectId} id={id} handleCloseConfirmModal={handleCloseConfirmModal}
                      handleImportLatestPartExpenses={handleImportLatestPartExpenses}
                      handleImportLatestInternalPartExpenses={handleImportLatestInternalPartExpenses}/>
    );

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            textAlign: "left",
        }),

    }

    let userRole = localStorage.getItem('role');

    const [openDeleteExpenses, setOpenDeleteExpenses] = React.useState(false);
    const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
    const [alertMessage, setAlertMessage] = React.useState(false);
    const [alertMessageText, setAlertMessageText] = React.useState("");

    const showAlertMessage = (message, variant) => {
        let a;
        setVariant(variant);
        setAlertMessageText(message);
        setAlertMessage(true);
        if (clearPreviousTimeout !== undefined) {
            clearTimeout(clearPreviousTimeout);
        }
        a = setTimeout(() => {
            setAlertMessage(false)
        }, 2000);
        setClearPreviousTimeout(a);
    }

    const handleOpenDeleteExpenses = (e) => {
        let id = e.target.value;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.value;
        }
        setId(id);
        setOpenDeleteExpenses(true);
    };
    const handleCloseDeleteExpenses = () => {
        setOpenDeleteExpenses(false);
    };

    const deleteRefreshContent = () => {
        const email = localStorage.getItem('email');
        const role = localStorage.getItem('role');
        if (selectedProjectId !== 0) {
            ExpensesService.getPartExpensesByProjectId(selectedProjectId, role, email).then((partExpenses) => {
                if (partExpenses.data.length !== 0) {
                    setFilteredExpenses(partExpenses.data);
                    setAllExpenses(partExpenses.data);
                } else {
                    setFilteredExpenses([]);
                    setAllExpenses([]);
                }
            });
        }
    }

    const expensesDelete = (
        <DeleteExpenses
            id={id}
            refreshContent={deleteRefreshContent}
            handleCloseDeleteExpenses={handleCloseDeleteExpenses} showAlertMessage={showAlertMessage}
        />
    )

    return (
        <div id="table">
            <Modal
                style={{width: "50%", margin: "auto"}}
                open={openConfirmModal}
                onClose={handleCloseConfirmModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">

                {confirmModal}
            </Modal>
            <Modal
                style={{width: "50%", margin: "auto"}}
                open={openDeleteExpenses}
                onClose={handleCloseDeleteExpenses}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {expensesDelete}
            </Modal>
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded" id="mainFormAddPart">
                        <div className="first_line">
                            <CardHeader title={"Expenses management"} id="list_projects"/>
                            <Alert id="alert-success" variant={variant} show={alertMessage}>
                                <p>{alertMessageText}</p>
                            </Alert>
                        </div>

                        <div className="col-6">
                            <label>Select project</label> <br/>
                            <select className="form-select" onChange={selectProject}>
                                <option value={0} selected>---------</option>
                                {allProjects.map((project) => {

                                    return (
                                        <option value={project.id}>{project.name}</option>
                                    )

                                })}
                            </select>
                        </div>
                        <br/>
                        {userRole === "ROLE_ADMIN" ?
                            <div style={{display: "flex", float: "right", alignItems: "center"}}>
                                <button style={{marginRight: 10}} className="btn btn-outline-success"
                                        onClick={handleOpenConfirmModal} id="partInternalExpenses"><BiImport/> Load the
                                    latest
                                    internal imported expenses
                                </button>

                                <button style={{marginRight: 10}} className="btn btn-outline-success"
                                        onClick={handleOpenConfirmModal} id="partExpenses"><BiImport/> Load the latest
                                    external imported expenses
                                </button>
                            </div> :
                            <div style={{display: "flex", float: "right", alignItems: "center"}}>
                                <button style={{marginRight: 10}} className="btn btn-outline-success"
                                        onClick={handleOpenConfirmModal} id="partExpenses"><BiImport/> Load the latest
                                    external imported expenses
                                </button>
                            </div>
                        }
                        <CardBody>
                            <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>
                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns">
                                    <TableContainer>
                                        <Table style={{height: {openedHeight}}} id="universityTable"
                                               className="table tableCustom">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Date</TableCell>
                                                    <TableCell data-priority="3">IFES Key - Part</TableCell>
                                                    <TableCell data-priority="3">Part Code</TableCell>
                                                    <TableCell data-priority="3">Description</TableCell>
                                                    <TableCell data-priority="3">Expense MKD</TableCell>
                                                    <TableCell data-priority="3">Expense USD</TableCell>
                                                    <TableCell data-priority="3">Expense CHF</TableCell>
                                                    <TableCell data-priority="3">Delete</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredExpenses[0] === "" && filteredExpenses.length === 1 ?
                                                    <div/> :
                                                    filteredExpenses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                        return (
                                                            <TableRow className="innerRow">
                                                                <TableCell className="innerCell"
                                                                           style={{width: "90px"}}>{term.date}</TableCell>
                                                                <TableCell className="innerCell">
                                                                    {term.part === null ?
                                                                        <Select onChange={handleSelectChange}
                                                                                name={term.id} required isMulti
                                                                                options={options}
                                                                                className="selectPart"
                                                                                styles={customStyles}
                                                                        /> :
                                                                        <div className="partExistsDiv" id={term.id}
                                                                             onClick={handleRemovePartForExpense}> {term.ifesCode ? term.ifesCode + "-" + term.part.description : term.part.description}</div>}
                                                                </TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.ifesFoCode}</TableCell>
                                                                <TableCell className="innerCell">
                                                                    {term.details}
                                                                </TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.expense_mkd}</TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.expense_usd}</TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.expense_chf}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <button className="btn btn-danger"
                                                                            value={term.id}
                                                                            onClick={handleOpenDeleteExpenses}
                                                                            style={{display: "flex", alignItems: "center"}}>
                                                                        <i className="fa fa-trash-o"/>&nbsp;Delete
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                            page={page}
                                            count={filteredExpenses[0] === "" && filteredExpenses.length === 1 ? 0 : filteredExpenses.length}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}
export default PartValidation
