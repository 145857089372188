import React, {Component} from "react";
import "./Import.css";
import {AiOutlinePaperClip} from "react-icons/ai";
import {Card} from "reactstrap";
import ImportService from "../../repository/importRepository";
import ProjectsService from "../../repository/projectsRepository";
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@material-ui/core";


const Import = props => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const [hasFiles, setHasFiles] = React.useState(false);
    const [finalChoice, setFinalChoice] = React.useState(false);
    const [typeUpload, setTypeUpload] = React.useState("");
    const [projectId, setProjectId] = React.useState(0);
    const [fileUpload, setFile] = React.useState(null);


    const handleUpload = (files) => {
        const files_target = files.target.files;
        if (files_target.length > 0) {
            setHasFiles(true);
            setFile(files);
        }
    }

    const selectType = () => {
        document.getElementById("selectTypeRow").style.display = "";
        refreshContent();
    }

    const confirmUponTypeSelection = (e) => {
        const select = e.target.value;
        setTypeUpload(select);
        document.getElementById("selectProjectRow").style.display = "";
        // if (select === "project_expanses") {
        //     setFinalChoice(true);
        // } else {
        //     setFinalChoice(false);
        //
        // }
    }

    const handleProjectSelection = (e) => {
        setFinalChoice(true);
        setProjectId(e.target.value);
    }

    const finalizeInput = () => {
        const creator = localStorage.getItem('username') + " (" + localStorage.getItem('email') + ")";
        const role = localStorage.getItem('role');
        const form = new FormData();
        form.append("file_arr", fileUpload.target.files[0]);
        form.append("projectId", projectId);
        form.append("type", typeUpload);
        form.append("creator", creator);
        form.append("role", role);
        ImportService.createImport(form).then(() => {
            window.location.href = "/import"
        });
    }
    const [projects, setProjects] = React.useState([]);
    const refreshContent = () => {
        ProjectsService.fetchProjects().then((projects) => {
            setProjects(projects.data);
        })
    }
    const [existingImports, setExistingImports] = React.useState([]);
    const [isFiltered, setIsFiltered] = React.useState(false);
    const filterFiles = (e) => {
        const typeOfFilter = e.target.name;
        let updateExistingImports = [];
        const type = document.getElementById("type").value.toString();
        const project = document.getElementById("project").value.toString();
        const status = document.getElementById("status").value.toString();
        if (project !== "0" && type !== "0" && status !== "0") {
            updateExistingImports = props.existingImports.filter(item => item.projectId === project && item.type === type && item.status === status);
        } else if (project !== "0" && type !== "0" && status === "0") {
            updateExistingImports = props.existingImports.filter(item => item.projectId === project && item.type === type);
        } else if (project !== "0" && type === "0" && status !== "0") {
            updateExistingImports = props.existingImports.filter(item => item.projectId === project && item.status === status);
        } else if (project !== "0" && type === "0" && status === "0") {
            updateExistingImports = props.existingImports.filter(item => item.projectId === project);
        } else if (project === "0" && type !== "0" && status !== "0") {
            updateExistingImports = props.existingImports.filter(item => item.type === type && item.status === status);
        } else if (project === "0" && type !== "0" && status === "0") {
            updateExistingImports = props.existingImports.filter(item => item.type === type);
        } else if (project === "0" && type === "0" && status !== "0") {
            updateExistingImports = props.existingImports.filter(item => item.status === status);
        } else if (project === "0" && type === "0" && status === "0") {
            updateExistingImports = props.existingImports;
        }
        setExistingImports(updateExistingImports);
        setIsFiltered(true);
    }
    let userRole = localStorage.getItem('role');

    return (
        <div id="table">
            <div className="row mt-5">
                <div className="col-4 mainCol">
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded" id="mainFormAddPart">
                        <div className="import_container p-3">
                            <h2 className="header_import">{<AiOutlinePaperClip/>}Import files</h2>
                            {userRole === "ROLE_ADMIN" ?
                                <div style={{display: "block"}}>
                                    <a download
                                       href={process.env.REACT_APP_HOST_ENV + "/imports/downloadProjectStructureTemplate/" + props.lastProjectStructureTemplate}
                                       className="btn btn-outline-success btn-sm" style={{width: "200px"}}>Last
                                        Project Structure Template</a>
                                    <a download
                                       href={process.env.REACT_APP_HOST_ENV + "/imports/downloadExternalExpensesTemplate/" + props.lastExternalImportTemplate}
                                       className="btn btn-outline-success btn-sm" style={{width: "200px"}}>Last
                                        External Import Template</a>
                                    <a download
                                       href={process.env.REACT_APP_HOST_ENV + "/imports/downloadInternalExpensesTemplate/" + props.lastInternalImportTemplate}
                                       className="btn btn-outline-success btn-sm" style={{width: "200px"}}>Last
                                        Internal Import Template</a>
                                </div> :
                                <div style={{display: "block"}}>
                                    <a download
                                       href={process.env.REACT_APP_HOST_ENV + "/imports/downloadExternalExpensesTemplate/" + props.lastExternalImportTemplate}
                                       className="btn btn-outline-success btn-sm" style={{width: "200px"}}>Last
                                        External Import Template</a>
                                </div>

                            }
                            <div className="row mt-5 import_row">
                                <div className="col import_column">
                                    <h3 className="text_import">Upload a File</h3>
                                    {/*<DropzoneArea*/}
                                    {/*    acceptedFiles={['.xlsx']}*/}
                                    {/*    dropzoneText={"Drag and drop an file here or click"}*/}
                                    {/*    onDrop={handleUpload}*/}
                                    {/*    class="drop_zone"*/}
                                    {/*    filesLimit={1}*/}

                                    {/*/>*/}
                                    <form method="post">
                                        <input type="file" name="files" onChange={handleUpload}/>
                                    </form>

                                </div>
                            </div>
                            <div className="row mt-5" style={{display: "flex", justifyContent: "center"}}>
                                <button style={{width: "99%"}} className="btn btn-primary" disabled={!hasFiles}
                                        onClick={selectType}>Next
                                </button>
                            </div>
                            {userRole === "ROLE_ADMIN" ? <div id="selectTypeRow" className="row mt-5"
                                                              style={{display: "none", justifyContent: "center"}}>
                                    <label>Select the type of import</label>
                                    <select onChange={confirmUponTypeSelection} style={{width: "99%"}}
                                            className="form-select">
                                        <option value={0} selected>---------</option>
                                        <option value="project_structure">Project structure</option>
                                        <option value="project_expanses">External Part expenses</option>
                                        <option value="project_expenses_intern">Internal Part Expenses</option>
                                    </select>
                                </div> :
                                <div id="selectTypeRow" className="row mt-5"
                                     style={{display: "none", justifyContent: "center"}}>
                                    <label>Select the type of import</label>
                                    <select onChange={confirmUponTypeSelection} style={{width: "99%"}}
                                            className="form-select">
                                        <option value={0} selected>---------</option>
                                        <option value="project_expanses">External Part expenses</option>
                                    </select>
                                </div>
                            }
                            <div id="selectProjectRow" className="row mt-5"
                                 style={{display: "none", justifyContent: "center"}}
                            >
                                <label>Select project</label>
                                <select onChange={handleProjectSelection} style={{width: "99%"}}
                                        className="form-select">
                                    <option value={0} selected>---------</option>
                                    {projects.map((project) => {
                                        return (
                                            <option value={project.id}>{project.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="row mt-5" style={{display: "flex", justifyContent: "center"}}>
                                <button type="button" onClick={finalizeInput} style={{width: "99%"}}
                                        className="btn btn-success" disabled={!finalChoice}
                                >Confirm
                                </button>
                            </div>
                        </div>
                    </Card>
                </div>

                <div className="col-8">
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded" id="mainFormAddPart">
                        <div className="import_container p-3">
                            <h2 className="header_import">Existing files</h2>
                            <div className="row">
                                <div className="col">
                                    <div className="table-rep-plugin">
                                        <div
                                            className=""
                                            data-pattern="priority-columns"
                                        >

                                            <hr/>
                                            <div className="row">

                                                <h5>Filters:</h5>

                                                <div className="col">
                                                    <label>Type</label><br/>
                                                    <select id="type" name="type" className="form-select"
                                                            onChange={filterFiles}>
                                                        <option selected value="0">------</option>
                                                        <option value="project_structure">Project structure</option>
                                                        <option value="project_expanses">External Part expenses</option>
                                                        <option value="project_expenses_intern">Internal Part Expenses
                                                        </option>
                                                    </select>
                                                </div>

                                                <div className="col">
                                                    <label>Project</label><br/>
                                                    <select id="project" name="project" className="form-select"
                                                            onChange={filterFiles}>
                                                        <option selected value="0">------</option>
                                                        {props.projects.map((project) => {
                                                            return (
                                                                <option
                                                                    value={project.id.toString()}>{project.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="col">
                                                    <label>Status</label><br/>
                                                    <select id="status" name="status" className="form-select"
                                                            onChange={filterFiles}>
                                                        <option selected value="0">------</option>
                                                        <option value="active">active</option>
                                                        <option value="inactive">inactive</option>
                                                    </select>
                                                </div>


                                            </div>
                                            <hr/>

                                            <TableContainer id="mainTable">
                                                <Table
                                                    id="universityTable"
                                                    className="table tableCustom"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell data-priority="3">Date</TableCell>
                                                            <TableCell data-priority="3">Type</TableCell>
                                                            <TableCell data-priority="3">Name</TableCell>
                                                            <TableCell data-priority="3">Project</TableCell>
                                                            <TableCell data-priority="3">Status</TableCell>
                                                            <TableCell data-priority="3">Creator</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {isFiltered === true ? existingImports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((existingImport) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell>
                                                                        {existingImport.date !== null ? existingImport.date : "-"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {existingImport.type !== null ? existingImport.type === "project_structure" ? "Project structure" :
                                                                            "Project expenses" : "-"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {existingImport.name !== null ? existingImport.name : "-"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {existingImport.projectName !== null ? existingImport.projectName : "-"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {existingImport.status !== null ? existingImport.status : "-"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {existingImport.creator !== null ? existingImport.creator : "-"}
                                                                    </TableCell>

                                                                </TableRow>

                                                            )
                                                        }) : props.existingImports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((existingImport) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell>
                                                                        {existingImport.date !== null ? existingImport.date : "-"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {existingImport.type !== null ? existingImport.type === "project_structure" ? "Project structure" :
                                                                            "Project expenses" : "-"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {existingImport.name !== null ? existingImport.name : "-"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {existingImport.projectName !== null ? existingImport.projectName : "-"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {existingImport.status !== null ? existingImport.status : "-"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {existingImport.creator !== null ? existingImport.creator : "-"}
                                                                    </TableCell>

                                                                </TableRow>

                                                            )
                                                        })}

                                                    </TableBody>
                                                </Table>
                                                <TablePagination
                                                    component="div"
                                                    rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                                    count={isFiltered === true ? existingImports[0] === "" && existingImports.length === 1 ? 0 : existingImports.length :
                                                        props.existingImports[0] === "" && props.existingImports.length === 1 ? 0 : props.existingImports.length}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    rowsPerPage={rowsPerPage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </TableContainer>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Card>
                </div>


            </div>
        </div>
    );
};


export default Import;








