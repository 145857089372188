import React from "react";
import Key from "./Key";
import {Modal} from "@material-ui/core";
import {Card, CardBody} from "reactstrap";
import MappingService from "../../repository/MappingRepository";

const Mapping = (props) => {

    const [selectedIfesKey, setSelectedIfesKey] = React.useState("");
    const [confirmCombination, setConfirmCombination] = React.useState({"show": false, "ifesKey": 0, "qBooksKey": 0});
    const [qBookKeys, setQBookKeys] = React.useState([]);
    const getSelectedIfesKey = (ifesKey) => {
        setSelectedIfesKey(ifesKey);
        MappingService.getQBookKeysForIfesKey(ifesKey).then((keys) => {
            setQBookKeys(keys.data)
        })
    }
    const handleKeysCombination = (ifesKey, qBooksKey) => {
        setConfirmCombination({"show": true, "ifesKey": ifesKey ,"qBooksKey": qBooksKey});

    }
    const closeHandelKeysCombination = () => {
        let updateConfirmCombination = {...confirmCombination};
        updateConfirmCombination.show = false;
        setConfirmCombination(updateConfirmCombination);
    }
    const handleApprovedCombination = (ifesKey, qBooksKey) => {
        MappingService.combineQBookIfes(ifesKey, qBooksKey).then(()=> {

            closeHandelKeysCombination();
        });
    }
    return (
        <div className="container">
            <Modal
                open={confirmCombination.show}
                onClose={closeHandelKeysCombination}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                <div className="">
                    <div className="row mt-5">
                        <div className="col">
                            <Card style={{width:400,marginLeft:"40%", marginTop:"10%"}}>
                                <CardBody>
                                    <div className="row">
                                        Are you sure you want to match Ifes key {confirmCombination.ifesKey} with QBook
                                        key {confirmCombination.qBooksKey} ?
                                    </div>

                                    <div className="row" style={{marginTop:10}}>
                                        <button type="button" className="btn btn-outline-success"
                                                onClick={()=>{handleApprovedCombination(confirmCombination.ifesKey, confirmCombination.qBooksKey)}}>Yes
                                        </button>

                                        <button style={{marginTop:10}} type="button" className="btn btn-outline-danger" onClick={closeHandelKeysCombination}>No
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>

            </Modal>

            <div className="row mt-5">
                <h3>Mapping</h3>
                <div className="col mainCol">
                    <div className="row mt-5" style={{display: "flex", justifyContent: "center"}}>
                        <div className="col">
                            <Key typeKeys={"ifesKeys"} getSelectedIfesKey={getSelectedIfesKey} QBooks={props.QBooks}/>
                        </div>
                        <div className="col">
                            <Key  typeKeys={"qBookKeys"} getSelectedIfesKey={getSelectedIfesKey} selectedIfesKey={selectedIfesKey}
                                  handleKeysCombination={handleKeysCombination} qBookKeys={qBookKeys} QBooks={props.QBooks}/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}
export default Mapping;
