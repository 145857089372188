import {Card, CardBody} from "reactstrap";
import React from "react";
import exchangeRateRepository from "../../repository/exchangeRateRepository";

const EditExchangeRate = (props) => {
    const [formData, updateForData] = React.useState({
        id: props.exchangeRate.id,
        dateFrom: props.exchangeRate.dateFrom,
        dateTo: props.exchangeRate.dateTo,
        er: props.exchangeRate.er
    })

    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const closeSlidedEditExchangeRate = () => {
        props.closeSlidedEditExchangeRate();
        clearFields();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const id = formData.id === undefined ? props.exchangeRate.id : formData.id;
        const dateFrom = formData.dateFrom === undefined ? props.exchangeRate.dateFrom : formData.dateFrom;
        const dateTo = formData.dateTo === undefined ? props.exchangeRate.dateTo : formData.dateTo;
        const er = formData.er === undefined ? props.exchangeRate.er : formData.er;
        exchangeRateRepository.editExchangeRate(id, dateFrom, dateTo, er).then(() => {
            //Ways you can handle resolve
            // First is to just redirect:
            props.closeSlidedEditExchangeRate();
            props.refreshContent();
            props.showAlertMessage("You successfully edited an exchange rate!");
            clearFields();
            //Second is to do it asynchronously:
            // props.closeSlidedAddProject()
            // props.refreshContent();
        })
    }

    const clearFields = () => {
        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        })
        updateForData({
            formData: {
                dateFrom: "",
                dateTo: "",
                er: "",
            }
        })
    }

    return (
        <div className="container Main_edit_project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody>
                            <div className="Header header_add_project">
                                <h1>Edit Exchange Rate</h1>
                            </div>
                            <form>
                                <div className="row">
                                    <div id="dateFrom">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Date from:
                                        </label>
                                        <input
                                            name="dateFrom"
                                            type="date"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter date"
                                            onChange={handleChange}
                                            value={formData.dateFrom !== undefined ? formData.dateFrom : props.exchangeRate.dateFrom}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="dateTo">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Date To:
                                        </label>
                                        <input
                                            name="dateTo"
                                            type="date"
                                            min={formData.dateFrom !== undefined ? formData.dateFrom : props.exchangeRate.dateFrom}
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter date"
                                            onChange={handleChange}
                                            value={formData.dateTo !== undefined ? formData.dateTo : props.exchangeRate.dateTo}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="er">
                                        <label>
                                            CHF to USD exchange rate:
                                        </label>
                                        <input
                                            name="er"
                                            type="number"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter exchange rate"
                                            onChange={handleChange}
                                            value={formData.er !== undefined ? formData.er : props.exchangeRate.er}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <span id="createCancel">
                                    <button
                                        onClick={onFormSubmit}
                                        type="button"
                                        className="btn btn-primary btn-small btn_add_part"
                                        id="create_button"
                                    >
                                        Confirm
                                    </button>

                                    <button
                                        onClick={closeSlidedEditExchangeRate}
                                        type="button"
                                        className="btn btn-primary btn-small btn_add_part"
                                        id="cancel_button"
                                    >
                                        Cancel
                                    </button>
                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default EditExchangeRate;
