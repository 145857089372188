import {Card, CardBody} from "reactstrap";
import React from "react";
import PartsService from "../../repository/partsRepository";


const DeletePart = props => {
    const [formData] = React.useState({
        id: props.id,

    });

    const submitFormPart = (e) => {

        e.preventDefault();
        const id = formData.id;

        PartsService.deletePart(id).then(() => {
            props.refreshContent();
            props.handleCloseDeletePart();
            props.showAlertMessage("You successfully deleted a part!");
        });

    }

    const handleCloseDeletePart = () => {
        props.handleCloseDeletePart();
    }

    return (


        <div className="container" style={{width: "50%"}}>

            <div className="row mt-5">
                <div className="col mainCol">
                    <Card style={{marginTop: "10%"}}>
                        <CardBody>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <span/>
                                <h3 className="formHeader">Delete</h3>
                                <button onClick={handleCloseDeletePart} className="btn-close"
                                        aria-label="Close"/>
                            </div>
                            <form onSubmit={submitFormPart}>
                                <div className="form-group" style={{display: "flex", justifyContent: "center"}}>
                                    Are you sure you want to delete this Part?


                                </div>
                                <br/>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <button type="submit" className="btn btn-outline-danger ">Delete</button>
                                    <br/>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DeletePart;