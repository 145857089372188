import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {Card, CardBody} from "reactstrap";
import exchangeRateRepository from "../../repository/exchangeRateRepository";
import {Modal} from "@material-ui/core";
import ErrorMessage from "./ErrorMessage";

const AddExchangeYear = (props) => {

    const [formData, updateFormData] = React.useState({
        fromDate: "",
        toDate: "",
        chfToUsdExchangeRate: "",
        errorMsgVisible: false,
        msg: ''
    })

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const closeSlidedAddExchangeRate = () => {
        props.closeSlidedAddExchangeRate();
        clearFields()
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const fromDate = formData.fromDate;
        const toDate = formData.toDate;
        const chfToUsdExchangeRate = formData.chfToUsdExchangeRate;

        let pass = true;
        if (fromDate === "" || fromDate === undefined) {
            setRequiredFromDate(true);
            pass = false;
        } else {
            setRequiredFromDate(false);
        }
        // if (toDate === "" || toDate === undefined) {
        //     setRequiredToDate(true);
        //     pass = false;
        // } else {
        //     setRequiredToDate(false)
        // }
        if (pass) {
            exchangeRateRepository.createNewExchangeRate(fromDate, toDate, chfToUsdExchangeRate).then(() => {
                //Ways you can handle resolve
                // First is to just redirect:
                // window.location.href="/projects";
                //Second is to do it asynchronously:
                props.closeSlidedAddExchangeRate()
                props.refreshContent();
                props.showAlertMessage("You successfully added an exchange rate!");
                clearFields();
            }).catch(error => {
                console.log("ff", error.response.data.message);
                showErrorMessage(error.response.data.message);
            })
        }
    }

    const showErrorMessage = (errorMessage) => {
        // this.setState({msg: errorMessgae, errorMsgVisible: true})

        updateFormData({
            ...formData,
            msg : errorMessage,
            errorMsgVisible: true
        })

        const timer = setTimeout(() => {
            clearErrorMessage();
        }, 2500);
    }

    const clearErrorMessage = () => {
        // this.setState({
        //     ...this.state,
        //     errorMsgVisible: false
        // });

        updateFormData({
            ...formData,
            errorMsgVisible: false
        })
    }

    const clearFields = () => {
        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        })
        updateFormData({
            formData: {
                fromDate: "",
                toDate: "",
                chfToUsdExchangeRate: "",
                errorMsgVisible: false,
                msg: ''
            }
        })
        setRequiredFromDate(false);
        setRequiredToDate(false);
        setRequiredChfToUsdExchangeRate(false);
    }
    const [requiredFromDate, setRequiredFromDate] = React.useState(false);
    const [requiredToDate, setRequiredToDate] = React.useState(false);
    const [requiredChfToUsdExchangeRate, setRequiredChfToUsdExchangeRate] = React.useState(false);

    return (
        <div className="container Main_add_Project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody>
                            <div className="Header header_add_project">
                                <h1>Create Exchange Rate</h1>
                            </div>
                            <br/>
                            <br/>
                            <form>
                                <div className="row">
                                    <div id="fromDate">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Date from:
                                        </label>
                                        <div>
                                            <label style={requiredFromDate === false ? {display: "none"} : {}}
                                                   className="text-danger">Please choose a date!</label>
                                        </div>
                                        <input
                                            name="fromDate"
                                            type="date"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter date"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="toDate">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Date to:
                                        </label>
                                        <div>
                                            <label style={requiredToDate === false ? {display: "none"} : {}}
                                                   className="text-danger">Please choose a date!</label>
                                        </div>
                                        <input
                                            name="toDate"
                                            type="date"
                                            min={formData.fromDate !== undefined ? formData.fromDate : props.fromDate}
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter date"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="chfToUsdExchangeRate">
                                        <label>
                                            CHF to USD Exchange Rate
                                        </label>
                                        <div>
                                            <label style={requiredToDate === false ? {display: "none"} : {}}
                                                   className="text-danger">Please insert the CHF to USD exchange rate!</label>
                                        </div>
                                        <input
                                            name="chfToUsdExchangeRate"
                                            type="number"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter CHF to USD exchange rate"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <span id="createCancel">
                                        <button
                                            onClick={onFormSubmit}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="create_button"
                                        >
                                            Create
                                        </button>

                                        <button
                                            onClick={closeSlidedAddExchangeRate}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="cancel_button"
                                        >
                                            Cancel
                                        </button>

                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>

                    <ErrorMessage id={"addExchangeRate"} clearErrorMessage={clearErrorMessage}
                                  show={formData.errorMsgVisible} msg={formData.msg}/>
                </div>
            </div>
        </div>
    );
};

export default AddExchangeYear;