import {Card, CardBody} from "reactstrap";
import '../../assets/general.css'
import CardHeader from "@material-ui/core/CardHeader";
import {VscAdd} from "react-icons/vsc";
import {FaRegEdit} from 'react-icons/fa';
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@material-ui/core";
import React, {useEffect} from "react";
import UserService from "../../repository/userRepository";
import AddUser from "./AddUser";
import RoleService from "../../repository/roleRepository";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";
import {Modal} from "@material-ui/core";
import {AiOutlineDelete} from "react-icons/ai";
import Alert from "react-bootstrap/Alert";


const UsersAll = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [filteredUsers, setFilteredUsers] = React.useState([]);
    const [allUsers, setAllUsers] = React.useState([]);

    const filterTable = (e) => {
        const value = e.target.value;
        if (value === null || value === "") {
            setFilteredUsers(allUsers);
        } else {
            let uniList = []
            allUsers.map(uni => {
                if (uni.username.toLowerCase().includes(value.toLowerCase())
                    || uni.email.toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)

                }
            })
            if (uniList.length > 0) {
                setFilteredUsers(uniList);
            } else {
                setFilteredUsers([""]);
            }
        }
        setPage(0);
    }


    const [showAddUserForm, setShowAddUserForm] = React.useState(false);
    const [showEditUserForm, setShowEditUserForm] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [rolesForAddUser, setRolesForAddUser] = React.useState([]);
    const [existentUsernames, setExistentUsernames] = React.useState([]);
    const slideAddUser = () => {
        RoleService.fetchRoles().then((roles) => {
            setRolesForAddUser(roles.data);
            UserService.getAllUsernames().then((usernames) => {
                setExistentUsernames(usernames.data);
            })
            setShowAddUserForm(true);
            setShowEditUserForm(false);
        });

    }
    const closeSlidedAddUser = () => {
        setShowAddUserForm(false);
    }
    const [roleUser, setRoleUser] = React.useState({});
    const slideEditUser = (e) => {
        setSelectedUser({})
        let id = e.target.value;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.value;
        }
        UserService.getOneUser(id).then((user) => {

            setRoleUser(user.data.role)
            setSelectedUser(user.data);
            RoleService.fetchRoles().then((roles) => {
                setRolesForAddUser(roles.data);
                setShowEditUserForm(true);
                setShowAddUserForm(false);
            });

        });

    }
    const closeSlidedEditUser = () => {
        setShowEditUserForm(false);
    }
    useEffect(() => {
        if (filteredUsers.length === 0) {
            refreshContent();
        }
    })
    const refreshContent = () => {
        UserService.fetchUsers().then((users) => {
            if (users.data.length !== 0) {
                setFilteredUsers(users.data);
                setAllUsers(users.data);
            }
        })
    }

    const deleteRefreshContent = () => {
        UserService.fetchUsers().then((users) => {
            if (users.data.length !== 0) {
                setFilteredUsers(users.data);
                setAllUsers(users.data)
            } else {
                setFilteredUsers([]);
                setAllUsers([]);
            }
        })
    }

    const [openDeleteUser, setOpenDeleteUser] = React.useState(false);
    const [id, setId] = React.useState();

    const handleOpenDeleteUser = (e) => {
        let id = e.target.id;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.id;
        }
        setId(id);
        setOpenDeleteUser(true);
    }
    const handleCloseDeleteUser = () => {
        setOpenDeleteUser(false);
    }

    const [alertMessage, setAlertMessage] = React.useState(false);
    const [alertMessageText, setAlertMessageText] = React.useState("");
    const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
    const showAlertMessage = (message) => {
        let a;
        setAlertMessageText(message);
        setAlertMessage(true);
        if (clearPreviousTimeout !== undefined) {
            clearTimeout(clearPreviousTimeout);
        }
        a = setTimeout(() => {
            setAlertMessage(false)
        }, 2000);
        setClearPreviousTimeout(a);
    }
    const deleteUser = (
        <DeleteUser id={id} handleCloseDeleteUser={handleCloseDeleteUser} refreshContent={deleteRefreshContent}
                    showAlertMessage={showAlertMessage}/>
    );
    return (
        <div>
            <Modal
                style={{width: "50%", margin: "auto"}}
                open={openDeleteUser}
                onClose={handleCloseDeleteUser}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {deleteUser}
            </Modal>

            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded">
                        <div className="first_line">
                            <CardHeader title={"Users"} id="list_projects"/>
                            <Alert id="alert-success" variant='success' show={alertMessage}>
                                <p>{alertMessageText}</p>
                            </Alert>
                            <button type="button" className="btn btn-outline-success" id="create"
                                    onClick={slideAddUser}>{<VscAdd id="add_icon"/>} New User
                            </button>
                        </div>
                        <CardBody>
                            <input onKeyUp={filterTable} type="text" className="filterField" placeholder="Filter"/>
                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer id="mainTable">
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>

                                                    <TableCell data-priority="3">Username</TableCell>
                                                    <TableCell data-priority="3">Email</TableCell>
                                                    <TableCell data-priority="3">Edit</TableCell>
                                                    <TableCell data-priority="3">Delete</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredUsers[0] === "" && filteredUsers.length === 1 ?
                                                    <div/> :
                                                    filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                        return (
                                                            <TableRow className="innerRow">

                                                                <TableCell
                                                                    className="innerCell">{term.username}</TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.email}</TableCell>
                                                                <TableCell className="innerCell">
                                                                    <button className="btn btn-success"
                                                                            value={term.id} onClick={slideEditUser}>
                                                                        <i className="fa fa-pencil-square-o"/>
                                                                        Edit
                                                                    </button>
                                                                </TableCell>
                                                                <TableCell className="innerCell">
                                                                    <button className="btn btn-danger"
                                                                            id={term.id}
                                                                            onClick={handleOpenDeleteUser}>
                                                                        <i className="fa fa-trash-o"/>
                                                                        Delete
                                                                    </button>
                                                                </TableCell>

                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                            count={filteredUsers[0] === "" && filteredUsers.length === 1 ? 0 : filteredUsers.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                <div style={showAddUserForm === false ? {display: "none"} : {}} className='col'>
                    <AddUser usernames={existentUsernames} roles={rolesForAddUser}
                             closeSlidedAddUser={closeSlidedAddUser} refreshContent={refreshContent}
                             showAlertMessage={showAlertMessage}/>
                </div>
                <div style={showEditUserForm === false ? {display: "none"} : {}} className='col'>
                    <EditUser userRole={roleUser} user={selectedUser} roles={rolesForAddUser}
                              closeSlidedEditUser={closeSlidedEditUser} refreshContent={refreshContent}
                              showAlertMessage={showAlertMessage}/>
                </div>
            </div>

        </div>
    )


}
export default UsersAll;