import {AiOutlinePaperClip} from "react-icons/ai";
import React from "react";
import {Card} from "@material-ui/core";
import ImportService from "../../repository/importRepository";

const ImportsTemplatesManagement = (props) =>{

    const [hasFiles, setHasFiles] = React.useState(false);
    const [fileUpload, setFile] = React.useState(null);
    const [typeUpload, setTypeUpload] = React.useState("");
    const [finalChoice, setFinalChoice] = React.useState(false);

    const handleUpload = (files) => {
        const files_target = files.target.files;
        if (files_target.length > 0) {
            setHasFiles(true);
            setFile(files);
        }
    }

    const confirmUponTypeSelection = (e) => {
        const select = e.target.value;
        setTypeUpload(select);
        setFinalChoice(true);
    }

    const selectType = () => {
        document.getElementById("selectTypeRow").style.display = "";

    }

    const finalizeInput = () => {
        const creator = localStorage.getItem('username') + " (" + localStorage.getItem('email') + ")";
        const form = new FormData();
        form.append("file_arr", fileUpload.target.files[0]);
        form.append("type", typeUpload);
        form.append("creator", creator);
        ImportService.createImportTemplate(form).then(() => {
            window.location.href = "/importsTemplatesManagement"
        });
    }

    return(
        <div className="row mt-5">
            <div className="mainCol">
                <Card className="customCard shadow p-3 mb-5 bg-white rounded" id="mainFormAddPart">
                    <div className="import_container p-3">
                        <h2 className="header_import">{<AiOutlinePaperClip/>}Import templates</h2>
                        <div className="row mt-5 import_row">
                            <div className="col import_column">
                                <h3 className="text_import">Upload a template</h3>
                                {/*<DropzoneArea*/}
                                {/*    acceptedFiles={['.xlsx']}*/}
                                {/*    dropzoneText={"Drag and drop an file here or click"}*/}
                                {/*    onDrop={handleUpload}*/}
                                {/*    class="drop_zone"*/}
                                {/*    filesLimit={1}*/}

                                {/*/>*/}
                                <form method="post">
                                    <input type="file" name="files" onChange={handleUpload}/>
                                </form>

                                <div className="row mt-5" style={{display: "flex", justifyContent: "center"}}>
                                    <button style={{width: "99%"}} className="btn btn-primary" disabled={!hasFiles}
                                            onClick={selectType}>Next
                                    </button>
                                </div>
                                <div id="selectTypeRow" className="row mt-5"
                                     style={{display: "none", justifyContent: "center"}}>
                                    <label>Select the type of import</label>
                                    <select onChange={confirmUponTypeSelection} style={{width: "99%"}}
                                            className="form-select">
                                        <option value={0} selected>---------</option>
                                        <option value="project_structure_template">Project structure</option>
                                        <option value="project_expanses_template">External Part expenses</option>
                                        <option value="project_expenses_intern_template">Internal Part Expenses</option>
                                    </select>
                                </div>

                                <div className="row mt-5" style={{display: "flex", justifyContent: "center"}}>
                                    <button type="button" onClick={finalizeInput} style={{width: "99%"}}
                                            className="btn btn-success" disabled={!finalChoice}
                                    >Confirm
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>

    )
}

export default ImportsTemplatesManagement;
