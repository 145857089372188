import instance from "../custom-axios/axios";
import axios from "../custom-axios/axios";

const ProjectsService  = {
    fetchProjects:()=>{
        return axios.get("/projects");
    },
    addProject: (form) =>{
        return instance.post(`/projects`,form);
    },
    editProject: (form) =>{
        return axios.post(`/projects/edit/${form.id}`,form)
    },
    getOneProject:(id)=>{
        return axios.get(`/projects/${id}`);
    },
    deleteProject:(id)=> {
        return instance.post(`/projects/delete/${id}`);
    },

    getProjectYearSizeByProjectId:(id)=>{
        return axios.get(`/projects/getProjectYearSizeByProjectId/${id}`);
    },

    getProjectYears:(projectId)=>{
        return axios.get(`/project_years/getByProject/${projectId}`);
    }


}

export default ProjectsService;