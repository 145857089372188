import {Card, CardBody} from "reactstrap";
import React from "react";
import ExchangeRateService from "../../repository/exchangeRateRepository";

const DeleteExchangeRate = props => {
    const [formData] = React.useState({
        id: props.id,
    });

    const submitFormExchangeRate = (e) => {
        e.preventDefault();
        const id = formData.id;
        ExchangeRateService.deleteExchangeRate(id).then(() => {
            props.refreshContent();
            props.handleCloseDeleteExchangeRate();
            props.showAlertMessage("You successfully deleted an exchange rate!");
        });

    }

    const handleCloseDeleteExchangeRate = () => {
        props.handleCloseDeleteExchangeRate();
    }

    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card style={{marginTop: "10%"}}>
                        <CardBody>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <span/>
                                <h3 className="formHeader">Delete</h3>
                                <button onClick={handleCloseDeleteExchangeRate} className="btn-close"
                                        aria-label="Close"/>
                            </div>
                            <form onSubmit={submitFormExchangeRate}>
                                <div className="form-group" style={{display: "flex", justifyContent: "center"}}>
                                    Are you sure you want to delete this exchange rate?
                                </div>
                                <br/>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <button type="submit" className="btn btn-outline-danger ">Delete</button>
                                    <br/>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DeleteExchangeRate;