import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {Card, CardBody} from "reactstrap";
import "./AddPart.css";
import partRepository from "../../repository/partsRepository";
import {useHistory} from "react-router-dom";

const AddSubpart = (props) => {
    const history = useHistory();
    const [formData, updateForData] = React.useState({
            id: "",
            partCode: "",
            description: "",
            ifesCode: "",
            level: null,
            price: undefined,
            unit: "-",
            quantity: undefined
        }
    )

    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value
        })
        let val = e.target.value;
        if (e.target.name === "level") {
            if (val === "5" || val === "6") {
                setShowCostInformation(true);
            } else {
                setShowCostInformation(false);
            }
        }
    }

    const closeSlidedAddSubpart = () => {
        clearFields();
        props.closeSlidedAddSubpart();
        setShowCostInformation(false);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        let pass = true;
        if (formData.description === "" || formData.description === undefined) {
            setRequiredName(true);
            pass = false;
        } else {
            setRequiredName(false)
        }
        if (formData.level === null || formData.level === undefined || formData.level === "0") {
            setRequiredLevel(true);
            pass = false;
        } else {
            setRequiredLevel(false)
        }
        if (pass) {
            const id = formData.id;
            const code = formData.partCode;
            const name = formData.description;
            const key = formData.ifesCode;
            const level_id = formData.level;
            const projectId = props.project.id;
            const price = formData.price;
            const quantity = formData.quantity;
            let unit = formData.unit;
            const part = props.parts.id;
            if (unit === undefined) {
                unit = "-"
            }
            const form = new FormData();
            form.append("id", id);
            form.append("description", name);
            form.append("code", code);
            form.append("ifesKey", key);
            form.append("levelId", level_id);
            form.append("projectId", projectId);
            form.append("price", price);
            form.append("quantity", quantity);
            form.append("unit", unit);
            form.append("yearsDetails", JSON.stringify(yearsDetails));
            form.append("parentId", part);

            partRepository.addPart(form).then(() => {
                clearFields();
                props.handleHighlight(part)
                props.closeSlidedAddSubpart();
                setShowCostInformation(false);
            });
        }
    }

    const clearFields = () => {

        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        });
        document.querySelectorAll("select").forEach((item) => {
            item.value = 0;
        });
        updateForData({
            formData: {
                id: "",
                partCode: "",
                description: "",
                ifesCode: "",
                level: null,
                price: undefined,
                unit: "",
                quantity: undefined
            }
        });
        setRequiredName(false);
        setRequiredLevel(false);
    }

    const [yearsDetails, setYearsDetails] = React.useState({})

    // eslint-disable-next-line array-callback-return
    props.years.map((year) => {
        if (!(year.year.id in yearsDetails)) {
            setYearsDetails({
                ...yearsDetails,
                [year.year.id]: {"unit": "", "price": 0, "quantity": 0}
            })
        }
    });

    const handleYearDetailsChange = (e) => {
        const val = e.target.value;
        const theAtt = e.target.name.split("-")[0];
        const theId = e.target.name.split("-")[1];
        let obj = yearsDetails[theId];
        obj[theAtt] = val;
        setYearsDetails({
            ...yearsDetails,
            [theId]: obj
        })
    }

    const [showCostInformation, setShowCostInformation] = React.useState(false);
    const [requiredName, setRequiredName] = React.useState(false);
    const [requiredLevel, setRequiredLevel] = React.useState(false);

    return (
        <div className="container Main_add_Project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody id="mainFormAddPart">
                            <div className="Header header_add_project">
                                <h1>Add Subpart</h1>
                            </div>
                            <form>
                                <div className="row">
                                    <div id="partCode">
                                        <label htmlFor="input_Part_Code" className="label_add_project">
                                            Part Code
                                        </label>
                                        <input
                                            name="partCode"
                                            type="text"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter part code"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Part Name
                                        </label>
                                        <div>
                                            <label style={requiredName === false ? {display: "none"} : {}}
                                                   className="text-danger">Please fill out this field!</label>
                                        </div>
                                        <textarea
                                            name="description"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter part name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="ifes_Key">
                                        <label htmlFor="input_Part_Key" className="label_add_project">
                                            IFES Key
                                        </label>
                                        <input
                                            name="ifesCode"
                                            type="text"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter ifes key"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="level">
                                        <label htmlFor="input_Part_Level" className="label_add_project">
                                            Level
                                        </label>
                                        <div>
                                            <label style={requiredLevel === false ? {display: "none"} : {}}
                                                   className="text-danger">Please select a level!</label>
                                        </div>
                                        <div>
                                            <select name="level" className="form_add_project form-control"
                                                    onChange={handleChange}>
                                                <option value={0} selected>Level</option>
                                                {props.levels.map((level) => {
                                                    return (<option value={level.id}>{level.description}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div style={showCostInformation === false ? {display: "none"} : {}}>
                                    <div className="row mt-5">
                                        <h5>Budget information:</h5>
                                        <div className="col mt-2">
                                            <input name="unit" className="field" placeholder="Unit"
                                                   onChange={handleChange}/>
                                        </div>
                                        <div className="col mt-2">
                                            <input name="price" className="field" placeholder="Price per unit"
                                                   onChange={handleChange}/>
                                        </div>
                                        <div className="col mt-2">
                                            <input name="quantity" className="field" placeholder="Quantity"
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <div className="mt-5 mb-5" id="perYearInformationAddPart">
                                        <h5>Budget per year information:</h5>
                                        {props.years.map((year) => {
                                            return (
                                                <div className="row">
                                                    <h6 className="mt-2">{year.year.fromDate + " to " + year.year.toDate}</h6>
                                                    <div className="col mt-2">
                                                        <input onChange={handleYearDetailsChange}
                                                               name={"unit-" + year.year.id} className="field"
                                                               placeholder="Unit"/>
                                                    </div>
                                                    <div className="col mt-2">
                                                        <input onChange={handleYearDetailsChange}
                                                               name={"price-" + year.year.id} className="field"
                                                               placeholder="Total price for period"/>
                                                    </div>
                                                    <div className="col mt-2">
                                                        <input onChange={handleYearDetailsChange}
                                                               name={"quantity-" + year.year.id} className="field"
                                                               placeholder="Quantity"/>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="row">
                                    <span id="createCancel">
                                        <button
                                            onClick={onFormSubmit}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="create_button"
                                        >
                                            Create
                                        </button>

                                        <button
                                            onClick={closeSlidedAddSubpart}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="cancel_button"
                                        >
                                            Cancel
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>

    )
        ;
};
export default AddSubpart;