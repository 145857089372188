import {Card, CardBody} from "reactstrap";
import "./EditProject.css";
import React from "react";
import projectsRepository from "../../repository/projectsRepository";
import {f} from "react-select/dist/index-4bd03571.esm";
import Select from "react-select";

const EditProject = (props) => {
    const [formData, updateForData] = React.useState({...props.project})

    React.useEffect(() => {
        updateForData(props.project);
    }, [props.project]);

    const handleChange = (e) => {

        updateForData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const closeSlidedEditProject = () => {
        props.closeSlidedEditProject();

    }
    const [years, setYears] = React.useState([]);

    const onFormSubmit = (e) => {
        e.preventDefault();
        const id = formData.id === undefined ? props.project.id : formData.id;
        const name = formData.name === undefined ? props.project.name : formData.name;
        const code = formData.code === undefined ? props.project.code : formData.code;
        const form = new FormData();
        form.append("id", id);
        form.append("name", name);
        form.append("code", code);
        form.append("yearIds", years);
        projectsRepository.editProject(form).then(() => {
            props.closeSlidedEditProject()
            props.refreshContent();
            props.showAlertMessage("You successfully edited a project!");

        })
    }


    let options = [];
    props.years.map((year) => {
        options.push({value: year.id, label: "From: " + year.fromDate + " To: " + year.toDate})
    })

    const handleChangeSelect = (value, action) => {
        let update = [];
        if (value.length !== 0) {
            update = [...years];
        }
        for (var i = 0; i < value.length; i++) {
            if (!update.includes(value[i].value)) {
                update.push(value[i].value);
            }
        }

        setYears(update);
    }

    return (
        <div className="container Main_add_Project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody>
                            <div className="Header header_add_project">
                                <h1>Edit Project</h1>
                            </div>
                            <form>
                                <div className="row">
                                    <div id="partCode">
                                        <label htmlFor="input_Part_Code" className="input_edit_project">
                                            Code
                                        </label>
                                        <input
                                            name="code"
                                            type="text"
                                            className="form_edit_project form-control"
                                            aria-describedby="underText"
                                            placeholder="Enter the PartCode"
                                            onChange={handleChange}
                                            value={formData.code}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="input_edit_project">
                                            Name
                                        </label>
                                        <input
                                            name="name"
                                            type="text"
                                            className="form_edit_project form-control"
                                            aria-describedby="underText"
                                            placeholder="Enter the PartName"
                                            onChange={handleChange}
                                            value={formData.name}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="yearSelectEditDiv">

                                        <div>
                                            <label>Select new year(s) to extend the project</label>
                                        </div>
                                        <Select id="yearSelectEdit" name="year" required isMulti
                                                onChange={handleChangeSelect}
                                                options={options} className="form_add_project"/>
                                    </div>
                                </div>


                                <span id="createCancel">
                                    <button
                                        onClick={onFormSubmit}
                                        type="button"
                                        className="btn btn-primary btn-small btn_add_part"
                                        id="create_button"
                                    >
                                        Confirm
                                    </button>

                                    <button
                                        onClick={closeSlidedEditProject}
                                        type="button"
                                        className="btn btn-primary btn-small btn_add_part"
                                        id="cancel_button"
                                    >
                                        Cancel
                                    </button>
                                </span>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};
export default EditProject;
