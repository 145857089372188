import {Card, CardBody} from "reactstrap";
import React from "react";
import RoleService from "../../repository/roleRepository";


const EditRole = (props) => {
    const [formData, updateForData] = React.useState({
        name: props.role.name,

    })


    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const closeSlidedEditRole = () => {
        props.closeSlidedEditRole();
        clearFields();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        const name = formData.name !== "" ? formData.name : props.role.name;
        const id = props.role.id;
        // updateForData({
        //         name:""
        //     }
        // )
        RoleService.editRole(id, name).then(() => {
            //Ways you can handle resolve
            // First is to just redirect:
            props.closeSlidedEditRole()
            props.refreshContent();
            props.showAlertMessage("You successfully edited a role!");
            clearFields();
            //Second is to do it asynchronously:
            // props.closeSlidedEditRole()
            // props.refreshContent();
        })
    }

    const clearFields = () => {
        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        })
        updateForData({
            formData: {
                name: "",
            }
        })
    }

    return (
        <div className="container Main_add_Project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody>
                            <div className="Header header_add_project">
                                <h1>Edit Role</h1>
                            </div>
                            <form>

                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Name
                                        </label>
                                        <input
                                            name="name"
                                            type="text"
                                            className="form_add_project form-control"
                                            aria-describedby="underText"
                                            placeholder="Enter name"
                                            onChange={handleChange}
                                            value={formData.name !== undefined ? formData.name : props.role.name}
                                        />
                                    </div>
                                </div>

                                <div className="row">

                                    <span id="createCancel">
                                        <button
                                            onClick={onFormSubmit}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="create_button"
                                        >
                                            Confirm
                                        </button>

                                        <button
                                            onClick={closeSlidedEditRole}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="cancel_button"
                                        >
                                            Cancel
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}
export default EditRole;