import instance from "../custom-axios/axios";
import axios from "../custom-axios/axios";

const ExpensesService = {

    fetchPartExpenses:()=>{
        return instance.get("/part_expenses");
    },

    getPartExpensesByProjectId:(id, role, email)=>{
        return instance.get(`/part_expenses/partExpensesByProjectId/${id}/${role}/${email}`);
    },

    connectExpenseWithSelectedPart:(expenseId,partId)=>{
        return axios.post(`/part_expenses/connectExpenseWithSelectedPart/${expenseId}/${partId}`)

    },

    removePartForExpense:(id)=>{
        return axios.post(`/part_expenses/removePartForExpense/${id}`);
    },

    deleteExpenses:(id) => {
        return axios.post(`/part_expenses/delete/${id}`);
    },

    importLatestPartExpenses:(projectId,role,creator)=>{
        return axios.get(`/part_expenses/import_expenses/${projectId}/${role}/${creator}`);
    },

    importLatestInternalPartExpenses:(projectId)=>{
        return axios.get(`/part_expenses/import_internal_expenses/${projectId}`);
    }


}

export default ExpensesService;
