import {Card, CardBody} from "reactstrap";
import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@material-ui/core";
import ProjectsService from "../../repository/projectsRepository";
import YearsService from "../../repository/yearsRepository";

const DataForEachYear = props => {

    // const [filteredYears, setFilteredYears] = React.useState([]);

    const closeDataForEachYear = () => {
        props.closeDataForEachYear();
    }

    // await YearsService.fetchYears().then((years) => {
    //     if (years.data.length !== 0) {
    //         setFilteredYears(years.data);
    //     }
    // })

    return (

        <div className="container">
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card style={{marginTop: "10%"}}>
                        <CardBody>


                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <span/>
                                <h3 className="formHeader">Total financial information</h3>
                                <button onClick={closeDataForEachYear} className="btn-close" aria-label="Close"/>
                            </div>

                            <div className="mt-2 mb-5">
                                <TableContainer>
                                    <Table
                                        id="universityTable"
                                        className="table tableCustom"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell data-priority="3">Unit</TableCell>
                                                <TableCell data-priority="3">Quantity</TableCell>
                                                <TableCell data-priority="3">Price/Unit</TableCell>
                                                <TableCell data-priority="3">Total budget</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow className="innerRow">
                                                <TableCell
                                                    className="innerCell">{props.financialInfo.unit != null ? props.financialInfo.unit : "-"}</TableCell>
                                                <TableCell
                                                    className="innerCell">{props.financialInfo.quantity != null ? props.financialInfo.quantity : "-"}</TableCell>
                                                <TableCell
                                                    className="innerCell">{props.financialInfo.priceUnit != null ? props.financialInfo.priceUnit : "-"}</TableCell>
                                                <TableCell
                                                    className="innerCell">{props.financialInfo.quantity != null && props.financialInfo.priceUnit != null ?
                                                    props.financialInfo.quantity * props.financialInfo.priceUnit : "-"}</TableCell>
                                            </TableRow>


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>

                            <div style={{display: "flex", justifyContent: "center"}}>
                                <h3 className="formHeader">Per year</h3>
                            </div>
                            <form>
                                <div className="form-group mt-2 mb-5"
                                     style={{display: "flex", justifyContent: "center"}}>
                                    <TableContainer>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Year</TableCell>
                                                    <TableCell data-priority="3">Quantity</TableCell>
                                                    <TableCell data-priority="3">Budget</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.quantityCostPerYear.map((data) => {
                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{data.yearId && props.filteredYears?.find(year => year.id === data.yearId) ?
                                                                                                    props.filteredYears?.find(year => year.id === data.yearId).fromDate + " - " +
                                                                                                    props.filteredYears?.find(year => year.id === data.yearId).toDate
                                                                                                : " - "}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{data.quantity}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{data.cost}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DataForEachYear;