import {Card, CardBody} from "reactstrap";
import React from "react";
import PartsService from "../../repository/partsRepository";


const ConfirmModal = props => {
    const [showLoader, setShowLoader] = React.useState(false);
    const [isClicked, setIsClicked] = React.useState(false);

    const submitFormLevel = (e) => {
        e.preventDefault();
        setShowLoader(true);
        setIsClicked(true);
        if (props.id === "finalizeValidation") {
            PartsService.finalizeValidation(props.projectId).then(() => {
                // window.location.href = "/validate";
                props.finalizeValidation();
            });
        } else if (props.id === "importProjectStructure") {
            PartsService.readPartValidation(props.projectId).then(() => {
                // window.location.href = "/validate";
                props.importProjectStructure();
            });
        } else if (props.id === "partBalance") {
            props.finalizeSelection();
        } else if (props.id === "partExpenses") {
            const role = localStorage.getItem('role');
            const username = localStorage.getItem('username');
            const email = localStorage.getItem('email');
            const creator = username + " ("+email+")";
            props.handleImportLatestPartExpenses(props.projectId,role,creator);
        }
        else if (props.id === "partInternalExpenses"){
            props.handleImportLatestInternalPartExpenses(props.projectId);
        }
    };

    const closeConfirmModal = () => {
        props.handleCloseConfirmModal();
    }

    return (

        <div className="container">
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card style={{marginTop: "10%"}}>
                        <CardBody>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <span/>
                                <h3 className="formHeader">Are you sure?</h3>
                                <button type="submit" className="btn-close" onClick={closeConfirmModal}/>
                            </div>
                            <form>
                                <div className="form-group" style={{display: "flex", justifyContent: "center"}}>
                                    {props.id === "finalizeValidation" ?
                                        <span>By confirming this action you will create new part records. It might result in duplicates if the part with the same title/name exists.</span>
                                        : props.id === "importProjectStructure" ?
                                            <span>By confirming this action you will import new project structure that might result in creating duplicates for the non-validated parts.</span>
                                            : props.id === "partBalance" ?
                                                <span>By confirming this action you will finalize this selection.</span>
                                                : props.id === "partExpenses" ?
                                                    <span>By confirming this action you will load the latest imported external expenses.</span>
                                                    : props.id === "partInternalExpenses" ?
                                                        <span>By confirming this action you will load the latest imported internal expenses.</span>
                                                        :
                                                    <span/>
                                    }
                                </div>
                                <br/>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <button type="submit" className="btn btn-outline-success"
                                            onClick={submitFormLevel} disabled={isClicked}>Confirm
                                    </button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="loader" style={showLoader === false ? {display: "none"} : {}}/>
        </div>

    );
};

export default ConfirmModal;
