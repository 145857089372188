import React, {useEffect} from 'react';
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import '../../assets/general.css'
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {VscAdd} from 'react-icons/vsc';
import {FaRegEdit} from 'react-icons/fa';
import LevelsService from "../../repository/levelsRepository";
import AddLevel from "./AddLevel";
import EditLevel from "./EditLevel";
import DeleteLevel from './DeleteLevel';
import Alert from "react-bootstrap/Alert";
import {AiOutlineDelete} from "react-icons/ai";




const Levels = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [showAddLevelForm, setShowAddLevelForm] = React.useState(false);
    const [showEditLevelForm, setShowEditLevelForm] = React.useState(false);
    const [filteredLevels, setFilteredLevels] = React.useState([]);
    const [allLevels, setAllLevels] = React.useState([]);
    const [alertMessage, setAlertMessage] = React.useState(false);
    const [alertMessageText, setAlertMessageText] = React.useState("");

    const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
    const showAlertMessage = (message) => {
        let a;
        setAlertMessageText(message);
        setAlertMessage(true);
        if (clearPreviousTimeout !== undefined) {
            clearTimeout(clearPreviousTimeout);
        }
        a = setTimeout(() => {
            setAlertMessage(false)
        }, 2000);
        setClearPreviousTimeout(a);
    }

    const filterTable = (e) => {
        const value = e.target.value;
        if (value === null || value === "") {
            setFilteredLevels(allLevels);
        } else {
            let uniList = []
            allLevels.map(uni => {
                if (uni.description.toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)

                }
            })
            if (uniList.length > 0) {
                setFilteredLevels(uniList);
            } else {
                setFilteredLevels([""]);
            }
        }
        setPage(0);
    }

    const slideAddLevel = () => {
        setShowAddLevelForm(true);
        setShowEditLevelForm(false);
    }
    const closeSlidedAddLevel = () => {
        setShowAddLevelForm(false);
    }

    useEffect(() => {
        if (filteredLevels.length === 0) {
            refreshContent();
        }
    })

    const refreshContent = () => {
        LevelsService.fetchLevels().then((levels) => {
            if (levels.data.length !== 0) {
                setFilteredLevels(levels.data);
                setAllLevels(levels.data);
            }
        })
    }

    const deleteRefreshContent = () => {
        LevelsService.fetchLevels().then((levels) => {
            if (levels.data.length !== 0) {
                setFilteredLevels(levels.data);
                setAllLevels(levels.data);
            } else {
                setFilteredLevels([]);
                setAllLevels([]);
            }
        })
    }

    const [selectedLevel, setSelectedLevel] = React.useState({});
    const slideEditLevel = async (e) => {
        let id = e.target.value;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.value;
        }
        await LevelsService.getOneLevel(id).then((level) => {
            setSelectedLevel(level.data);
            setShowEditLevelForm(true);
            setShowAddLevelForm(false);
        })

    }

    const closeSlidedEditLevel = () => {
        setShowEditLevelForm(false);
    }

    const [openDeleteLevel, setOpenDeleteLevel] = React.useState(false);
    const [id, setId] = React.useState();

    const handleOpenDeleteLevel = (e) => {
        let id = e.target.id;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.id;
        }
        setId(id);
        setOpenDeleteLevel(true)
    };

    const handleCloseDeleteLevel = () => {
        setOpenDeleteLevel(false);
    };

    const LevelDelete = (
        <DeleteLevel id={id} handleCloseDeleteLevel={handleCloseDeleteLevel} refreshContent={deleteRefreshContent}
                     showAlertMessage={showAlertMessage}/>
    );

    return (
        <div id="table">
            <Modal
                style={{width: "50%", margin: "auto"}}
                open={openDeleteLevel}
                onClose={handleCloseDeleteLevel}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">

                {LevelDelete}
            </Modal>

            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded">
                        <div className="first_line">
                            <CardHeader title={"Levels"} id="list_projects"/>
                            <Alert id="alert-success" variant='success' show={alertMessage}>
                                <p>{alertMessageText}</p>
                            </Alert>
                            <button type="button" className="btn btn-outline-success" id="create"
                                    onClick={slideAddLevel}>{<VscAdd id="add_icon"/>} New Level
                            </button>
                        </div>
                        <CardBody>
                            <input className="filterField" placeholder="Filter" onKeyUp={filterTable} type="text"/>
                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer id="mainTable">
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Description</TableCell>
                                                    <TableCell data-priority="3">Order Number</TableCell>
                                                    <TableCell data-priority="3">Edit</TableCell>
                                                    <TableCell data-priority="3">Delete</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredLevels[0] === "" && filteredLevels.length === 1 ? <div/> :
                                                    filteredLevels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                        return (
                                                            <TableRow className="innerRow">
                                                                <TableCell
                                                                    className="innerCell">{term.description}</TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.orderNumber}</TableCell>
                                                                <TableCell className="innerCell">
                                                                    <button className="btn btn-success"
                                                                            onClick={slideEditLevel} value={term.id}>
                                                                        <i className="fa fa-pencil-square-o"/>
                                                                        Edit
                                                                    </button>
                                                                </TableCell>
                                                                <TableCell className="innerCell">
                                                                    <button className="btn btn-danger"
                                                                            id={term.id}
                                                                            onClick={handleOpenDeleteLevel}>
                                                                        <i className="fa fa-trash-o"/>
                                                                        Delete
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                            count={filteredLevels[0] === "" && filteredLevels.length === 1 ? 0 : filteredLevels.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                <div style={showAddLevelForm === false ? {display: "none"} : {}} className='col'>
                    <AddLevel closeSlidedAddLevel={closeSlidedAddLevel} refreshContent={refreshContent}
                              showAlertMessage={showAlertMessage}/>
                </div>
                <div style={showEditLevelForm === false ? {display: "none"} : {}} className='col'>
                    <EditLevel level={selectedLevel} closeSlidedEditLevel={closeSlidedEditLevel}
                               refreshContent={refreshContent} showAlertMessage={showAlertMessage}/>
                </div>


            </div>

        </div>
    );
};

export default Levels;