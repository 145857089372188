import {Card, CardBody} from "reactstrap";
import React from "react";
import RoleService from "../../repository/roleRepository";


const DeleteRole = props => {
    const [formData, setFormData] = React.useState({
        id: props.id,

    });

    const submitForm = (e) => {

        e.preventDefault();
        const id = formData.id;
        RoleService.deleteRole(id).then(() => {
            props.refreshContent();
            props.handleCloseDelete();
            props.showAlertMessage("You successfully deleted a role!");
        });

    }

    const handleCloseDelete = () => {
        props.handleCloseDelete();
    }

    return (


        <div className="container">

            <div className="row mt-5">
                <div className="col mainCol">
                    <Card style={{marginTop: "10%"}}>
                        <CardBody>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <span/>
                                <h3 className="formHeader">Delete</h3>
                                <button onClick={handleCloseDelete} className="btn-close"
                                        aria-label="Close"/>
                            </div>
                            <form onSubmit={submitForm}>
                                <div className="form-group" style={{display: "flex", justifyContent: "center"}}>
                                    Are you sure you want to delete this Role?


                                </div>
                                <br/>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <button type="submit" id="submit" className="btn btn-outline-danger ">Delete
                                    </button>
                                    <br/>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DeleteRole;