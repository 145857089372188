import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarContent, SidebarFooter,
} from 'react-pro-sidebar';
import {HiOutlineDocumentText} from "react-icons/hi";
import {AiOutlinePaperClip} from "react-icons/ai";
import {GiPapers} from "react-icons/gi";
import {IoNewspaperOutline} from "react-icons/io5";
import {RiAdminFill} from "react-icons/ri"
import 'react-pro-sidebar/dist/css/styles.css';
import "./SideBar.css";
import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import {CgChevronLeft, CgChevronRight} from "react-icons/cg";

const SideBar = () => {
    const [statusCollapsed, setStatusCollapsed] = React.useState(window.innerWidth < 1351)
    const handleStatusCollapsed = () => {
        setStatusCollapsed(!statusCollapsed);
    }

    React.useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1351) {
                setStatusCollapsed(true)
            } else {
                setStatusCollapsed(false)
            }
        }

        window.addEventListener('resize', handleResize)
    })


    const [openCloseProjects, setOpenCloseProjects] = React.useState(false);
    const [openCloseNomenclatures, setOpenCloseNomenclatures] = React.useState(false);
    const [openCloseImports, setOpenCloseImports] = React.useState(false);
    const [openCloseReports, setOpenCloseReports] = React.useState(false);
    const [openCloseAdministration, setOpenCloseAdministration] = React.useState(false);

    const openProjects = () => {
        openCloseProjects === true ? setOpenCloseProjects(false) : setOpenCloseProjects(true);
        setOpenCloseNomenclatures(false);
        setOpenCloseImports(false);
        setOpenCloseReports(false);
        setOpenCloseAdministration(false);
    }
    const openNomenclatures = () => {
        openCloseNomenclatures === true ? setOpenCloseNomenclatures(false) : setOpenCloseNomenclatures(true);
        setOpenCloseProjects(false);
        setOpenCloseImports(false);
        setOpenCloseReports(false);
        setOpenCloseAdministration(false);
    }
    const openImports = () => {
        openCloseImports === true ? setOpenCloseImports(false) : setOpenCloseImports(true);
        setOpenCloseProjects(false);
        setOpenCloseNomenclatures(false);
        setOpenCloseReports(false);
        setOpenCloseAdministration(false);
    }
    const openReports = () => {
        openCloseReports === true ? setOpenCloseReports(false) : setOpenCloseReports(true);
        setOpenCloseProjects(false);
        setOpenCloseNomenclatures(false);
        setOpenCloseImports(false);
        setOpenCloseAdministration(false);
    }
    const openAdministration = () => {
        setOpenCloseProjects(false);
        setOpenCloseNomenclatures(false);
        setOpenCloseImports(false);
        setOpenCloseReports(false);
        openCloseAdministration === true ? setOpenCloseAdministration(false) : setOpenCloseAdministration(true);
    }

    useEffect(() => {
        document.querySelectorAll(".menu").forEach((elem) => {
            if (elem.childNodes[0].childNodes[0].children[0].href === window.location.href) {
                if (!elem.className.includes("added")) {
                    elem.style.background = "#031530";
                    elem.className += " added";
                }
            } else {
                if (elem.className.includes("added")) {
                    elem.style.background = "";
                    elem.className = elem.className.replace(" added", "");
                }
            }
        });
    })

    const changeColor = (e) => {

        try {
            let currClass = e.nativeEvent.path[3].classList.value;
            document.querySelectorAll(".menu").forEach((elem) => {
                if (elem.className === currClass && !elem.className.includes(" added")) {
                    elem.style.background = "#031530";
                    elem.className += " added";
                } else if (elem.className.includes(" added") && elem.className !== currClass) {
                    elem.style.background = "";
                    elem.className = elem.className.replace(" added", "");
                }
            });
        } catch {
            let currClass = e.target.parentNode.parentNode.parentNode.className;
            document.querySelectorAll(".menu").forEach((elem) => {
                if (elem.className === currClass && !elem.className.includes(" added")) {
                    elem.style.background = "#031530";
                    elem.className += " added";
                } else if (elem.className.includes(" added") && elem.className !== currClass) {
                    elem.style.background = "";
                    elem.className = elem.className.replace(" added", "");
                }
            });
        }
    }
    let userRole = localStorage.getItem('role');
    return (
        <div id="header">
            {userRole === "ROLE_ADMIN" ?
                <ProSidebar collapsed={statusCollapsed}>
                    <SidebarContent>
                        <Menu iconShape="square">
                            <SubMenu title="Projects" icon={<HiOutlineDocumentText/>} open={openCloseProjects}
                                     onOpenChange={openProjects}>
                                <MenuItem onClick={changeColor} className="menu projects"><Link
                                    to="/projects"/>Projects</MenuItem>
                                <MenuItem onClick={changeColor} className="menu validate"><Link
                                    to="/validate"/>Import of project structure</MenuItem>
                            </SubMenu>
                            <SubMenu title="Nomenclatures" icon={<GiPapers/>} open={openCloseNomenclatures}
                                     onOpenChange={openNomenclatures}>
                                <MenuItem onClick={changeColor} className="menu years"><Link
                                    to="/years"/>Years</MenuItem>
                                <MenuItem onClick={changeColor} className="menu levels"><Link
                                    to="/levels"/>Levels</MenuItem>
                                <MenuItem onClick={changeColor} className="menu exchange-rates"><Link
                                    to="/exchange-rates"/>Exchange rates</MenuItem>
                            </SubMenu>
                            <SubMenu title="Operations" icon={<AiOutlinePaperClip/>} open={openCloseImports}
                                     onOpenChange={openImports}>
                                {/*<MenuItem onClick={changeColor} className="menu mapping"><Link*/}
                                {/*    to="/mapping"/>Mapping</MenuItem>*/}
                                <MenuItem onClick={changeColor} className="menu import"><Link
                                    to="/import"/>Import files</MenuItem>
                                <MenuItem onClick={changeColor} className="menu expenses"><Link
                                    to="/expenses"/>Expenses management</MenuItem>
                                <MenuItem onClick={changeColor} className="menu reports"><Link
                                    to="/balance"/>Balance reports</MenuItem>
                            </SubMenu>
                            {/*<SubMenu title="Reports" icon={<IoNewspaperOutline/>} open={openCloseReports}*/}
                            {/*         onOpenChange={openReports}>*/}
                            {/*    <MenuItem className="menu2">a</MenuItem> /!*koga ke se stavi link napravi go samo menu*!/*/}
                            {/*    <MenuItem className="menu2">b</MenuItem> /!*koga ke se stavi link napravi go samo menu*!/*/}
                            {/*    <MenuItem className="menu2">c</MenuItem> /!*koga ke se stavi link napravi go samo menu*!/*/}
                            {/*</SubMenu>*/}
                            <SubMenu title="Administration" icon={<RiAdminFill/>} open={openCloseAdministration}
                                     onOpenChange={openAdministration}>
                                <MenuItem onClick={changeColor} className="menu roles"><Link
                                    to="/roles"/>Roles</MenuItem>
                                <MenuItem onClick={changeColor} className="menu users"><Link
                                    to="/users"/>Users</MenuItem>
                                <MenuItem onClick={changeColor} className="menu partners">
                                    <Link to="/partners/project"/>
                                    Partners
                                </MenuItem>
                                <MenuItem style={{display: "none"}} onClick={changeColor} className="menu partners">
                                    <Link to="/importsTemplatesManagement"/>
                                    Imports Templates Management
                                </MenuItem>
                            </SubMenu>
                            <div className="closemenu" onClick={handleStatusCollapsed}>
                                {/* changing menu collapse icon on click */}
                                {statusCollapsed ? (<CgChevronRight/>) : (<CgChevronLeft/>
                                )}
                            </div>
                        </Menu>
                    </SidebarContent>
                </ProSidebar> :
                <ProSidebar collapsed={statusCollapsed}>
                    <SidebarContent>
                        <Menu iconShape="square">
                            <SubMenu title="Operations" icon={<AiOutlinePaperClip/>} open={openCloseImports}
                                     onOpenChange={openImports}>
                                <MenuItem onClick={changeColor} className="menu import"><Link
                                    to="/import"/>Import files</MenuItem>
                                <MenuItem onClick={changeColor} className="menu expenses"><Link
                                    to="/expenses"/>Expenses management</MenuItem>
                            </SubMenu>
                            <div className="closemenu" onClick={handleStatusCollapsed}>
                                {statusCollapsed ? (<CgChevronRight/>) : (<CgChevronLeft/>
                                )}
                            </div>
                        </Menu>
                    </SidebarContent>
                </ProSidebar>}
        </div>

    );
};

export default SideBar;
