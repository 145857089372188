import React, {Component} from "react";
import "./LogIn.css";
import logo from "../../assets/images/logo-vector.svg";
import {FiUserPlus} from "react-icons/fi";
import userRepository from "../../repository/userRepository";
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import ErrorMessage from "./ErrorMessage";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            msg: '',
            role: "",
            errorMsgVisible: false,
            registerMsgVisible: false,
        }
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({...this.state, registerMsgVisible: true});
        }
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    handleKeyPress = (e) => {
        if (e.key === "Enter") {
            this.loginHandler();
        }
    }

    loginHandler = () => {
        userRepository.loginUser(
            this.state.username,
            this.state.password
        ).then(response => {
            localStorage.setItem("username", response.data.username);
            localStorage.setItem("email", response.data.email);
            localStorage.setItem("role", response.data.role);
            if (response.data.role === "ROLE_ADMIN") {
                window.location.href = "/projects"
            }else if(response.data.role === "ROLE_PARTNER"){
                window.location.href = "/import"
            }
        }).catch(error => {
            this.showErrorMessage();
        })
    }

    showErrorMessage = () => {
        this.setState({msg: "Incorrect username or password", errorMsgVisible: true})
        const timer = setTimeout(() => {
            this.clearErrorMessage();
        }, 2500);
    }

    showRegisterMessage = () => {
        this.setState({registerMsgVisible: true})
        const timer = setTimeout(() => {
            this.clearRegisterMessage();
        }, 2500);
    }

    clearErrorMessage = () => {
        this.setState({
            ...this.state,
            errorMsgVisible: false
        });


    }

    clearRegisterMessage = () => {
        this.setState({
            ...this.state,
            registerMsgVisible: false
        });
        window.history.replaceState({}, document.title)


    }

    render() {
        return (
            <div className="login" onKeyPress={this.handleKeyPress}>
                <div className="container">
                    <div className="row">
                        <div className="col" id="login_main_div">
                            <Card id="login_card">
                                <div className="row">
                                    <CardHeader title={"Log In"} id="login_header"/>
                                </div>
                                <ErrorMessage id={"login"} clearErrorMessage={this.clearErrorMessage}
                                              show={this.state.errorMsgVisible} msg={this.state.msg}/>
                                {this.props.location.state && this.props.location.state.from === "register" &&
                                <ErrorMessage id={"register"}
                                              show={this.state.registerMsgVisible}
                                              msg={this.props.location.state.msg}
                                              clearRegisterMessage={this.showRegisterMessage}
                                />}
                                {this.props.location.state && this.props.location.state.from === "resetPassword" &&
                                <ErrorMessage id={"resetPassword"}
                                              show={this.state.registerMsgVisible}
                                              msg={this.props.location.state.msg}
                                              clearResetPasswordMessage={this.showRegisterMessage}
                                />}
                                <CardBody className="login_card">
                                    <form>
                                        <div className="row">
                                            <input name="username" type="text" className="form-control login_inputs"
                                                   placeholder="Enter username or email" onChange={this.handleChange}/>
                                        </div>
                                        <div className="row mt-4">
                                            <input name="password" type="password" className="form-control login_inputs"
                                                   placeholder="Password" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form_check">
                                            <input type="checkbox" className="form-check-input"/>
                                            <label className="form-check-label">Remember me</label>
                                        </div>
                                        <div>
                                            <button type="button" className="form-control" id="login_button"
                                                    onClick={this.loginHandler}>Log in
                                            </button>
                                        </div>
                                    </form>
                                    <div className="row">
                                        <a href={'/reset/password'} className="login_options">Forgot Password?</a>
                                        <a href={'/register'} className="login_options" id="login_register">{
                                            <FiUserPlus/>} Register</a>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col">
                            <img src={logo} alt="" id="login_logo"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;
