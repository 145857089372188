import React, {Component} from "react";
import {useState} from "react";
import "./Register.css";
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import Logo_Register from "../../assets/images/logo-vector.svg";
import UserService from "../../repository/userRepository";
import {useHistory} from "react-router-dom";

const Register = () => {
    const history = useHistory();
    const [userRegistration, setUserRegistration] = useState({
        username: "",
        email: "",
        password: "",
        repeatPassword: ""
    })

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserRegistration({...userRegistration, [name]: value})
    }

    const handleRegister = () => {
        if (userRegistration.repeatPassword !== userRegistration.password) {
            alert("Passwords do not match!");
        } else {
            UserService.register(userRegistration.username, userRegistration.password,
                userRegistration.repeatPassword, userRegistration.email).then(() => {
                history.push({
                    pathname: "/login",
                    state: {
                        msg: "You have successfully registered!",
                        show: true,
                        from: "register"
                    }
                });
            });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleBackButton = () => {
        window.location.href = "/"
    }

    return (
        <div className="login">
            <div className="container container_login">
                <div className="row">
                    <div className="col" id="login_main_div">
                        <Card id="login_card">
                            <div className="row">
                                <CardHeader title={"Register"} id="login_header"/>
                            </div>
                            <CardBody className="login_card register_card">
                                <form className="register_form" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <input
                                            type="text"
                                            className=" form-control register_inputs"
                                            placeholder="Enter Username"
                                            name="username"
                                            value={userRegistration.username}
                                            onChange={handleInput}
                                            required
                                        />
                                    </div>
                                    <div className="row mt-4">
                                        <input
                                            type="email"
                                            className=" form-control register_inputs"
                                            placeholder="Enter Email"
                                            name="email"
                                            value={userRegistration.email}
                                            onChange={handleInput}
                                            required
                                        />
                                    </div>
                                    <div className="row mt-4">
                                        <input
                                            type="password"
                                            className=" form-control register_inputs"
                                            placeholder="Enter Password"
                                            name="password"
                                            value={userRegistration.password}
                                            onChange={handleInput}
                                            required
                                        />
                                        <small id="passwordHelpBlock" className="form-text text-muted">
                                            Your password must be 8-20 characters long,
                                            contain letters and numbers.
                                        </small>
                                    </div>
                                    <div className="row mt-4">
                                        <input
                                            type="password"
                                            className=" form-control register_inputs"
                                            placeholder="Confirm Password"
                                            name="repeatPassword"
                                            value={userRegistration.repeatPassword}
                                            onChange={handleInput}
                                            required
                                        />
                                        <small id="passwordHelpBlock" className="form-text text-muted">
                                            Your password must be 8-20 characters long,
                                            contain letters and numbers.
                                        </small>
                                    </div>
                                    <div>
                                        <button type="submit" className="form-control" id="login_button"
                                                onClick={handleRegister}>
                                            Register
                                        </button>
                                    </div>
                                </form>
                            </CardBody>
                            <div id="registerBackBtn">
                                <button
                                    style={{background: "rgba(0,0,128,1)", color: "white"}}
                                    onClick={handleBackButton}
                                    className="form-control btn_back_register"
                                >
                                    Back
                                </button>
                            </div>
                        </Card>
                    </div>
                    <div className="col">
                        <img src={Logo_Register} alt="" id="login_logo"/>{" "}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
