import {TableCell} from "@material-ui/core";
import React from "react";
import PartsService from "../../repository/partsRepository";
import "./PartLine.css";

const PartLine = (props) => {

    const [hDone, setHDone] = React.useState({status: false, id: 0});
    const [subPartsForPart, setSubPartsForPart] = React.useState({});
    const [partCloses, setPartCloses] = React.useState([]);
    let [fetchDataForPartIds, setFetchedDataForPartIds] = React.useState([]);

    const setBlueBackground = () => {
        setHDone({status: true, id: props.highlight});
    }

    const addSPHandler = async (id) => {
        await PartsService.getSubParts(id).then((subParts) => {
            const classOfCells = ".innerCell-" + id;
            const innerCells = document.querySelectorAll(classOfCells);
            const subPartsData = subParts.data;
            const e = innerCells[0].firstChild;
            if (subPartsData.length > 0) {
                subPartsForPart[id] = {"showSubParts": true, "subParts": subPartsData}
                const currentShowContent = subPartsForPart[id].showSubParts;
                const partSubParts = subPartsForPart[id].subParts;
                subPartsForPart[id].showSubParts = !currentShowContent;

                if (currentShowContent) {
                    e.innerHTML = "<+>";
                    for (var k = 0; k < partSubParts.length; k++) {
                        const classOfCellsSub = ".innerCell-" + partSubParts[k].id;
                        const innerCellsSub = document.querySelectorAll(classOfCellsSub);
                        for (var m = 0; m < innerCellsSub.length; m++) {
                            innerCellsSub[m].style.display = "none";
                        }

                        const tableRows = document.getElementsByClassName("customTableRow");
                        const rowsToBeDeleted = getAllRowsToBeDeleted(id, []);

                        for (var x = 0; x < tableRows.length; x++) {
                            for (var y = 0; y < rowsToBeDeleted.length; y++) {
                                if (tableRows[x].id === rowsToBeDeleted[y].toString()) {
                                    document.getElementById(tableRows[x].id).remove()
                                }
                            }
                        }
                    }
                } else {
                    e.innerHTML = "<->";
                    for (var k = 0; k < partSubParts.length; k++) {
                        const classOfCellsSub = ".innerCell-" + partSubParts[k].id;
                        const innerCellsSub = document.querySelectorAll(classOfCellsSub);
                        for (var m = 0; m < innerCellsSub.length; m++) {
                            innerCellsSub[m].style.display = "block";
                        }
                    }
                }
                let update = fetchDataForPartIds;
                update.splice(update.indexOf(id), 1);
                setFetchedDataForPartIds(update);
            }
        });
    }

    if (props.highlight.toString() === props.part.id.toString() && hDone.status === false && hDone.id.toString() !== props.highlight.toString()) {

        setBlueBackground();
    } else {
        const classNameValid = '.innerCell-' + props.highlight;
        const element = document.querySelector(classNameValid);
        if (element && hDone.status === false && hDone.id.toString() !== props.highlight.toString()) {
            setBlueBackground()
        }
    }

    const handleSlideAddSubpart = (e) => {
        props.slideAddSubpart(e);
        addSPHandler(e.target.value)
        setHDone({...hDone, status: false})

    }
    const handleDelete = (e) => {
        const parentCellId = parseInt(e.target.parentNode.parentNode.id);
        props.handleOpenDeletePart(e);
        addSPHandler(parentCellId);
    }

    const setPartsClosesArray = (id, subPartsData) => {
        let partClosesArray = partCloses;
        let partsToBeClosed = [];
        let flag = true;
        for (var i = 0; i < partClosesArray.length; i++) {
            if (partClosesArray[i].id.toString() === id.toString()) {
                flag = false;
            }
        }
        for (var i = 0; i < subPartsData.length; i++) {
            partsToBeClosed.push(subPartsData[i].id);
        }
        partClosesArray.push({"id": id, "closes": partsToBeClosed})
        setPartCloses(partClosesArray);

    }

    const chooseColor = (id) => {
        const randomColor = require('randomcolor'); // import the script
        const tinyColor = require('tinycolor2');

        if (partCloses.length === 1 || partCloses[0].closes.includes(id)) {
            return randomColor({
                luminosity: 'light',
                format: 'hex',
                hue: '',
            });
        }
        for (let i = 0; i < partCloses.length; i++) {
            const closesArray = partCloses[i].closes;
            for (let j = 0; j < closesArray.length; j++) {
                if (closesArray[j].toString() === id.toString()) {
                    const parentColor = document.getElementById(partCloses[i].id).firstChild.style.background;

                    let newColor = tinyColor(parentColor).brighten(15).toString();
                    newColor = tinyColor(newColor).darken(1).toString();

                    return newColor;
                }
            }
        }
    }

    const handleToggle = async (e) => {
        const id = e.target.value;
        // const toggleButtonParent = e.target.parentNode;
        await PartsService.getSubParts(id).then((subParts) => {
            const classOfCells = ".innerCell-" + id;
            const innerCells = document.querySelectorAll(classOfCells);
            const subPartsData = subParts.data;
            setPartsClosesArray(id, subPartsData);

            if (subPartsData.length > 0) {
                if (!fetchDataForPartIds.includes(id)) {
                    let updateList = [...fetchDataForPartIds];
                    updateList.push(id);
                    setFetchedDataForPartIds(updateList);
                    fetchDataForPartIds.push(id);

                    subPartsForPart[id] = {"showSubParts": true, "subParts": subPartsData}

                    const color = chooseColor(subPartsData[0].id);
                    for (var i = 0; i < subPartsData.length; i++) {
                        const tableRow = document.createElement('tr');
                        tableRow.className = "customTableRow MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-1";

                        const currentRow = document.getElementById(classOfCells.split('-')[1])
                        for (var j = 0; j < innerCells.length; j++) {
                            if (innerCells[j].id === "toggle-" + id) {
                                const tableCell = document.createElement('td');
                                tableCell.id = "toggle-" + subPartsData[i].id;
                                tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + subPartsData[i].id;

                                tableCell.style.background = color;
                                tableCell.style.verticalAlign = "middle";
                                tableCell.style.borderRadius = "15px 0 0 15px";

                                switch (subPartsData[i].level.description) {
                                    case ("header"):
                                        tableCell.style.borderLeft = "6px solid #004AAC";
                                        break;
                                    case ("activity"):
                                        tableCell.style.borderLeft = "4px solid #004AAC";
                                        break;
                                    case ("subactivity"):
                                        tableCell.style.borderLeft = "2px solid #004AAC";
                                        break;
                                }

                                const toggleButton = document.createElement('button');
                                toggleButton.value = subPartsData[i].id;
                                toggleButton.onclick = handleToggle;
                                toggleButton.type = "button";
                                toggleButton.innerHTML = "<+>";
                                toggleButton.className = "toggleButton";
                                toggleButton.id = "toggleButton-"+subPartsData[i].id;
                                tableCell.append(toggleButton);
                                //innerCells[j].append(tableCell);

                                tableRow.id = subPartsData[i].id;
                                tableRow.append(tableCell)
                            } else if (innerCells[j].id === "partCode-" + id) {
                                const tableCell = document.createElement('td');
                                tableCell.id = "partCode-" + subPartsData[i].id;
                                tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + subPartsData[i].id;
                                tableCell.innerHTML = subPartsData[i].partCode;

                                tableCell.style.background = color;
                                // innerCells[j].append(tableCell)

                                tableRow.append(tableCell)
                            } else if (innerCells[j].id === "description-" + id) {
                                const tableCell = document.createElement('td');
                                tableCell.id = "description-" + subPartsData[i].id;
                                tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-5 description innerCell-" + subPartsData[i].id;
                                tableCell.innerHTML = subPartsData[i].description;
                                tableCell.onclick = props.showDataForEachYear;
                                tableCell.style.cursor = "pointer";

                                switch (subPartsData[i].level.description) {
                                    case ("part"):
                                        tableCell.style.paddingLeft = "12px";
                                        break;
                                    case ("header"):
                                        tableCell.style.paddingLeft = "20px";
                                        break;
                                    case ("activity"):
                                        tableCell.style.paddingLeft = "28px";
                                        break;
                                    case ("subactivity"):
                                        tableCell.style.paddingLeft = "36px";
                                        break;
                                }
                                tableCell.style.textAlign = "left";
                                tableCell.style.background = color;
                                // innerCells[j].append(tableCell)

                                tableRow.append(tableCell)
                            } else if (innerCells[j].id === "ifesCode-" + id) {
                                const tableCell = document.createElement('td');
                                tableCell.id = "ifesCode-" + subPartsData[i].id;
                                tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 description innerCell-" + subPartsData[i].id;
                                tableCell.innerHTML = subPartsData[i].ifesCode;

                                tableCell.style.padding = "15px !important";
                                tableCell.style.background = color;
                                // innerCells[j].append(tableCell)

                                tableRow.append(tableCell)
                            } else if (innerCells[j].id === "level-" + id) {
                                const tableCell = document.createElement('td');
                                tableCell.id = "level-" + subPartsData[i].id;
                                tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + subPartsData[i].id;
                                tableCell.innerHTML = subPartsData[i].level.description;

                                tableCell.style.background = color;
                                // innerCells[j].append(tableCell)

                                tableRow.append(tableCell)
                            }
                                // } else if (innerCells[j].id === "unit-" + id) {
                                //     const tableCell = document.createElement('td');
                                //     tableCell.id = "unit-" + subPartsData[i].id;
                                //     tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + subPartsData[i].id;
                                //     tableCell.innerHTML = subPartsData[i].unit === undefined || subPartsData[i].unit === "" ? "-" : subPartsData[i].unit;
                                //
                                //     tableCell.style.background = color;
                                //     //innerCells[j].append(tableCell)
                                //
                                //     tableRow.append(tableCell)
                                // }
                                // else if (innerCells[j].id === "priceUnit-" + id) {
                                //     const tableCell = document.createElement('td');
                                //     tableCell.id = "priceUnit-" + subPartsData[i].id;
                                //     tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + subPartsData[i].id;
                                //     tableCell.innerHTML = subPartsData[i].priceUnit === null ? "-" : subPartsData[i].priceUnit;
                                //
                                //     tableCell.style.background = color;
                                //     // innerCells[j].append(tableCell)
                                //
                                //     tableRow.append(tableCell)
                                // }
                                // else if (innerCells[j].id === "quantity-" + id) {
                                //     const tableCell = document.createElement('td');
                                //     tableCell.id = "quantity-" + subPartsData[i].id;
                                //     tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + subPartsData[i].id;
                                //     tableCell.innerHTML = subPartsData[i].quantity === null ? "-" : subPartsData[i].quantity;
                                //
                                //     tableCell.style.background = color;
                                //     // innerCells[j].append(tableCell)
                                //
                                //     tableRow.append(tableCell)
                            // }
                            else if (innerCells[j].id === "edit-" + id) {
                                const tableCell = document.createElement('td');
                                tableCell.id = "edit-" + subPartsData[i].id;
                                tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + subPartsData[i].id;

                                tableCell.style.background = color;

                                const button = document.createElement('button');
                                button.className = "btn btn-success partLineButtons";
                                button.innerHTML = "Edit";
                                button.value = subPartsData[i].id;
                                button.onclick = props.slideEditPart;
                                tableCell.append(button);
                                // innerCells[j].append(tableCell)

                                tableRow.append(tableCell)
                            } else if (innerCells[j].id === "add-" + id) {
                                const tableCell = document.createElement('td');
                                tableCell.id = "add-" + subPartsData[i].id;
                                tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + subPartsData[i].id;

                                tableCell.style.background = color;

                                const button = document.createElement('button');
                                button.className = "btn btn-success partLineButtons";
                                button.innerHTML ="Add";
                                button.value = subPartsData[i].id;
                                button.name = subPartsData[i].level.orderNumber;
                                button.onclick = handleSlideAddSubpart;
                                tableCell.append(button);
                                // innerCells[j].append(tableCell)

                                tableRow.append(tableCell)
                            }
                                // else if (innerCells[j].id === "details-" + id) {
                                //     const tableCell = document.createElement('td');
                                //     tableCell.id = "details-" + subPartsData[i].id;
                                //     tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + subPartsData[i].id;
                                //
                                //     tableCell.style.background = color;
                                //     tableCell.style.borderRadius = "0 15px 15px 0";
                                //
                                //     const button = document.createElement('button');
                                //     button.className = "btn btn-info partLineButtons details";
                                //     button.innerHTML = ReactDOMServer.renderToString(<FcViewDetails/>) + " Details";
                                //     button.value = subPartsData[i].id;
                                //     button.onclick = props.showDataForEachYear;
                                //     button.id = subPartsData[i].id;
                                //     tableCell.append(button);
                                //     // innerCells[j].append(tableCell)
                                //
                                //     tableRow.append(tableCell)
                            // }
                            else if (innerCells[j].id === "delete-" + id) {
                                const tableCell = document.createElement("td");
                                tableCell.id = "delete-" + subPartsData[i].id;
                                tableCell.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + subPartsData[i].id;

                                tableCell.style.background = color;

                                const button = document.createElement("button");
                                button.className = "bi bi-trash btn btn-danger partLineButtons";
                                button.innerHTML ="Delete";
                                button.value = subPartsData[i].id;
                                button.onclick = handleDelete;
                                button.id = subPartsData[i].id;
                                tableCell.append(button);
                                // innerCells[j].append(tableCell);

                                tableRow.append(tableCell)
                            }
                        }

                        // debugger
                        // let flag = true;
                        // const allCustomTableRows = document.getElementsByClassName("customTableRow");
                        // for (var k = 0; k < allCustomTableRows.length; k++){
                        //    if (allCustomTableRows[k].id === tableRow.id){
                        //        flag = false;
                        //    }
                        // }
                        // if (flag) {
                        //     if (currentRow){
                        //         currentRow.parentNode.insertBefore(tableRow, currentRow.nextSibling);
                        //     } else {
                        //         const currentRowTop = document.getElementById(props.part.id);
                        //         currentRowTop.parentNode.insertBefore(tableRow, currentRowTop.nextSibling);
                        //     }
                        // } else {
                        //     document.getElementById(tableRow.id).style.display = "inline";
                        // }

                        if (currentRow) {
                            currentRow.parentNode.insertBefore(tableRow, currentRow.nextSibling);
                        } else {
                            const currentRowTop = document.getElementById(props.part.id);
                            currentRowTop.parentNode.insertBefore(tableRow, currentRowTop.nextSibling);
                        }

                    }

                    e.target.innerHTML = "<->";

                } else {
                    const currentShowContent = subPartsForPart[id].showSubParts;
                    const partSubParts = subPartsForPart[id].subParts;
                    subPartsForPart[id].showSubParts = !currentShowContent;

                    if (currentShowContent) {
                        e.target.innerHTML = "<+>";
                        for (var k = 0; k < partSubParts.length; k++) {
                            const classOfCellsSub = ".innerCell-" + partSubParts[k].id;
                            const innerCellsSub = document.querySelectorAll(classOfCellsSub);
                            for (var m = 0; m < innerCellsSub.length; m++) {
                                innerCellsSub[m].style.display = "none";
                            }
                        }

                        const rowsToBeDeleted = getAllRowsToBeDeleted(id, []);

                        for (var x = 0; x < rowsToBeDeleted.length; x++) {

                            const rowToBeRemoved = document.getElementById(rowsToBeDeleted[x]);

                            if (rowToBeRemoved) {
                                rowToBeRemoved.remove();
                            }
                        }

                    } else {
                        e.target.innerHTML = "<->";
                        for (var k = 0; k < partSubParts.length; k++) {
                            const classOfCellsSub = ".innerCell-" + partSubParts[k].id;
                            const innerCellsSub = document.querySelectorAll(classOfCellsSub);
                            for (var m = 0; m < innerCellsSub.length; m++) {
                                innerCellsSub[m].style.display = "block";
                            }
                            // for (var x = 0; x < tableRows.length; x++){
                            //     for (var y = 0; y < partSubParts.length; y++){
                            //         if (tableRows[x].id === partSubParts[y].id.toString()){
                            //             tableRows[x].setAttribute("display", "inline");
                            //         }
                            //     }
                            // }
                        }
                    }
                    let update = fetchDataForPartIds;
                    update.splice(update.indexOf(id), 1);
                    setFetchedDataForPartIds(update);
                }
            }
        });
    }

    const getAllRowsToBeDeleted = (id, array) => {

        if (id === null) {
            return array;
        }

        for (var i = 0; i < partCloses.length; i++) {
            if (partCloses[i].id === id.toString()) {
                array.push(...partCloses[i].closes);
                if (partCloses[i].closes !== null) {
                    for (var j = 0; j < partCloses[i].closes.length; j++) {
                        getAllRowsToBeDeleted(partCloses[i].closes[j], array);
                    }
                } else {
                    getAllRowsToBeDeleted(id, array);
                }
            }
        }

        return array;
    }

    return (
        <div className={"partLine innerRow-" + props.part.id}>
            <tr className="customTableRow firstRow MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-1"
                id={props.part.id} style={{borderBottom: "1px solid gray",}}>
                <TableCell id={"toggle-" + props.part.id}
                           className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + props.part.id}>
                    <button id={"toggleButton-" + props.part.id} value={props.part.id} type="button"
                            className="toggleButton" onClick={handleToggle}> &lt;+>
                    </button>
                </TableCell>
                <TableCell id={"partCode-" + props.part.id}
                           className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + props.part.id}>{props.part.partCode}
                </TableCell>
                <TableCell onClick={props.showDataForEachYear} id={"description-" + props.part.id}
                           style={{textAlign: "left", cursor: "pointer"}}
                           className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-5 innerCell-" + props.part.id}>{props.part.description}</TableCell>
                <TableCell id={"ifesCode-" + props.part.id}
                           className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + props.part.id}>{props.part.ifesCode}</TableCell>
                <TableCell id={"level-" + props.part.id}
                           className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + props.part.id}>{props.part.level !== null ? props.part.level.description : ""}</TableCell>
                {/*<TableCell id={"unit-" + props.part.id}*/}
                {/*           className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + props.part.id}>{props.part.unit}</TableCell>*/}
                {/*<TableCell id={"priceUnit-" + props.part.id}*/}
                {/*           className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + props.part.id}>{props.part.priceUnit === null ? "-" : props.part.priceUnit}</TableCell>*/}
                {/*<TableCell id={"quantity-" + props.part.id}*/}
                {/*           className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + props.part.id}>{props.part.quantity === null ? "-" : props.part.quantity}</TableCell>*/}
                <TableCell id={"edit-" + props.part.id}
                           className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + props.part.id}>
                    <button value={props.part.id} className="btn btn-success partLineButtons"
                            onClick={props.slideEditPart}>Edit
                    </button>
                </TableCell>
                <TableCell id={"add-" + props.part.id}
                           className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + props.part.id}>
                    <button value={props.part.id} name={props.part.level.orderNumber}
                            className="btn btn-success partLineButtons"
                            onClick={handleSlideAddSubpart}>Add
                    </button>
                </TableCell>
                <TableCell
                    id={"delete-" + props.part.id}
                    className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + props.part.id}
                >
                    <button
                        id={props.part.id}
                        className="btn btn-danger partLineButtons"
                        onClick={props.handleOpenDeletePart}
                    >
                       Delete
                    </button>
                </TableCell>
                {/*<TableCell id={"details-" + props.part.id} className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 innerCell-" + props.part.id}>*/}
                {/*    <button className="btn btn-info partLineButtons details" id={props.part.id}*/}
                {/*            onClick={props.showDataForEachYear}>{<FcViewDetails/>} Details*/}
                {/*    </button>*/}
                {/*</TableCell>*/}
            </tr>
        </div>
    )
}

export default PartLine;

