import React, {useEffect} from 'react';
import {Card, CardBody} from "reactstrap";
import '../../assets/general.css'
import CardHeader from "@material-ui/core/CardHeader";
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {VscAdd} from 'react-icons/vsc';
import {FaRegEdit} from 'react-icons/fa';
import YearsService from "../../repository/yearsRepository";
import AddYear from "./AddYear";
import EditYear from "./EditYear";
import DeleteYear from './DeleteYear';
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import Alert from "react-bootstrap/Alert";

const Years = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [showAddYearForm, setShowAddYearForm] = React.useState(false);
    const [showEditYearForm, setShowEditYearForm] = React.useState(false);
    const [filteredYears, setFilteredYears] = React.useState([]);
    const [alertMessage, setAlertMessage] = React.useState(false);
    const [alertMessageText, setAlertMessageText] = React.useState("");

    const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
    const showAlertMessage = (message) => {
        let a;
        setAlertMessageText(message);
        setAlertMessage(true);
        if (clearPreviousTimeout !== undefined) {
            clearTimeout(clearPreviousTimeout);
        }
        a = setTimeout(() => {
            setAlertMessage(false)
        }, 2000);
        setClearPreviousTimeout(a);
    }

    const slideAddYear = () => {
        setShowAddYearForm(true);
        setShowEditYearForm(false);
    }

    const closeSlidedAddYear = () => {
        setShowAddYearForm(false);
    }

    useEffect(() => {
        if (filteredYears.length === 0) {
            refreshContent();
        }
    })

    const refreshContent = () => {
        YearsService.fetchYears().then((years) => {
            if (years.data.length !== 0) {
                setFilteredYears(years.data);
            }
        })
    }

    const deleteRefreshContent = () => {
        YearsService.fetchYears().then((years) => {
            if (years.data.length !== 0) {
                setFilteredYears(years.data);
            } else {
                setFilteredYears([]);
            }
        })
    }
    const [selectedYear, setSelectedYear] = React.useState({});
    const slideEditYear = (e) => {
        let id = e.target.value;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.value;
        }
        YearsService.getOneYear(id).then((year) => {
            setSelectedYear(year.data);
            setShowEditYearForm(true);
            setShowAddYearForm(false);
        })
    }

    const closeSlidedEditYear = () => {
        setShowEditYearForm(false);
    }

    const [openDeleteYear, setOpenDeleteYear] = React.useState(false);
    const [id, setId] = React.useState();

    const handleOpenDeleteYear = (e) => {
        let id = e.target.value;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.value;
        }
        setId(id);
        setOpenDeleteYear(true);
    };

    const handleCloseDeleteYear = () => {
        setOpenDeleteYear(false);
    };

    const yearDelete = (
        <DeleteYear id={id} handleCloseDeleteYear={handleCloseDeleteYear} refreshContent={deleteRefreshContent}
                    showAlertMessage={showAlertMessage}/>
    )

    return (
        <div id="table">
            <Modal
                style={{width: "50%", margin: "auto"}}
                open={openDeleteYear}
                onClose={handleCloseDeleteYear}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {yearDelete}

            </Modal>
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded">
                        <div className="first_line">
                            <CardHeader title={"Years"} id="list_projects"/>
                            <Alert id="alert-success" variant='success' show={alertMessage}>
                                <p>{alertMessageText}</p>
                            </Alert>
                            <button type="button" className="btn btn-outline-success" id="create"
                                    onClick={slideAddYear}>{<VscAdd id="add_icon"/>} New Year
                            </button>
                        </div>
                        <CardBody>
                            {/*<input onKeyUp={filterTable} type="text" className="filterField" placeholder="Filter"/>*/}
                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer id="mainTable">
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Number</TableCell>
                                                    <TableCell data-priority="3">From Date</TableCell>
                                                    <TableCell data-priority="3">To Date</TableCell>
                                                    <TableCell data-priority="3">Edit</TableCell>
                                                    <TableCell data-priority="3">Delete</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredYears[0] === "" && filteredYears.length === 1 ?
                                                    <div/> :
                                                    filteredYears.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                        return (
                                                            <TableRow className="innerRow">
                                                                <TableCell
                                                                    className="innerCell">{term.number}</TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.fromDate}</TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.toDate}</TableCell>
                                                                <TableCell className="innerCell">
                                                                    <button className="btn btn-success"
                                                                            onClick={slideEditYear} value={term.id}>
                                                                        <i className="fa fa-pencil-square-o"/>
                                                                        Edit
                                                                    </button>
                                                                </TableCell>
                                                                <TableCell className="innerCell">
                                                                    <button className="btn btn-danger"
                                                                            value={term.id}
                                                                            onClick={handleOpenDeleteYear}>
                                                                        <i className="fa fa-trash-o"/>
                                                                        Delete
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                            count={filteredYears[0] === "" && filteredYears.length === 1 ? 0 : filteredYears.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                <div style={showAddYearForm === false ? {display: "none"} : {}} className='col'>
                    <AddYear closeSlidedAddYear={closeSlidedAddYear} refreshContent={refreshContent}
                             showAlertMessage={showAlertMessage}/>
                </div>
                <div style={showEditYearForm === false ? {display: "none"} : {}} className='col'>
                    <EditYear year={selectedYear} closeSlidedEditYear={closeSlidedEditYear}
                              refreshContent={refreshContent} showAlertMessage={showAlertMessage}/>
                </div>


            </div>

        </div>
    );
};


export default Years;