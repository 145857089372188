import {Card, CardBody} from "reactstrap";
import React from "react";
import RoleService from "../../repository/roleRepository";
import UserService from "../../repository/userRepository";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";


const AddUser = (props) => {

    const [formData, updateForData] = React.useState({
        username: "",
        email: "",
        role: null

    })

    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const closeSlidedAddUser = () => {
        props.closeSlidedAddUser();
        clearFields()
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const username = formData.username;
        const email = formData.email;
        const role = formData.role;
        let pass = true;
        if (username === "" || username === undefined) {
            setRequiredUsername(true)
            pass = false;
        } else {
            setRequiredUsername(false)
        }
        if (email === "" || email === undefined) {
            setRequiredEmail(true)
            pass = false;
        } else {
            setRequiredEmail(false);
        }
        if (role === null || role === undefined || role === "0") {
            setRequiredRole(true)
            pass = false;
        } else {
            setRequiredRole(false);
        }
        if (pass) {
            UserService.addUser(email, username, role).then(() => {
                //Ways you can handle resolve
                // First is to just redirect:
                // window.location.href="/projects";
                //Second is to do it asynchronously:
                props.closeSlidedAddUser()
                props.refreshContent();
                props.showAlertMessage("You successfully added a user!");
                clearFields();
                window.location.href = "/users";
            })
        }
    }
    const clearFields = () => {
        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        })
        document.querySelectorAll("select").forEach((item) => {
            item.value = 0;
        })
        updateForData({
            formData: {
                username: "",
                email: "",
                role: null
            }
        })
        setRequiredUsername(false);
        setRequiredEmail(false);
        setRequiredRole(false);
    }
    const suggestUsername = (e) => {
        let currentValue = e.target.value;
        const usernames = props.usernames;
        for (var i = 0; i < usernames.length; i++) {
            if (usernames[i].startsWith(currentValue)) {
                if (usernames[i] === currentValue) {
                    const randomnumber = Math.floor(Math.random() * (10000 - 100 + 1)) + 10000;
                    currentValue = currentValue + randomnumber.toString();
                    e.target.value = currentValue;
                    updateForData({
                        ...formData,
                        username: currentValue
                    })
                }
            }
        }
    }
    const [requiredEmail, setRequiredEmail] = React.useState(false);
    const [requiredUsername, setRequiredUsername] = React.useState(false);
    const [requiredRole, setRequiredRole] = React.useState(false);

    return (
        <div className="container Main_add_Project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody>
                            <div className="Header header_add_project">
                                <h1>Create User</h1>
                            </div>
                            <form>
                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Email
                                        </label>
                                        <div>
                                            <label style={requiredEmail === false ? {display: "none"} : {}}
                                                   className="text-danger">Please fill out this field!</label>
                                        </div>
                                        <input
                                            name="email"
                                            type="text"
                                            className="form_add_project form-control"
                                            aria-describedby="underText"
                                            placeholder="Enter email"
                                            onChange={handleChange}

                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Username
                                        </label>
                                        <div>
                                            <label style={requiredUsername === false ? {display: "none"} : {}}
                                                   className="text-danger">Please fill out this field!</label>
                                        </div>
                                        <input
                                            name="username"
                                            type="text"
                                            className="form_add_project form-control"
                                            aria-describedby="underText"
                                            placeholder="Enter username"
                                            onChange={handleChange}

                                            onKeyUp={suggestUsername}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Role
                                        </label>
                                        <div>
                                            <label style={requiredRole === false ? {display: "none"} : {}}
                                                   className="text-danger">Please select a role!</label>
                                        </div>
                                        <select name="role" className="form_add_project form-control"
                                                onChange={handleChange}>
                                            <option value={0} selected>Select role</option>
                                            {props.roles.map((role) => {
                                                return (<option value={role.id}>{role.name}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <span id="createCancel">
                                        <button
                                            onClick={onFormSubmit}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="create_button"
                                        >
                                            Create
                                        </button>

                                        <button
                                            onClick={closeSlidedAddUser}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="cancel_button"
                                        >
                                            Cancel
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}
export default AddUser;