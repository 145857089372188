import React, {useEffect} from 'react';
import {Card, CardBody} from "reactstrap";
import '../../assets/general.css'
import CardHeader from "@material-ui/core/CardHeader";
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {VscAdd} from 'react-icons/vsc';
import {FaRegEdit} from 'react-icons/fa';
import ExchangeRatesService from "../../repository/exchangeRateRepository";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import Alert from "react-bootstrap/Alert";
import AddExchangeRate from "../ExchangeRate/AddExchangeRate";
import EditYear from "../Years/EditYear";
import YearsService from "../../repository/yearsRepository";
import EditExchangeRate from "./EditExchangeRate";
import DeleteYear from "../Years/DeleteYear";
import DeleteExchangeRate from "./DeleteExchangeRate";

const ExchangeRates = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [showAddExchangeRateForm, setShowAddExchangeRateForm] = React.useState(false);
    const [showEditExchangeRateForm, setShowEditExchangeRateForm] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(false);
    const [alertMessageText, setAlertMessageText] = React.useState("");
    const [filteredExchangeRates, setFilteredExchangeRates] = React.useState([]);
    const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();

    const showAlertMessage = (message) => {
        let a;
        setAlertMessageText(message);
        setAlertMessage(true);
        if (clearPreviousTimeout !== undefined) {
            clearTimeout(clearPreviousTimeout);
        }
        a = setTimeout(() => {
            setAlertMessage(false)
        }, 2000);
        setClearPreviousTimeout(a);
    }

    const slideAddExchangeRate = () => {
        setShowAddExchangeRateForm(true);
        setShowEditExchangeRateForm(false);
    }

    const closeSlidedAddExchangeRate = () => {
        setShowAddExchangeRateForm(false);
    }

    const [selectedExchangeRate, setSelectedExchangeRate] = React.useState({});

    const slideEditExchangeRate= (e) => {
        let id = e.target.value;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.value;
        }
        ExchangeRatesService.getOneExchangeRate(id).then((er) => {
            setSelectedExchangeRate(er.data);
            setShowEditExchangeRateForm(true);
            setShowAddExchangeRateForm(false);
        })
    }

    const closeSlidedEditExchangeRate = () => {
        setShowEditExchangeRateForm(false);
    }

    const [openDeleteExchangeRate, setOpenDeleteExchangeRate] = React.useState(false);
    const [id, setId] = React.useState();

    const handleOpenDeleteExchangeRate = (e) => {
        let id = e.target.value;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.value;
        }
        setId(id);
        setOpenDeleteExchangeRate(true);
    };

    const handleCloseDeleteExchangeRate = () => {
        setOpenDeleteExchangeRate(false);
    };

    const deleteRefreshContent = () => {
        ExchangeRatesService.fetchExchangeRateHistory().then((er) => {
            if (er.data.length !== 0) {
                setFilteredExchangeRates(er.data);
            } else {
                setFilteredExchangeRates([]);
            }
        })
    }

    const exchangeRateDelete = (
        <DeleteExchangeRate id={id} handleCloseDeleteExchangeRate={handleCloseDeleteExchangeRate} refreshContent={deleteRefreshContent}
                    showAlertMessage={showAlertMessage}/>
    )

    useEffect(() => {
        if (filteredExchangeRates.length === 0) {
            refreshContent();
        }
    })

    const refreshContent = () => {
        ExchangeRatesService.fetchExchangeRateHistory().then((exchangeRates) => {
            if (exchangeRates.data.length !== 0) {
                setFilteredExchangeRates(exchangeRates.data);
            }
        })
    }

    return (
        <div id="table">
            <Modal
                style={{width: "50%", margin: "auto"}}
                open={openDeleteExchangeRate}
                onClose={handleCloseDeleteExchangeRate}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {exchangeRateDelete}

            </Modal>

            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded">
                        <div className="first_line">
                            <CardHeader title={"Exchange rates"} id="list_exchange_rates"/>
                            <Alert id="alert-success" variant='success' show={alertMessage}>
                                <p>{alertMessageText}</p>
                            </Alert>
                            <button type="button" className="btn btn-outline-success" id="create"
                                    onClick={slideAddExchangeRate}>{<VscAdd id="add_icon"/>} New Exchange Rate
                            </button>
                        </div>
                        <CardBody>
                            {/*<input onKeyUp={filterTable} type="text" className="filterField" placeholder="Filter"/>*/}
                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer id="mainTable">
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Date from</TableCell>
                                                    <TableCell data-priority="3">Date to</TableCell>
                                                    <TableCell data-priority="3">CHF to USD Exchange Rate</TableCell>
                                                    <TableCell data-priority="3">Edit</TableCell>
                                                    <TableCell data-priority="3">Delete</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredExchangeRates[0] === "" && filteredExchangeRates.length === 1 ?
                                                    <div/> :
                                                    filteredExchangeRates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                        return (
                                                            <TableRow className="innerRow">
                                                                <TableCell
                                                                    className="innerCell">{term.dateFrom}</TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.dateTo}</TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.chfToUsdExchangeRate}</TableCell>
                                                                <TableCell className="innerCell">
                                                                    <button className="btn btn-success"
                                                                            onClick={slideEditExchangeRate} value={term.id}>
                                                                        <i className="fa fa-pencil-square-o"/>
                                                                        Edit
                                                                    </button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <button className="btn btn-danger"
                                                                            value={term.id}
                                                                            onClick={handleOpenDeleteExchangeRate}>
                                                                        <i className="fa fa-trash-o"/>
                                                                        Delete
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                            count={filteredExchangeRates[0] === "" && filteredExchangeRates.length === 1 ? 0 : filteredExchangeRates.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                <div style={showAddExchangeRateForm === false ? {display: "none"} : {}} className='col'>
                    <AddExchangeRate closeSlidedAddExchangeRate={closeSlidedAddExchangeRate} refreshContent={refreshContent}
                             showAlertMessage={showAlertMessage}/>
                </div>

                <div style={showEditExchangeRateForm === false ? {display: "none"} : {}} className='col'>
                    <EditExchangeRate exchangeRate={selectedExchangeRate} closeSlidedEditExchangeRate ={closeSlidedEditExchangeRate}
                              refreshContent={refreshContent} showAlertMessage={showAlertMessage}/>
                </div>
            </div>
        </div>
    );
};

export default ExchangeRates;