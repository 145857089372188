import instance from "../custom-axios/axios";
import axios from "../custom-axios/axios";

const MappingService = {
    fetchQBooks:()=>{
        return axios.get(`/q_books_ifes`);
    },
    getQBookKeysForIfesKey:(ifesKey) => {
        return axios.get(`/q_books_ifes/q_books/ifes/${ifesKey}`);
    },
    combineQBookIfes:(ifesKey, qBookKey) => {
        return axios.post(`q_books_ifes/combine`, {
            ifesKey: ifesKey,
            qBookKey: qBookKey
        })
    }
}

export default MappingService;