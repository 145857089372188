import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {Card, CardBody} from "reactstrap";
import "./ResetPassword.css";
import Logo from "../../assets/images/logo-vector.svg";
import CardHeader from "@material-ui/core/CardHeader";
import {Redirect, useHistory} from "react-router-dom";
import UserService from "../../repository/userRepository";

const axios = require("axios");

const ResetPassword = () => {
    const history = useHistory();
    const [email, setEmail] = React.useState('sample@sample.com');

    let handleChange = (e) => {
        setEmail(e.target.value);
    }

    let handleResetPassword = () => {
        if (email.length === 0 || !email.includes('@')) {
            alert("Email is invalid");
            //info for email is invalid
        } else {
            UserService.resetPassword(email).then(() => {
                history.push({
                    pathname: "/login",
                    state: {
                        msg: "Your mail was successfully sent",
                        show: true,
                        from: "resetPassword"
                    }
                })
            });
        }
    }

    let handleBackBtn = () => {
        window.location.href = "/"
    }

    return (
        <div className="login">
            <div className="container">
                <div className="row">
                    <div className="col" id="login_main_div">
                        <Card id="login_card">
                            <div className="row">
                                <CardHeader title={"Reset Password"} id="login_header"/>
                            </div>
                            <CardBody className="login_card">
                                <form>
                                    <div className="row">
                                        <input
                                            type="email"
                                            className=" form-control login_inputs"
                                            aria-describedby="underText"
                                            placeholder="Enter email"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="row">
                                        <button
                                            type="button"
                                            className="form-control"
                                            id="resetPassword_button"
                                            onClick={handleResetPassword}>Send Mail
                                        </button>
                                    </div>
                                </form>
                            </CardBody>
                            <div className="row backbtn">
                                <div id="divBackBtn">
                                    <button
                                        style={{background: "rgba(0,0,128,1)", color: "white"}}
                                        onClick={handleBackBtn}
                                        className="form-control btn_back_resetPassword">Back
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col">
                        <img id="login_logo" src={Logo} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
