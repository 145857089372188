import {Card, CardBody} from "reactstrap";
import React, {useRef} from "react";
import LevelsService from "../../repository/levelsRepository";
import './Exchange.css'
import PartsService from "../../repository/partsRepository";
import ExchangeRateService from "../../repository/exchangeRateRepository";


const Exchange = (props) => {
    let remaining
    const firstInput = useRef()
    const secondInput = useRef()
    const thirdInput = useRef()
    const submit = (e) => {
        e.preventDefault()
        let yearPrice = props.year.existing.price
        let interval
        if (thirdInput.current.value > yearPrice) {
            document.getElementById("exchange_limit").style.display = "flex"
            interval = setInterval(() => {
                document.getElementById("exchange_limit").style.display = "none"
                clearInterval(interval)
            }, 3000)
        } else {
            remaining = yearPrice - firstInput.current.value
            let formData = {
                fromDate: props.year.year.fromDate,
                toDate: props.year.year.toDate,
                costPrevious: yearPrice,
                costToExchange: firstInput.current.value,
                costRemaining: remaining,
                exchangeRateToUsd: secondInput.current.value,
            }
            PartsService.exchange(props.partId, props.year.year.id, formData).then((r) => {
                props.closeExchange()
                props.closeEditPart()
            })
        }
    }

    const close = () => {
        props.closeExchange();
    }

    const changeValue = () => {
        thirdInput.current.value = (firstInput.current.value * secondInput.current.value).toFixed(3)
    }



    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card style={{marginTop: "10%"}}>
                        <CardBody>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <span/>
                                <h3 className="formHeader mb-4">Exchange CHF to USD</h3>
                                <button onClick={close} className="btn-close"
                                        aria-label="Close"/>
                            </div>
                            <form onSubmit={submit}>
                                <h6 className="mb-2">{props.year.year.fromDate} to {props.year.year.toDate}
                                    ({props.unit}):</h6>
                                <div id="exchange_input">
                                    <input className="form-control exchange_inputs" ref={firstInput} type="number" placeholder="CHF"
                                           step="any" onChange={changeValue}/>&ensp; x &ensp;
                                    <input className="form-control exchange_inputs" ref={secondInput} type="number"
                                           step="any" onChange={changeValue}
                                           placeholder="Exchange rate"/>&ensp; = &ensp;
                                    <input className="form-control exchange_inputs" readOnly ref={thirdInput} placeholder="USD"
                                           defaultValue={0}/>
                                </div>
                                <div id="exchange_limit">Limit exceeded</div>
                                <div id="exchange_save_btn">
                                    <button type="submit" className="btn btn-outline-warning">Save</button>
                                    <br/>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Exchange;