import instance from "../custom-axios/axios";
//user
const UserService = {
    fetchUsers: () => {
        return instance.get(`/users`);
    },

    getOneUser: (id) => {
        return instance.get(`/users/${id}`);
    },
    addUser: (email, username, role) => {

        return instance.post(`/users/create`, {email: email, username: username, role: role, host: window.location.origin});
    },
    getLoggedDetails: () => {
        return instance.get('/users/getLoggedDetails');
    },
    loginUser: (username, password) => {
        return instance.post('/login', {username: username, password: password});
    },

    editUser: (id, email, username, role) => {
        return instance.post(`/users/edit/${id}`, {id: id, email: email, username: username, role: role});
    },

    getAllUsernames: () => {
        return instance.get(`/users/getAllUsernames`);
    },
    resetPassword: (email) => {
        return instance.post('/change/forgot-password-mail', {email: email, host:window.location.origin});
    },
    register: (username, password, repeatedPassword, email) => {
        return instance.post('/register', {
            username: username,
            password: password,
            repeatedPassword: repeatedPassword,
            email: email,
        });
    },
    deleteUser: (id) => {
        return instance.post(`/users/delete/${id}`)
    },
    resetNewPassword: (email,newPassword, confirmNewPassword) => {
        return instance.post(`/change/forgot-password/reset`, {
            email:email,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword,

        })
    },
    getAllUsersWithRolePartner:()=>{
        return instance.get(`/users/getUsersWithRolePartner`);
    }
}
export default UserService;