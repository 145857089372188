import React, {useEffect, useState} from 'react';
import {Card, CardBody} from "reactstrap";
import '../../assets/general.css'
import './PartnerProject.css'
import CardHeader from "@material-ui/core/CardHeader";
import {
    Modal,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TablePagination, TableRow,
} from "@material-ui/core";
import PartnerService from "../../repository/partnerRepository";
import PartsService from "../../repository/partsRepository";
import UserService from "../../repository/userRepository";
import {VscAdd} from "react-icons/vsc";


const PartnerProject = (props) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [pagePartner, setPagePartner] = React.useState(0);
    const [rowsPerPagePartner, setRowsPerPagePartner] = React.useState(5);

    const handleChangePagePartner = (event, newPage) => {
        setPagePartner(newPage);
    };

    const handleChangeRowsPerPagePartner = (event) => {
        setRowsPerPagePartner(parseInt(event.target.value, 10));
        setPagePartner(0);
    };

    const [openAddPartner, setOpenAddPartner] = React.useState(false);
    const handleOpenAddPartner = () => {
        setOpenAddPartner(true);
    }
    const handleClosePartner = () => {
        setOpenAddPartner(false);
    }
    const [usersPartners, setUsersPartners] = React.useState(() => {
        UserService.getAllUsersWithRolePartner().then((snapshot) => {
            return snapshot.data;
        })
    });
    const savePartner = () => {
        const name = document.getElementById("name").value;
        const userId = document.getElementById("user").value;
        const form = new FormData();
        form.append("name", name);
        form.append("userId", userId);
        PartnerService.createPartner(form).then(() => {
            window.location.href = "/partners/project";
        })
    }

    const [projectAlertMessage, setProjectAlertMessage] = React.useState(false);
    const setSelectProjectAlert = () => {
        setProjectAlertMessage(true);
        setTimeout(() => {
            setProjectAlertMessage(false)
        }, 2000);
    }

    const [partnerId, setPartnerId] = React.useState(0);
    const [partId, setPartId] = React.useState(0);
    const handleHighlightPartnerConnected = (id) => {
        let element = null;
        document.querySelectorAll(".partnerCell").forEach((item) => {
            if (item.id.toString() === partnerId.toString()) {
                element = item;

            }
        });
        if (element !== null) {
            element.style.background = "#b5faaf";
            element.style.transition = "background 1s";

            setTimeout(() => {
                element.style.background = "white";

            }, 2000);

        }
    }
    const handlePartnerSelect = (e) => {
        if (selectedProjectId !== 0) {
            const id = e.target.id;
            setPartnerId(id);
        } else {
            // const element = e.target;
            // element.style.background="#f58c94";
            // element.style.transition ="background 2s";
            //
            // setTimeout(()=>{
            //     element.style.background="white";
            //
            // },2000);
            //
            setSelectProjectAlert();
        }

    }
    const [partnerAlertMessage, setPartnerAlertMessage] = React.useState(false);
    const setSelectPartnerAlert = () => {
        setPartnerAlertMessage(true);
        setTimeout(() => {
            setPartnerAlertMessage(false)
        }, 2000);
    }


    const [openSettingModal, setOpenSettingModal] = React.useState(false);
    const handlePartSelect = (e) => {
        if (partnerId !== 0) {
            const id = e.target.id.toString();
            setPartId(parseInt(id));
            setOpenSettingModal(true);
        } else {
            setSelectPartnerAlert();
        }
    }
    const handleCloseOpenSettingModal = () => {
        setOpenSettingModal(false);
    }
    const confirmPartnerForPart = () => {
        if (partnerId !== 0 && partId !== 0) {
            PartsService.setPartnerForPart(partId, partnerId).then(() => {
                let updatePartsFromProject = partsFromProject;
                updatePartsFromProject = updatePartsFromProject.filter(item => item.id.toString() !== partId.toString());
                setPartsFromProject(updatePartsFromProject);
                setOpenSettingModal(false);
                handleHighlightPartnerConnected();
                setPartnerId(0);
                setPartId(0);
            });
        }
    }
    const [partsFromProject, setPartsFromProject] = React.useState([]);
    const [selectedProjectId, setSelectedProjectId] = React.useState(0);

    const selectProject = (e) => {
        const id = e.target.value;
        setSelectedProjectId(id);
        const email = localStorage.getItem('email');
        const role = "ROLE_ADMIN";
        PartsService.getPartsForPartExpensesChoosePart(id, role, email).then((snapshot) => {
            setPartsFromProject(snapshot.data);
        });

    }

    return (
        <div>

            <div className="row mt-5 mb-5">

                <Modal
                    style={{width: "50%", margin: "auto"}}
                    open={openSettingModal}
                    onClose={handleCloseOpenSettingModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="container" style={{width: "50%"}}>

                        <div className="row mt-5">
                            <div className="col mainCol">
                                <Card style={{marginTop: "10%"}}>
                                    <CardBody>
                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <span/>

                                            <button onClick={handleCloseOpenSettingModal} className="btn-close"
                                                    aria-label="Close"/>
                                        </div>

                                        <div className="form-group" style={{display: "flex", justifyContent: "center"}}>
                                            By confirming you will set the selected partner for the selected part.


                                        </div>
                                        <br/>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <button onClick={confirmPartnerForPart} type="button"
                                                    className="btn btn-outline-success ">Confirm
                                            </button>
                                            <br/>
                                        </div>

                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </Modal>


                <Modal
                    style={{width: "50%", margin: "auto"}}
                    open={openAddPartner}
                    onClose={handleClosePartner}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >

                    <div className="container Main_add_Project">
                        <div className="row">
                            <div className="col mainCol">
                                <Card className="add_project_card shadow p-3 mb-5 mt-5 bg-white rounded">
                                    <CardBody>
                                        <div className="Header header_add_project">
                                            <h1>Create Partner</h1>
                                        </div>
                                        <form>
                                            <div className="row">
                                                <div id="partCode">
                                                    <label htmlFor="input_Part_Code" className="label_add_project">
                                                        Name
                                                    </label>

                                                    <input
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        className="form-control"
                                                        aria-describedby="underText"
                                                        placeholder="Enter name"
                                                    />
                                                </div>
                                            </div>


                                            <div className="row mb-5">
                                                <div id="partCode">
                                                    <label htmlFor="input_Part_Code" className="label_add_project">
                                                        User
                                                    </label>
                                                    <select id="user" className="form-select" name="user">
                                                        <option value={0}>-----</option>
                                                        {props.users.map((user) => {
                                                            return (<option
                                                                value={user.id}>{user.username + " (" + user.email + ")"}</option>)
                                                        })}
                                                    </select>
                                                </div>
                                            </div>


                                            <div className="row">
                                    <span id="createCancel">
                                        <button
                                            onClick={savePartner}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="create_button"
                                        >
                                            Create
                                        </button>

                                        <button
                                            onClick={handleClosePartner}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="cancel_button"
                                        >
                                            Cancel
                                        </button>
                                    </span>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>

                </Modal>


                <div className="col-6">

                    <div className="alert alert-danger"
                         style={projectAlertMessage === false ? {display: "none"} : {}}>Please select project first
                    </div>
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded" id="mainTableKey">
                        <div className="first_line">
                            <CardHeader title={"Partners"}/>
                        </div>
                        <CardBody>
                            <div className="table-rep-plugin">
                                <div className="" data-pattern="priority-columns">
                                    <TableContainer id="mainTable">
                                        <button style={{float: "right"}}
                                                className="btn btn-outline-success"
                                                onClick={handleOpenAddPartner}>{<VscAdd id="add_icon"/>} New Partner
                                        </button>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Name</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.partners.slice(pagePartner * rowsPerPagePartner, pagePartner * rowsPerPagePartner + rowsPerPagePartner).map((term) => {
                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell style={{cursor: "pointer"}}
                                                                       onClick={handlePartnerSelect} id={term.id}
                                                                       className="innerCell partnerCell">{term.description}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[5, 10, 15]}
                                page={pagePartner}
                                count={props.partners.length}
                                onChangePage={handleChangePagePartner}
                                rowsPerPage={rowsPerPagePartner}
                                onChangeRowsPerPage={handleChangeRowsPerPagePartner}

                            />
                        </CardBody>
                    </Card>
                </div>
                <div className="col-6">
                    <div className="alert alert-danger"
                         style={partnerAlertMessage === false ? {display: "none"} : {}}>Please select partner first
                    </div>


                    <Card className="customCard shadow p-3 mb-5 bg-white rounded" id="mainTableKey">
                        <div className="first_line">
                            <CardHeader title={"Projects"}/>
                        </div>

                        <div>
                            <label>Project</label><br/>
                            <select className="form-select" onChange={selectProject}>
                                <option value={0} selected>------</option>
                                {props.projects.map((project) => {
                                    return (<option value={project.id}>{project.name}</option>)
                                })}
                            </select>
                        </div>
                        <CardBody>
                            {/*<input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>*/}
                            <div className="table-rep-plugin">
                                <div className="" data-pattern="priority-columns">
                                    <TableContainer id="mainTable">
                                        <div style={partnerId === 0 ? {display: "none"} : {}}>Select a part:</div>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Part</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {partsFromProject.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell
                                                                style={term.partner === null ? {cursor: "pointer"} : {
                                                                    cursor: "pointer",
                                                                    color: "red"
                                                                }} onClick={handlePartSelect} id={term.id}
                                                                className="innerCell">{parseInt(term.ifesCode) + " - " + term.description}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[5, 10, 15]}
                                page={page}
                                count={props.projects.length}
                                onChangePage={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}

                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
}
export default PartnerProject;
