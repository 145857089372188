import axios from "../custom-axios/axios";

const PartnerService = {

    fetchPartners:()=>{
        return axios.get(`/partners`);
    },

    createPartner:(form)=>{
        return axios.post(`/partners`, form);
    }


}

export default PartnerService;