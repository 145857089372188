import React, {useEffect} from 'react';
import {Card, CardBody} from "reactstrap";
import './ProjectAll.css'
import './Parts.css'
import CardHeader from "@material-ui/core/CardHeader";
import {
    Grid,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {VscAdd} from 'react-icons/vsc';
import AddPart from "./AddPart";
import EditPart from "./EditPart";
import AddSubpart from "./AddSubpart";
import PartLine from "../PartLine/PartLine";
import PartsService from "../../repository/partsRepository";
import ProjectsService from "../../repository/projectsRepository";
import LevelsService from "../../repository/levelsRepository";
import Alert from "react-bootstrap/Alert";
import DeletePart from './DeletePart';
import DataForEachYear from "./DataForEachYear";
import YearsService from "../../repository/yearsRepository";

const Parts = props => {
        const [allParts, setAllParts] = React.useState([]);
        const [projectId, setProjectId] = React.useState(0);
        const [projectName, setProjectName] = React.useState("");
        useEffect(() => {
                const projectId = window.location.href.split("/").pop();
                ProjectsService.getOneProject(projectId).then((snapshot) => {
                    setProjectName(snapshot.data.name);
                })
                setProjectId(projectId)
                if (filteredParts.length === 0) {
                    PartsService.getPartsForProject(projectId).then((parts) => {
                        refreshContent()
                    })
                }
            }, []
        )

        const [page, setPage] = React.useState(0);
        const [rowsPerPage, setRowsPerPage] = React.useState(10);

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const [showAddPartForm, setShowAddPartForm] = React.useState(false);
        const [showEditPartForm, setShowEditPartForm] = React.useState(false);
        const [showAddSubpartForm, setShowAddSubpartForm] = React.useState(false);
        const [filteredParts, setFilteredParts] = React.useState([]);
        const [levelsForAddUser, setLevelsForAddUser] = React.useState([]);
        const [alertMessage, setAlertMessage] = React.useState(false);
        const [alertMessageText, setAlertMessageText] = React.useState("");

        const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
        const showAlertMessage = (message) => {
            let a;
            setAlertMessageText(message);
            setAlertMessage(true);
            if (clearPreviousTimeout !== undefined) {
                clearTimeout(clearPreviousTimeout);
            }
            a = setTimeout(() => {
                setAlertMessage(false)
            }, 2000);
            setClearPreviousTimeout(a);
        }

        const filterTable = (e) => {
            const value = e.target.value;
            if (value === null || value === "") {
                setFilteredParts(allParts);
            } else {
                PartsService.filterBy(projectId, value).then(part => setFilteredParts(part.data));
            }
            setPage(0);
        }

        const [project, setProject] = React.useState({});
        const [projectYears, setProjectYears] = React.useState([]);
        const slideAddPart = () => {
            if (document.getElementsByClassName('col-8 mainCol')[0] !== undefined) {
                document.getElementsByClassName('col-8 mainCol')[0].className = "col-8 mainCol";
            } else if (document.getElementsByClassName('col mainCol')[0] !== undefined) {
                document.getElementsByClassName('col mainCol')[0].className = "col-8 mainCol";
            }
            LevelsService.fetchLevels().then((levels) => {
                setLevelsForAddUser(levels.data);
            });
            ProjectsService.getOneProject(props.location.state !== undefined ? props.location.state.projectId : projectId).then((projectData) => {
                setProject(projectData.data);
            });
            ProjectsService.getProjectYearSizeByProjectId(props.location.state !== undefined ? props.location.state.projectId : projectId).then((years) => {
                setProjectYears(years.data)
            });
            setShowAddPartForm(true);
            setShowEditPartForm(false)
            setShowAddSubpartForm(false);
        }

        const closeSlidedAddPart = () => {
            if (document.getElementsByClassName('col-8 mainCol')[0] !== undefined) {
                document.getElementsByClassName('col-8 mainCol')[0].className = "col mainCol";
            }
            setShowAddPartForm(false);
        }

        const closeSlidedEditPart = () => {
            if (document.getElementsByClassName('col-8 mainCol')[0] !== undefined) {
                document.getElementsByClassName('col-8 mainCol')[0].className = "col mainCol";
            }
            setShowEditPartForm(false);
        }

        const closeSlidedAddSubpart = () => {
            if (document.getElementsByClassName('col-8 mainCol')[0] !== undefined) {
                document.getElementsByClassName('col-8 mainCol')[0].className = "col mainCol";
            }
            setShowAddSubpartForm(false);
        }

        const refreshContent = (id) => {
            PartsService.getPartsForProject(id === undefined ? props.location.state !== undefined ? props.location.state.projectId : projectId : id).then((parts) => {
                if (parts.data.length !== 0) {
                    setFilteredParts(parts.data);
                    setAllParts(parts.data);
                }
            })
          refreshEditedProject()
        }

        const deleteRefreshContent = () => {
            PartsService.getPartsForProject(props.location.state !== undefined ? props.location.state.projectId : projectId).then((parts) => {
                if (parts.data.length !== 0) {
                    setFilteredParts(parts.data);
                    setAllParts(parts.data);
                } else {
                    setFilteredParts([]);
                    setAllParts([]);
                }
            })
          refreshEditedProject()
        }

        const [selectedPart, setSelectedPart] = React.useState({});
        const [editingPartLevel, setEditingPartLevel] = React.useState({});
        const slideEditPart = async (e) => {
            setProjectYears([]);
            if (document.getElementsByClassName('col-8 mainCol')[0] !== undefined) {
                document.getElementsByClassName('col-8 mainCol')[0].className = "col-8 mainCol";
            } else if (document.getElementsByClassName('col mainCol')[0] !== undefined) {
                document.getElementsByClassName('col mainCol')[0].className = "col-8 mainCol";
            }
            const id = e.target.value;
            await LevelsService.fetchLevels().then((levels) => {
                setLevelsForAddUser(levels.data);
            });
            await ProjectsService.getOneProject(props.location.state !== undefined ? props.location.state.projectId : projectId).then((projectData) => {
                setProject(projectData.data);
            });
            let yearsDataNotComplete = [];
            await ProjectsService.getProjectYearSizeByProjectId(props.location.state !== undefined ? props.location.state.projectId : projectId).then((years) => {

                yearsDataNotComplete = years.data;
            });

            await PartsService.getOnePart(id).then((part) => {
                yearsDataNotComplete.forEach((yd) => {
                    let quantity_cost_per_year_filtered = []
                    for (var i = 0; i < part.data.quantity_cost_per_year.length; i++) {
                        if (part.data.quantity_cost_per_year[i].yearId === yd.year.id) {
                            quantity_cost_per_year_filtered.push(part.data.quantity_cost_per_year[i]);
                        }
                    }
                    if (quantity_cost_per_year_filtered.length > 0) {
                        yd['existing'] = {
                            "unit": quantity_cost_per_year_filtered[0].unit !== "undifined" ? quantity_cost_per_year_filtered[0].unit : "",
                            "price": quantity_cost_per_year_filtered[0].cost,
                            "quantity": quantity_cost_per_year_filtered[0].quantity
                        }
                    }
                });

                setProjectYears(yearsDataNotComplete);
                setSelectedPart(part.data);
                setEditingPartLevel(part.data.level);
                setShowEditPartForm(true);
                setShowAddPartForm(false);
                setShowAddSubpartForm(false);
            })
        }

        const slideAddSubpart = async (e) => {
            if (document.getElementsByClassName('col-8 mainCol')[0] !== undefined) {
                document.getElementsByClassName('col-8 mainCol')[0].className = "col-8 mainCol";
            } else if (document.getElementsByClassName('col mainCol')[0] !== undefined) {
                document.getElementsByClassName('col mainCol')[0].className = "col-8 mainCol";
            }
            const id = e.target.value
            const levelOrderNumber = e.target.name;

            await LevelsService.getLevelsWithOrderNumberGreaterThanParents(parseInt(levelOrderNumber)).then((levels) => {
                setLevelsForAddUser(levels.data);
            });

            await ProjectsService.getOneProject(props.location.state !== undefined ? props.location.state.projectId : projectId).then((projectData) => {
                setProject(projectData.data);
            });

            await PartsService.getOnePart(id).then((part) => {
                setSelectedPart(part.data);
            });

            ProjectsService.getProjectYearSizeByProjectId(props.location.state !== undefined ? props.location.state.projectId : projectId).then((years) => {
                setProjectYears(years.data)
            });
            setShowAddSubpartForm(true);
            setShowAddPartForm(false);
            setShowEditPartForm(false);
        }

        const [openDeletePart, setOpenDeletePart] = React.useState(false);
        const [id, setId] = React.useState();

        const handleOpenDeletePart = (e) => {
            const id = e.target.id;
            setId(id);
            setOpenDeletePart(true);
          PartsService.getOnePart(id).then((part) => {
            setSelectedPart(part.data);
          })
        }

        const handleCloseDeletePart = () => {
            setOpenDeletePart(false);
        }

        const partDelete = (
            <DeletePart id={id} projectId={props.location.state !== undefined ? props.location.state.projectId : projectId}
                        refreshContent={deleteRefreshContent}
                        handleCloseDeletePart={handleCloseDeletePart} showAlertMessage={showAlertMessage}/>
        )

        const [openDataForYear, setOpenDataForYear] = React.useState(false);
        const [quantityCostPerYear, setQuantityCostPerYear] = React.useState([]);
        const [financialInfo, setFinancialInfo] = React.useState({});
        const [filteredYears, setFilteredYears] = React.useState([]);
        const showDataForEachYear = (e) => {

            const id = e.target.id.split("-")[1];
            setId(id);
            let updatedFinancialInfo = {};
            PartsService.getOnePart(id).then((data) => {
                updatedFinancialInfo["unit"] = data.data.unit;
                updatedFinancialInfo["priceUnit"] = data.data.priceUnit;
                updatedFinancialInfo["quantity"] = data.data.quantity;
                setFinancialInfo(updatedFinancialInfo)
            })


            PartsService.getQuantityCostPerYear(id).then((data) => {
                setQuantityCostPerYear(data.data);
                setOpenDataForYear(true);
            });

            YearsService.fetchYears().then((years) => {
                if (years.data.length !== 0) {
                    setFilteredYears(years.data);
                }
            })
        }
        const closeDataForEachYear = () => {
            setOpenDataForYear(false);
        }

// const [alreadyTried, setAlreadyTried] = React.useState(false);
// if (filteredParts.length === 0 && alreadyTried === false){
//     setAlreadyTried(true)
//     const projectId = window.location.href.split("/").pop();
//     PartsService.getPartsForProject(projectId).then((parts)=>{
//         setFilteredParts(parts.data)
//     })
//
// }
//

        const stopPropagation = (e) => {
            e.stopPropagation();
        }
        const [highlightLine, setHighlightLine] = React.useState("");
        const handleHighlight = (id) => {
            setHighlightLine(id);
        }
        const yearBody = (
            <DataForEachYear id={id} financialInfo={financialInfo} closeDataForEachYear={closeDataForEachYear}
                             quantityCostPerYear={quantityCostPerYear} filteredYears={filteredYears}/>
        );

        const refreshEditedProject = () => {
          if (selectedPart.parentPartId != null) {
            document.getElementById('toggleButton-' + selectedPart.parentPartId).click()
            document.getElementById('toggleButton-' + selectedPart.parentPartId).click()
          }
        }

        return (
            <div className="" id="table">
                <Modal
                    open={openDeletePart}
                    onClose={handleCloseDeletePart}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {partDelete}
                </Modal>
                <Modal
                    open={openDataForYear}
                    onClose={closeDataForEachYear}
                >
                    {yearBody}
                </Modal>
                <div className="row mt-5">
                    <div className="col mainCol">
                        <Card className="customCard shadow p-3 mb-5 bg-white rounded">
                            <div className="first_line">
                                <CardHeader title={"Parts - " + projectName}
                                            id="list_projects"/>
                                <Alert id="alert-success" variant='success' show={alertMessage}>
                                    <p>{alertMessageText}</p>
                                </Alert>
                                <button type="button" className="btn btn-outline-success" id="create"
                                        onClick={slideAddPart}>{<VscAdd id="add_icon"/>} New Part
                                </button>
                            </div>
                            <CardBody>
                                <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>
                                <div className="table-rep-plugin">
                                    <div
                                        className=""
                                        data-pattern="priority-columns"
                                    >
                                        <TableContainer id="mainTable">
                                            <Table
                                                id="universityTable"
                                                className="table tableCustom"
                                                style={{width: "99%"}}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <Grid container spacing={1} className="tableHeadContainer"
                                                              alignContent="center">
                                                            <Grid item xs={1}><TableCell data-priority="3"/></Grid>
                                                            <Grid item xs={1}><TableCell data-priority="3">Part
                                                                Code</TableCell></Grid>
                                                            <Grid style={{display: "flex", justifyContent: "center"}} item
                                                                  xs={5}><TableCell data-priority="3">Part Name</TableCell></Grid>
                                                            <Grid item xs={1}><TableCell data-priority="3">IFES
                                                                Key</TableCell></Grid>
                                                            <Grid item xs={1}><TableCell data-priority="3">Level</TableCell></Grid>
                                                            {/*<Grid item xs={1}><TableCell data-priority="3">Unit</TableCell></Grid>*/}
                                                            {/*<Grid item xs={1}><TableCell data-priority="3">Price/Unit</TableCell></Grid>*/}
                                                            {/*<Grid item xs={1}><TableCell data-priority="3">Quantity</TableCell></Grid>*/}
                                                            <Grid item xs={1}><TableCell data-priority="3">Edit</TableCell></Grid>
                                                            <Grid item xs={1}><TableCell
                                                                data-priority="3">Add</TableCell></Grid>
                                                            <Grid item xs={1}><TableCell
                                                                data-priority="3">Delete</TableCell></Grid>
                                                            {/*<Grid item xs={1}><TableCell data-priority="3">Details</TableCell></Grid>*/}
                                                        </Grid>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filteredParts.length > 0 ? filteredParts[0] === "" && filteredParts.length === 1 ?
                                                        <div/> :
                                                        filteredParts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                                return (
                                                                    <PartLine highlight={highlightLine} part={term}
                                                                              slideEditPart={slideEditPart}
                                                                              slideAddSubpart={slideAddSubpart}
                                                                              stopPropagation={stopPropagation}
                                                                              handleOpenDeletePart={handleOpenDeletePart}
                                                                              showDataForEachYear={showDataForEachYear}
                                                                    />
                                                                )
                                                            }
                                                        ) : allParts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                        return (
                                                            <PartLine highlight={highlightLine} part={term}
                                                                      slideEditPart={slideEditPart}
                                                                      slideAddSubpart={slideAddSubpart}
                                                                      handleOpenDeletePart={handleOpenDeletePart}
                                                                      showDataForEachYear={showDataForEachYear}
                                                                      stopPropagation={stopPropagation}
                                                            />
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                            <TablePagination
                                                component="div"
                                                rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                                page={page}
                                                count={filteredParts.length > 0 ? filteredParts[0] === "" && filteredParts.length === 1 ? 0 : filteredParts.length : allParts.length}
                                                onChangePage={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </TableContainer>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div style={showAddPartForm === false ? {display: "none"} : {}} className='col-4'>
                        <AddPart project={project} levels={levelsForAddUser} closeSlidedAddPart={closeSlidedAddPart}
                                 refreshContent={refreshContent} showAlertMessage={showAlertMessage}
                                 years={projectYears}/>
                    </div>
                    <div style={showEditPartForm === false ? {display: "none"} : {}} className='col-4'>
                        <EditPart editingPartLevel={editingPartLevel} levels={levelsForAddUser}
                                  projectId={props.location.state !== undefined ? props.location.state.projectId : projectId}
                                  project={project} parts={selectedPart}
                                  closeSlidedEditPart={closeSlidedEditPart} refreshContent={refreshContent}
                                  showAlertMessage={showAlertMessage} years={projectYears}
                        />
                    </div>
                    <div style={showAddSubpartForm === false ? {display: "none"} : {}} className='col-4'>
                        <AddSubpart project={project} levels={levelsForAddUser} parts={selectedPart}
                                    closeSlidedAddSubpart={closeSlidedAddSubpart} refreshContent={refreshContent}
                                    years={projectYears} handleHighlight={handleHighlight}/>
                    </div>
                </div>
            </div>
        );
    }
;


export default Parts;