import './Nav.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navbar, Nav, Button, Dropdown} from 'react-bootstrap';
import logo from '../../assets/images/logo-vector.svg'
import {BsJustify, BsBellFill, BsFillPersonFill, BsSearch} from 'react-icons/bs';
import axios from "../../custom-axios/axios";

const NavigationBar = () => {
    let onLogoutClick = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        axios.get("/logout");
    }

    return (
        <Navbar className="nav_bar py-0">
            <Navbar.Brand href="/projects" className="navbar_logo">
                <img src={logo} className="logo" alt="logo"/>
            </Navbar.Brand>
            <div className="right_nav_bar">
                <Navbar.Collapse>
                    <span style={{fontSize:13, color:"white"}}>{localStorage.getItem('username') + " ("+localStorage.getItem('email')+") "}</span> <Nav>
                        {/*<Button variant="contained" className="icons_boxing"> <BsSearch class="icons_right"/> </Button>*/}
                        <Dropdown>
                            <Dropdown.Toggle variant="contained" className="icons_boxing">
                                <BsFillPersonFill className="icons_right"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu id="logoutDropDown">
                               <Dropdown.Item href='../Log_in/LogIn' onClick={onLogoutClick}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </Nav>
                </Navbar.Collapse>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        </Navbar>
    );
};

export default NavigationBar;
