import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Alert from 'react-bootstrap/Alert';
import './LogIn.css'

export default class ErrorMessage extends React.Component {
    constructor(props) {
        super(props);
    }

    handleClose = () => {
        this.props.clearErrorMessage();

    }

    componentDidMount() {
        if (this.props.id === "register") {
            this.props.clearRegisterMessage();
        } else if (this.props.id === "resetPassword") {
            this.props.clearResetPasswordMessage();
        }
    }

    render() {

        return (

            <div id="alert">
                {this.props.id === "login" ?
                    <Alert variant='danger' show={this.props.show} onClick={this.handleClose}>
                        <Alert.Heading>Error!</Alert.Heading>
                        <p>
                            {this.props.msg}
                        </p>
                    </Alert>
                    :
                    <Alert variant='success' show={this.props.show}>
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>
                            {this.props.msg}
                        </p>
                    </Alert>
                }
            </div>
        )
    }
}