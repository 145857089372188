import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {Card, CardBody} from "reactstrap";
import yearsRepository from "../../repository/yearsRepository";

const AddYear = (props) => {

    const [formData, updateForData] = React.useState({
        number: "",
        fromDate: "",
        toDate: ""
    })


    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const closeSlidedAddYear = () => {
        props.closeSlidedAddYear();
        clearFields()
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const number = formData.number;
        const fromDate = formData.fromDate;
        const toDate = formData.toDate;
        let pass = true;
        if (number === "" || number === undefined) {
            setRequiredNumber(true);
            pass = false;
        } else {
            setRequiredNumber(false)
        }
        if (fromDate === "" || fromDate === undefined) {
            setRequiredFromDate(true);
            pass = false;
        } else {
            setRequiredFromDate(false);
        }
        if (toDate === "" || toDate === undefined) {
            setRequiredToDate(true);
            pass = false;
        } else {
            setRequiredToDate(false)
        }
        if (pass) {
            yearsRepository.addYear(number, fromDate, toDate).then(() => {
                //Ways you can handle resolve
                // First is to just redirect:
                // window.location.href="/projects";
                //Second is to do it asynchronously:
                props.closeSlidedAddYear()
                props.refreshContent();
                props.showAlertMessage("You successfully added a year!");
                clearFields();
            })
        }
    }

    const clearFields = () => {
        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        })
        updateForData({
            formData: {
                number: "",
                fromDate: "",
                toDate: ""
            }
        })
        setRequiredNumber(false);
        setRequiredFromDate(false);
        setRequiredToDate(false);
    }
    const [requiredNumber, setRequiredNumber] = React.useState(false);
    const [requiredFromDate, setRequiredFromDate] = React.useState(false);
    const [requiredToDate, setRequiredToDate] = React.useState(false);

    return (
        <div className="container Main_add_Project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody>
                            <div className="Header header_add_project">
                                <h1>Create Year</h1>
                            </div>
                            <form>
                                <div className="row">
                                    <div id="number">
                                        <label htmlFor="input_Part_Code" className="label_add_project">
                                            Number
                                        </label>
                                        <div>
                                            <label style={requiredNumber === false ? {display: "none"} : {}}
                                                   className="text-danger">Please fill out this field!</label>
                                        </div>
                                        <input
                                            name="number"
                                            type="number"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter number"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="fromDate">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            From date
                                        </label>
                                        <div>
                                            <label style={requiredFromDate === false ? {display: "none"} : {}}
                                                   className="text-danger">Please choose a date!</label>
                                        </div>
                                        <input
                                            name="fromDate"
                                            type="date"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter date"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="toDate">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            To date
                                        </label>
                                        <div>
                                            <label style={requiredToDate === false ? {display: "none"} : {}}
                                                   className="text-danger">Please choose a date!</label>
                                        </div>
                                        <input
                                            name="toDate"
                                            type="date"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter date"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <span id="createCancel">
                                        <button
                                            onClick={onFormSubmit}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="create_button"
                                        >
                                            Create
                                        </button>

                                        <button
                                            onClick={closeSlidedAddYear}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="cancel_button"
                                        >
                                            Cancel
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};
export default AddYear;
