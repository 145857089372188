import {Card, CardBody} from "reactstrap";
import React from "react";
import levelsRepository from "../../repository/levelsRepository";

const EditLevel = (props) => {
    const [formData, updateForData] = React.useState({
        id: props.level.id,
        description: props.level.description,
        orderNumber: props.level.orderNumber,
    })
    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const closeSlidedEditLevel = () => {
        props.closeSlidedEditLevel();
        clearFields();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const id = formData.id === undefined ? props.level.id : formData.id;
        const description = formData.description === undefined ? props.level.description : formData.description;
        const orderNumber = formData.orderNumber === undefined ? props.level.orderNumber : formData.orderNumber;
        levelsRepository.editLevel(id, description, orderNumber).then(() => {
            //Ways you can handle resolve
            // First is to just redirect:
            props.closeSlidedEditLevel()
            props.refreshContent();
            props.showAlertMessage("You successfully edited a level!");
            clearFields();
            //Second is to do it asynchronously:
            // props.closeSlidedAddProject()
            // props.refreshContent();
        })
    }

    const clearFields = () => {
        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        })
        updateForData({
            formData: {
                description: "",
                orderNumber: "",
            }
        })
    }

    return (
        <div className="container Main_add_Project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody>
                            <div className="Header header_add_project">
                                <h1>Edit Level</h1>
                            </div>
                            <form>
                                <div className="row">
                                    <div id="description">
                                        <label htmlFor="input_Part_Code" className="label_add_project">
                                            Description
                                        </label>
                                        <input
                                            name="description"
                                            type="text"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter description"
                                            onChange={handleChange}
                                            value={formData.description !== undefined ? formData.description : props.level.description}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="orderNumber">
                                        <label htmlFor="input_Part_Name" className="label_add_project"
                                               style={{width: "110px"}}>
                                            Order Number
                                        </label>
                                        <input
                                            name="orderNumber"
                                            type="text"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter order number"
                                            onChange={handleChange}
                                            value={formData.orderNumber !== undefined ? formData.orderNumber : props.level.orderNumber}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <span id="createCancel">
                                        <button
                                            onClick={onFormSubmit}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="create_button"
                                        >
                                            Confirm
                                        </button>

                                        <button
                                            onClick={closeSlidedEditLevel}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="cancel_button"
                                        >
                                            Cancel
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};
export default EditLevel;
