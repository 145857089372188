import CardHeader from "@material-ui/core/CardHeader";
import React, {useEffect, useState} from "react";
import {
    Card,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {CardBody} from "reactstrap";
import PartsService from "../../repository/partsRepository";
import ConfirmModal from "../PartValidation/ConfirmModal";
import ProjectsService from "../../repository/projectsRepository";
import ExpensesService from "../../repository/partExpensesRepository";
import {AiOutlineCheck} from "react-icons/ai";


const PartBalance = (props) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const months = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [pageR, setPageR] = React.useState(0);
    const [rowsPerPageR, setRowsPerPageR] = React.useState(5);

    const handleChangePageR = (event, newPage) => {
        setPageR(newPage);
    };

    const handleChangeRowsPerPageR = (event) => {
        setRowsPerPageR(parseInt(event.target.value, 10));
        setPageR(0);
    };

    const [parts, setParts] = React.useState([]);
    const [refresh, setRefresh] = React.useState([]);
    const [selectedProjectId, setSelectedProjectId] = React.useState(0);
    const [initialFromDate, setInitialFromDate] = React.useState(null);
    const [initialToDate, setInitialToDate] = React.useState(null);
    const [selectedFrom, setSelectedFrom] = React.useState(0);
    const [selectedTo, setSelectedTo] = React.useState(0);

    const refreshContent = () => {
        if (selectedProjectId !== 0) {
            ProjectsService.getProjectYears(selectedProjectId).then((years) => {
                if (years.data.length > 0) {
                    let today = new Date();
                    const dd = String(today.getDate()).padStart(2, '0');
                    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    const yyyy = today.getFullYear();

                    today = yyyy + '-' + mm + '-' + dd;
                    const first = years.data[0];
                    const last = years.data.pop();
                    if (first.year.fromDate <= today) {
                        setInitialFromDate(first.year.fromDate);
                        setSelectedFrom(first.year.fromDate);
                    } else {
                        setInitialFromDate(today);
                        setSelectedFrom(today);
                    }
                    if (last.year.toDate <= today) {
                        setInitialToDate(last.year.toDate);
                        setSelectedTo(last.year.toDate)
                    } else {
                        setInitialToDate(today);
                        setSelectedTo(today)
                    }
                }
            });
            PartsService.getPartsForPartExpenses(selectedProjectId).then((partsData) => {
                setParts(partsData.data);
            });
        }
    }

    const selectProject = (e) => {
        const id = e.target.value;
        setSelectedProjectId(id);
        ProjectsService.getProjectYears(id).then((years) => {
            if (years.data.length > 0) {
                let today = new Date();
                const dd = String(today.getDate()).padStart(2, '0');
                const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                const yyyy = today.getFullYear();

                today = yyyy + '-' + mm + '-' + dd;
                const first = years.data[0];
                const last = years.data.pop();
                if (first.year.fromDate <= today) {
                    setInitialFromDate(first.year.fromDate);
                    setSelectedFrom(first.year.fromDate);
                } else {
                    setInitialFromDate(today);
                    setSelectedFrom(today);
                }
                if (last.year.toDate <= today) {
                    setInitialToDate(last.year.toDate);
                    setSelectedTo(last.year.toDate)
                } else {
                    setInitialToDate(today);
                    setSelectedTo(today)
                }
            }
        })
        PartsService.getPartsForPartExpenses(id).then((partsData) => {
            setParts(partsData.data);

        });
    }

    const [calculationList, setCalculationList] = React.useState([]);


    const handleSelectForCalculation = () => {
        let newList = [...calculationList];
        let updateParts = [...parts];
        let updateRefresh = [...refresh];

        selectedRows.forEach(selectedRow => {
            if (!newList.includes(selectedRow)) {
                newList.push(selectedRow);
                const element = updateParts.filter(part => part.id === selectedRow)[0];
                updateRefresh.push(element);
                updateParts.splice(updateParts.indexOf(element), 1);
            }
        });

        setCalculationList(newList);
        setRefresh(updateRefresh);
        setParts(updateParts);
        setSelectAll(false);

    }

    const handleRemoveFromCalculation = () => {
        let newList = [...calculationList];
        let updateParts = [...parts];
        let updateRefresh = [...refresh];

        selectedRowsRemove.forEach(selectedRowId => {
            if (newList.includes(selectedRowId)) {
                newList.splice(newList.indexOf(selectedRowId), 1);
                const element = updateRefresh.filter(elem => elem.id.toString() === selectedRowId.toString())[0];
                updateParts.push(element);
                updateRefresh.splice(updateRefresh.indexOf(element), 1);
            }
        });

        setCalculationList(newList);
        setRefresh(updateRefresh);
        setParts(updateParts);
        setSelectedRowsRemove([]);
        setSelectAllRemove(false);
        setSelectedRows([]);
        setSelectAll(false);
    }


    const selectFrom = (e) => {
        setSelectedFrom(e.target.value);
    }
    const selectTo = (e) => {
        setSelectedTo(e.target.value);
    }

    const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
    const [id, setId] = React.useState();

    const handleCloseConfirmModal = () => {
        setOpenConfirmModal(false);
    };

    const handleOpenConfirmModal = (e) => {
        setId(e.target.id);
        setOpenConfirmModal(true);
    }

    const [showMessage, setShowMessage] = React.useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = React.useState(false);
    const [selectedRowsRemove, setSelectedRowsRemove] = useState([]);
    const [selectAllRemove, setSelectAllRemove] = React.useState(false);

    const finalizeSelection = () => {
        const form = new FormData();
        form.append("parts", calculationList.sort());
        form.append("from", selectedFrom);
        form.append("to", selectedTo);
        PartsService.calculateBalance(form).then(() => {
            handleCloseConfirmModal();
            // setCalculationList([]);
            // setRefresh([]);
            // setSelectAll(false);
            // setSelectedRows([]);
            // refreshContent();
            window.location.href = "/balance"
            setShowMessage(true);
        });
    }

    const confirmModal = (
        <ConfirmModal id={id} handleCloseConfirmModal={handleCloseConfirmModal} finalizeSelection={finalizeSelection}/>
    );

    const handleRowSelection = (partId) => {
        setSelectedRows(selectedRows => {
            if (selectedRows.includes(partId)) {
                return selectedRows.filter(id => id !== partId);
            } else {
                return [...selectedRows, partId];
            }
        });
    };

    const handleSelectAll = (event) => {
        setSelectAll(event.target.checked);
        setSelectedRows(event.target.checked ? parts.map(part => part.id) : []);
    };

    const handleRowSelectionRemove = (partId) => {
        setSelectedRowsRemove(selectedRows => {
            if (selectedRows.includes(partId)) {
                return selectedRows.filter(id => id !== partId);
            } else {
                return [...selectedRows, partId];
            }
        });
    };

    const handleSelectAllRemove = (event) => {
        setSelectAllRemove(event.target.checked);
        setSelectedRowsRemove(event.target.checked ? refresh.map(part => part.id) : []);
    };

    return (
        <div id="table">
            <Modal
                style={{width: "50%", margin: "auto"}}
                open={openConfirmModal}
                onClose={handleCloseConfirmModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">

                {confirmModal}
            </Modal>
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded"
                          style={{maxHeight: "77vh", overflow: "auto"}}>
                        <div className="first_line">
                            <CardHeader title={"Balance reports"} id="list_projects"/>
                        </div>
                        <div className="row mt-5">

                            <div className="col-6">
                                <label>Select project</label> <br/>
                                <select className="form-select" onChange={selectProject}>
                                    <option value={0} selected>---------</option>
                                    {props.projects.map((project) => {

                                        return (
                                            <option value={project.id}>{project.name}</option>
                                        )

                                    })}
                                </select>
                            </div>

                            <div className="col-6">
                                <div className="row">
                                    <div className="col-6">
                                        <label>From:</label> <br/>
                                        <input defaultValue={initialFromDate !== null ? initialFromDate : ''}
                                               min={initialFromDate !== null ? initialFromDate : ''} type="date"
                                               className="form-control" onChange={selectFrom}/>
                                    </div>

                                    <div className="col-6">
                                        <label>To:</label> <br/>
                                        <input defaultValue={initialToDate !== null ? initialToDate : ''}
                                               min={initialFromDate !== null ? initialFromDate : ''} type="date"
                                               className="form-control" onChange={selectTo}/>
                                    </div>

                                </div>
                            </div>


                        </div>
                        <CardBody>
                            <div className="row mt-3 export">
                                <a download
                                   href={process.env.REACT_APP_HOST_ENV + "/docs/downloadFile/" + props.lastExport}
                                   className="btn btn-outline-success btn-sm" style={{width: "200px"}}>Last
                                    Balance Export</a>
                                <label className="arrowBox" style={showMessage === false ? {display: "none"} : {}}>
                                    <div className="arrow"/>
                                </label>
                                <div className="popUp" style={showMessage === false ? {display: "none"} : {}}>
                                    Click to get your last export
                                </div>
                            </div>

                            <div className="table-rep-plugin mt-2">
                                <h4>All parts</h4>

                                <button style={{float: "right"}}
                                        onClick={handleSelectForCalculation}
                                        className="btn btn-outline-success">
                                    Select
                                </button>
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">
                                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAll}/>
                                                    </TableCell>
                                                    <TableCell data-priority="3">Part Code</TableCell>
                                                    <TableCell data-priority="3">Part Name</TableCell>
                                                    <TableCell data-priority="3">IFES Key</TableCell>
                                                    <TableCell data-priority="3">Level</TableCell>
                                                    {/*<TableCell data-priority="3">Calculate</TableCell>*/}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {parts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((part) => {
                                                    return (
                                                        <TableRow className={"partRow-" + part.id}>
                                                            <TableCell data-priority="3">
                                                                <input type="checkbox" checked={selectedRows.includes(part.id)}
                                                                        onChange={() => handleRowSelection(part.id)}/>
                                                            </TableCell>
                                                            <TableCell data-priority="3">
                                                                {part.partCode}
                                                            </TableCell>
                                                            <TableCell data-priority="3">
                                                                {part.description}
                                                            </TableCell>
                                                            <TableCell data-priority="3">
                                                                {part.ifesCode}
                                                            </TableCell>
                                                            <TableCell data-priority="3">
                                                                {part.level.description}
                                                            </TableCell>
                                                            {/*<TableCell data-priority="3">*/}
                                                            {/*    <button value={part.id}*/}
                                                            {/*            onClick={handleSelectForCalculation}*/}
                                                            {/*            className="btn btn-outline-success">*/}
                                                            {/*        Select*/}
                                                            {/*    </button>*/}
                                                            {/*</TableCell>*/}
                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                            page={page}
                                            count={parts.length}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>
                                </div>
                            </div>

                            <div className="table-rep-plugin mt-5">
                                <h4>Selected parts</h4>
                                <button style={{float: "right"}} onClick={handleOpenConfirmModal} id="partBalance"
                                        className="btn btn-outline-danger"><AiOutlineCheck/> Finalize selection
                                </button>

                                <button style={{float: "right", marginRight: "10px"}}
                                        onClick={handleRemoveFromCalculation}
                                        className="btn btn-outline-warning">
                                    Remove
                                </button>
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer>

                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">
                                                        <input type="checkbox" checked={selectAllRemove} onChange={handleSelectAllRemove}/>
                                                    </TableCell>
                                                    <TableCell data-priority="3">Part Code</TableCell>
                                                    <TableCell data-priority="3">Part Name</TableCell>
                                                    <TableCell data-priority="3">IFES Key</TableCell>
                                                    <TableCell data-priority="3">Level</TableCell>
                                                    {/*<TableCell data-priority="3">Calculate</TableCell>*/}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {refresh.slice(pageR * rowsPerPageR, pageR * rowsPerPageR + rowsPerPageR).map((part) => {
                                                    return (
                                                        <TableRow className={"partRow-" + part.id}>
                                                            <TableCell data-priority="3">
                                                                <input type="checkbox" checked={selectedRowsRemove.includes(part.id)}
                                                                       onChange={() => handleRowSelectionRemove(part.id)}/>
                                                            </TableCell>
                                                            <TableCell data-priority="3">
                                                                {part.partCode}
                                                            </TableCell>
                                                            <TableCell data-priority="3">
                                                                {part.description}
                                                            </TableCell>
                                                            <TableCell data-priority="3">
                                                                {part.ifesCode}
                                                            </TableCell>
                                                            <TableCell data-priority="3">
                                                                {part.level.description}
                                                            </TableCell>
                                                            {/*<TableCell data-priority="3">*/}
                                                            {/*    <button value={part.id}*/}
                                                            {/*            onClick={handleRemoveFromCalculation}*/}
                                                            {/*            className="btn btn-outline-warning">*/}
                                                            {/*        Remove*/}
                                                            {/*    </button>*/}
                                                            {/*</TableCell>*/}
                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                            page={pageR}
                                            count={refresh.length}
                                            onChangePage={handleChangePageR}
                                            rowsPerPage={rowsPerPageR}
                                            onChangeRowsPerPage={handleChangeRowsPerPageR}
                                        />
                                    </TableContainer>
                                </div>
                            </div>


                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )


}
export default PartBalance;






