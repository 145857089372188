//parts
import instance from "../custom-axios/axios";
import axios from "../custom-axios/axios";

const PartsService = {
    fetchParts: () => {
        return axios.get(`/parts`);
    },
    addPart: (form) => {

        return instance.post(`/parts`, form);
    },
    addSubPart: (partCode, description, ifesCode, level, project, part) => {
        return instance.post(`/parts`, {

            partCode: partCode,
            description: description,
            ifesCode: ifesCode,
            level: level,
            project: project,
            part: part
        });
    },
    editPart: (id, form) => {

        return axios.post(`/parts/edit/${id}`, form)
    },
    deletePart: (id) => {
        return instance.post(`/parts/delete/${id}`)
    },
    getPartsForProject: (id) => {
        return axios.get(`/parts/project/${id}`)
    },

    getOnePart: (id) => {
        return axios.get(`/parts/${id}`);
    },
    getSubParts: (id) => {
        return axios.get(`/parts/getSubParts/${id}`);
    },

    importPartsForValidation: () => {
        return axios.get(`/part_validation/read`);
    },

    saveImportLocally: (file) => {
        return axios.post('/part_validation/saveLocally', {file: file},)
    },

    getPartValidation: (projectId) => {
        return axios.get(`/part_validation/getByProject/${projectId}`);
    },

    setParentForPartForValidation: (id, parent_id) => {
        return axios.post(`/part_validation/addParent/${id}/${parent_id}`);
    },

    setLevelForPartForValidation: (id, level_id) => {
        return axios.post(`/part_validation/addLevel/${id}/${level_id}`);
    },

    removeParentPartValidation: (id) => {
        return axios.post(`/part_validation/removeParent/${id}`);
    },

    setConfirmedForPartForValidation: (id) => {
        return axios.post(`/part_validation/confirmPart/${id}`);
    },

    finalizeValidation: (projectId) => {
        return axios.post(`/part_validation/finalizeValidation/${projectId}`);
    },
    readPartValidation: (projectId) => {
        return axios.get(`/part_validation/import_validation_with_existing_levels/${projectId}`);
    },

    calculateBalance: (form) => {
        return axios.post(`/parts/balance`, form);
    },

    getPartForPart: (id) => {
        return axios.get(`/parts/getPart/${id}`);
    },
    getQuantityCostPerYear: (id) => {
        return axios.get(`/parts/getQuantityCostPerYear/${id}`);
    },

    getLastPartExpenseExport: () => {
        return axios.get(`/docs/downloadLastExpenseFile`);
    },

    getPartsForPartExpenses: (projectId) => {
        return axios.get(`/parts/getPartsForPartExpenses/${projectId}`);
    },


    getPartsForPartExpensesChoosePart: (projectId, role, email) => {
        return axios.get(`/parts/getPartsForPartExpensesChoosePart/${projectId}/${role}/${email}`)
    },

    setPartnerForPart: (partId, partnerId) => {
        return axios.post(`/parts/setPartnerForPart/${partId}/${partnerId}`);
    },

    exchange: (partId, yearId, body) => {
        return axios.post(`/parts/edit/${partId}/update/${yearId}`, body)
    },

    downloadLatestBalanceExport: (lastExport) => {
        return axios.get(`/docs/downloadFile/${lastExport}`);
    },

    filterBy:(projectId, value) => {
        return axios.post(`/parts/filterBy/${projectId}`, {projectId, value});
    }

}
export default PartsService;
