import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {Card, CardBody} from "reactstrap";
import "./AddProject.css";
import projectsRepository from "../../repository/projectsRepository";
import Select from "react-select"

const AddProject = (props) => {
    const [formData, updateForData] = React.useState({
        name: "",
        code: "",

    });

    const [years, setYears] = React.useState([]);
    let options = [];
    props.years.map((year) => {
        options.push({value: year.id, label: "From: " + year.fromDate + " To: " + year.toDate})
    })

    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleChangeSelect = (value, action) => {
        let update = [];
        if (value.length !== 0) {
            update = [...years];
        }
        for (var i = 0; i < value.length; i++) {
            if (!update.includes(value[i].value)) {
                update.push(value[i].value);
            }
        }

        setYears(update);
    }
    const closeSlidedAddProject = () => {
        props.closeSlidedAddProject();
        clearFields();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const name = formData.name;
        const code = formData.code;
        let pass = true;
        if (code === "" || code === undefined) {
            setRequiredCode(true);
            pass = false;
        } else {
            setRequiredCode(false);
        }
        if (name === "" || name === undefined) {
            setRequiredName(true);
            pass = false;
        } else {
            setRequiredName(false);
        }
        if (years.length === 0) {
            setRequiredYear(true);
            pass = false;
        } else {
            setRequiredYear(false);
        }
        if (pass) {
            const form = new FormData();
            form.append("name", name);
            form.append("code", code);
            form.append("yearIds", years);

            projectsRepository.addProject(form).then(() => {
                //Ways you can handle resolve
                // First is to just redirect:
                // window.location.href="/projects";
                //Second is to do it asynchronously:
                props.closeSlidedAddProject()
                props.refreshContent();
                props.showAlertMessage("You successfully added a project!");
                clearFields();
            })
        }
    }

    const clearFields = () => {
        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        })

        updateForData({
            formData: {
                name: "",
                code: "",
            }
        })
        setRequiredCode(false);
        setRequiredName(false);
        setRequiredYear(false);
    }

    const [requiredCode, setRequiredCode] = React.useState(false);
    const [requiredName, setRequiredName] = React.useState(false);
    const [requiredYear, setRequiredYear] = React.useState(false);

    return (
        <div className="container Main_add_Project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody>
                            <div className="Header header_add_project">
                                <h1>Create Project</h1>
                            </div>
                            <form>
                                <div className="row">
                                    <div id="partCode">
                                        <label htmlFor="input_Part_Code" className="label_add_project">
                                            Code
                                        </label>
                                        <div>
                                            <label style={requiredCode === false ? {display: "none"} : {}}
                                                   className="text-danger">Please fill out this field!</label>
                                        </div>
                                        <input
                                            name="code"
                                            type="text"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter code"
                                            onChange={handleChange}

                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Name
                                        </label>
                                        <div>
                                            <label style={requiredName === false ? {display: "none"} : {}}
                                                   className="text-danger">Please fill out this field!</label>
                                        </div>
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="partCode">
                                        <label htmlFor="input_Part_Code" className="label_add_project">
                                            Year
                                        </label>
                                        <div>
                                            <label style={requiredYear === false ? {display: "none"} : {}}
                                                   className="text-danger">Please select a year!</label>
                                        </div>
                                        <Select name="year" required isMulti onChange={handleChangeSelect}
                                                options={options} className="form_add_project"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <span id="createCancel">
                                        <button
                                            onClick={onFormSubmit}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="create_button"
                                        >
                                            Create
                                        </button>

                                        <button
                                            onClick={closeSlidedAddProject}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="cancel_button"
                                        >
                                            Cancel
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>

    );
};
export default AddProject;
