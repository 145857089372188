import {Card, CardBody} from "reactstrap";
import '../../assets/general.css'
import CardHeader from "@material-ui/core/CardHeader";
import {VscAdd} from "react-icons/vsc";
import {FaRegEdit} from 'react-icons/fa';
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@material-ui/core";
import React, {useEffect} from "react";
import AddRole from "./AddRole";
import RoleService from "../../repository/roleRepository";
import EditRole from "./EditRole";
import DeleteRole from "./DeleteRole";
import {Modal} from "@material-ui/core";
import {AiOutlineDelete} from "react-icons/ai";
import Alert from "react-bootstrap/Alert";


const RolesAll = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [filteredRoles, setFilteredRoles] = React.useState([]);
    const [allRoles, setAllRoles] = React.useState([]);

    const filterTable = (e) => {
        const value = e.target.value;
        if (value === null || value === "") {
            setFilteredRoles(allRoles);
        } else {
            let uniList = []
            allRoles.map(uni => {
                if (uni.name.toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)

                }
            })
            if (uniList.length > 0) {
                setFilteredRoles(uniList);
            } else {
                setFilteredRoles([""]);
            }
        }
        setPage(0);
    }
    const [alertMessage, setAlertMessage] = React.useState(false);
    const [alertMessageText, setAlertMessageText] = React.useState("");
    const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
    const showAlertMessage = (message) => {
        let a;
        setAlertMessageText(message);
        setAlertMessage(true);
        if (clearPreviousTimeout !== undefined) {
            clearTimeout(clearPreviousTimeout);
        }
        a = setTimeout(() => {
            setAlertMessage(false)
        }, 2000);
        setClearPreviousTimeout(a);
    }
    const [showAddRoleForm, setShowAddRoleForm] = React.useState(false);
    const slideAddRole = () => {

        setShowAddRoleForm(true);
        setShowEditRoleForm(false);
    }
    const closeSlidedAddRole = () => {
        setShowAddRoleForm(false);
    }
    const [showEditRoleForm, setShowEditRoleForm] = React.useState(false);
    const [selectedRole, setSelectedRole] = React.useState({});
    const slideEditRole = (e) => {
        let id = e.target.value;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.value;
        }
        RoleService.getOneRole(id).then((role) => {
            setSelectedRole(role.data);
            setShowEditRoleForm(true)
            setShowAddRoleForm(false);
        })

    }
    const closeSlidedEditRole = () => {
        setShowEditRoleForm(false);
    }
    useEffect(() => {
        if (filteredRoles.length === 0) {
            refreshContent();
        }
    })

    const refreshContent = () => {
        RoleService.fetchRoles().then((roles) => {
            if (roles.data.length !== 0) {
                setFilteredRoles(roles.data);
                setAllRoles(roles.data);
            }
        })
    }

    const DeleteRefreshContent = () => {
        RoleService.fetchRoles().then((roles) => {
            if (roles.data.length !== 0) {
                setFilteredRoles(roles.data);
                setAllRoles(roles.data);
            } else {
                setFilteredRoles([]);
                setAllRoles([]);
            }
        })
    }

    const [openDelete, setOpenDelete] = React.useState(false);
    const [id, setId] = React.useState();

    const handleOpenDelete = (e) => {
        let id = e.target.id;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.id;
        }
        setId(id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const bodyDelete = (
        <DeleteRole id={id} handleCloseDelete={handleCloseDelete} refreshContent={DeleteRefreshContent}
                    showAlertMessage={showAlertMessage}/>
    );

    return (
        <div>
            <Modal
                style={{width: "50%", margin: "auto"}}
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {bodyDelete}
            </Modal>
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded">
                        <div className="first_line">
                            <CardHeader title={"Roles"} id="list_projects"/>
                            <Alert id="alert-success" variant='success' show={alertMessage}>
                                <p>{alertMessageText}</p>
                            </Alert>
                            <button type="button" className="btn btn-outline-success" id="create"
                                    onClick={slideAddRole}>{<VscAdd id="add_icon"/>} New Role
                            </button>
                        </div>
                        <CardBody>
                            <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>
                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer id="mainTable">
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Role Name</TableCell>
                                                    <TableCell data-priority="3">Edit</TableCell>
                                                    <TableCell data-priority="3">Delete</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredRoles[0] === "" && filteredRoles.length === 1 ?
                                                    <div/> :
                                                    filteredRoles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                        return (
                                                            <TableRow className="innerRow">
                                                                <TableCell className="innerCell">{term.name}</TableCell>
                                                                <TableCell className="innerCell">
                                                                    <button className="btn btn-success"
                                                                            value={term.id} onClick={slideEditRole}>
                                                                        <i className="fa fa-pencil-square-o"/>
                                                                        Edit
                                                                    </button>
                                                                </TableCell>
                                                                <TableCell className="innerCell">
                                                                    <button className="btn btn-danger"
                                                                            id={term.id} onClick={handleOpenDelete}>
                                                                        <i className="fa fa-trash-o"/>
                                                                        Delete
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                            count={filteredRoles[0] === "" && filteredRoles.length === 1 ? 0 : filteredRoles.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                <div style={showAddRoleForm === false ? {display: "none"} : {}} className='col'>
                    <AddRole closeSlidedAddRole={closeSlidedAddRole} refreshContent={refreshContent}
                             showAlertMessage={showAlertMessage}/>
                </div>

                <div style={showEditRoleForm === false ? {display: "none"} : {}} className='col'>
                    <EditRole role={selectedRole} closeSlidedEditRole={closeSlidedEditRole}
                              refreshContent={refreshContent} showAlertMessage={showAlertMessage}/>
                </div>
            </div>
        </div>
    )


}
export default RolesAll;