import {Card, CardBody} from "reactstrap";
import React from "react";
import RoleService from "../../repository/roleRepository";


const AddRole = (props) => {

    const [formData, updateForData] = React.useState({
        name: "",

    })


    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const closeSlidedAddRole = () => {
        props.closeSlidedAddRole();
        clearFields()

    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        let pass = true;
        const name = formData.name;
        if (name === "" || name === undefined) {
            setRequiredName(true);
            pass = false;
        } else {
            setRequiredName(false);
        }
        if (pass) {
            RoleService.addRole(name).then(() => {
                //Ways you can handle resolve
                // First is to just redirect:
                // window.location.href="/projects";
                //Second is to do it asynchronously:
                props.closeSlidedAddRole()
                props.refreshContent();
                props.showAlertMessage("You successfully added a role!");
                clearFields();
            })
        }
    }
    const clearFields = () => {
        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        })
        updateForData({
            formData: {
                name: "",

            }
        })
        setRequiredName(false)
    }

    const [requiredName, setRequiredName] = React.useState(false);

    return (
        <div className="container Main_add_Project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody>
                            <div className="Header header_add_project">
                                <h1>Create Role</h1>
                            </div>
                            <form>
                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Name
                                        </label>
                                        <div>
                                            <label style={requiredName === false ? {display: "none"} : {}}
                                                   className="text-danger">Please fill out this field!</label>
                                        </div>
                                        <input
                                            name="name"
                                            type="text"
                                            className="form_add_project form-control"
                                            aria-describedby="underText"
                                            placeholder="Enter name"
                                            onChange={handleChange}
                                            value={formData.name}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <span id="createCancel">
                                        <button
                                            onClick={onFormSubmit}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="create_button"
                                        >
                                            Create
                                        </button>

                                        <button
                                            onClick={closeSlidedAddRole}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="cancel_button"
                                        >
                                            Cancel
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}
export default AddRole;