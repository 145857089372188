import React, {useEffect, useState} from 'react';
import {Card, CardBody} from "reactstrap";
import '../../assets/general.css'
import "./Key.css"
import CardHeader from "@material-ui/core/CardHeader";
import {
    TablePagination,
} from "@material-ui/core";

const Key = (props) => {
    let ifesKeys = [];
    props.QBooks.map((qbook) => {
        if (!ifesKeys.includes(qbook.ifesKey)) {
            ifesKeys.push(qbook.ifesKey);
        }
    })

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleColumns = (e) => {
        if (props.typeKeys === "ifesKeys") {
            const keyId = e.target.id;
            props.getSelectedIfesKey(keyId);
        } else {
            const keyId = e.target.id;
            props.handleKeysCombination(props.selectedIfesKey, keyId);
        }
    }

    const [filteredKeys, setFilteredKeys] = React.useState([]);
    const filterContent = (e) => {
        const value = e.target.value;
        let myProps = [];
        if (props.typeKeys === "ifesKeys") {
            myProps = ifesKeys;
        } else {
            myProps = props.qBookKeys;
        }
        if (value === null || value === "") {
            setFilteredKeys(myProps)
        } else {
            let uniList = []
            myProps.map(uni => {
                if (uni.toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)
                }
            })
            if (uniList.length > 0) {
                setFilteredKeys(uniList);
            } else {
                setFilteredKeys([""]);
            }
        }
        setPage(0);
    }

    return (

        <Card className="customCard shadow p-3 mb-5 bg-white rounded" id="mainTableKey">
            <div className="first_line_keys">
                <CardHeader
                    title={props.typeKeys === "ifesKeys" ? "Ifes Keys" : "QBook Keys - " + props.selectedIfesKey}
                    id="key_Header"/>
            </div>
            <div>
                <input className="filterField" type="text" onKeyUp={filterContent} placeholder="Filter"/>
            </div>

            <CardBody>
                {props.typeKeys === "ifesKeys" ? <div className="list_keys">

                        {filteredKeys.length > 0 ? (filteredKeys[0] === "" && filteredKeys.length === 1) ? <div/> :
                            filteredKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((key) => {
                                return (
                                    <p onClick={HandleColumns} id={key}>{key}</p>
                                )
                            }) : ifesKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((key) => {
                            return (
                                <p onClick={HandleColumns} id={key}>{key}</p>
                            )
                        })}
                    </div> :
                    <div className="list_keys">

                        {filteredKeys.length > 0 ? (filteredKeys[0] === "" && filteredKeys.length === 1) ? <div/> :
                            filteredKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((qbook) => {
                                return (
                                    <p onClick={HandleColumns} id={qbook.qbookKey}>{qbook.qbookKey}</p>
                                )
                            }) : props.qBookKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((qbook) => {
                            return (
                                <p onClick={HandleColumns} id={qbook.qbookKey}>{qbook.qbookKey}</p>
                            )
                        })}
                    </div>
                }
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 15]}
                    page={page}
                    count={props.typeKeys === "ifesKeys" ? filteredKeys.length > 0 ? filteredKeys.length : ifesKeys.length : filteredKeys.length > 0 ? filteredKeys.length : props.qBookKeys.length}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}

                />

            </CardBody>
        </Card>
    );
}
export default Key;