import axios from "../custom-axios/axios";
import instance from "../custom-axios/axios";

const LevelsService = {
    getOneLevel:(id)=>{
        return axios.get(`/levels/${id}`);
    },
    fetchLevels:()=>{
        return axios.get(`/levels`);
    },
    addLevel:(description, orderNumber)=>{
        return instance.post(`/levels`, {
            description:description,
            orderNumber:orderNumber
        })
    },
    editLevel:(id,description,orderNumber)=>{
        return axios.post(`/levels/edit/${id}`,{
            id:id,
            description:description,
            orderNumber:orderNumber
        })
    },
    deleteLevel:(id)=>{
        return instance.post(`/levels/delete/${id}`);
    },
    getLevelsWithOrderNumberGreaterThanParents:(orderNumber)=>{
        return instance.get(`/levels/orderNumberGreaterThan/${orderNumber}`);
    }
}
export default LevelsService;