import {Card, CardBody} from "reactstrap";
import React from "react";
import LevelsService from "../../repository/levelsRepository";


const DeleteLevel = props => {
    const [formData, setFormData] = React.useState({
        id: props.id,

    });

    const submitFormLevel = (e) => {

        e.preventDefault();
        const id = formData.id;
        LevelsService.deleteLevel(id).then(() => {
            props.refreshContent();
            props.handleCloseDeleteLevel();
            props.showAlertMessage("You successfully deleted a level!");
        });

    }

    const handleCloseDeleteLevel = () => {
        props.handleCloseDeleteLevel();
    }

    return (


        <div className="container">

            <div className="row mt-5">
                <div className="col mainCol">
                    <Card style={{marginTop: "10%"}}>
                        <CardBody>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <span/>
                                <h3 className="formHeader">Delete</h3>
                                <button onClick={handleCloseDeleteLevel} className="btn-close"
                                        aria-label="Close"/>
                            </div>
                            <form onSubmit={submitFormLevel}>
                                <div className="form-group" style={{display: "flex", justifyContent: "center"}}>
                                    Are you sure you want to delete the Level?


                                </div>
                                <br/>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <button type="submit" className="btn btn-outline-danger ">Delete</button>
                                    <br/>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DeleteLevel;