import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideBar from "../SideBar/SideBar";
import NavigationBar from "../NavigationBar/NavigationBar";
import {Redirect} from "react-router-dom"
import {Switch} from "react-router-dom";
import {BrowserRouter as Router, Route} from "react-router-dom"
import ProjectAll from "../Project/ProjectAll";
import {Component, useState} from "react";
import ProjectService from "../../repository/projectsRepository";
import RoleService from "../../repository/roleRepository";
import RolesAll from "../Role/RolesAll";
import Parts from "../Project/Parts";
import PartsService from "../../repository/partsRepository";
import UserService from "../../repository/userRepository";
import UsersAll from "../User/UsersAll";
import Login from "../Log_in/LogIn";
import ResetPassword from "../ResetPassword/ResetPassword";
import Register from "../Register/Register";
import LevelsService from "../../repository/levelsRepository";
import Import from "../Import/Import";
import YearsService from "../../repository/yearsRepository";
import Years from "../Years/Years";
import Levels from "../Level/Levels";
import Key from "../Mapping/Key";
import Mapping from "../Mapping/Mapping";
import PartValidation from "../PartValidation/PartValidation";
import MappingService from "../../repository/MappingRepository";
import PartBalance from "../PartBalance/PartBalance";
import setNewPassword from "../setNewPassword/setNewPassword";
import PartExpenses from "../PartExpenses/PartExpenses";
import ExpensesService from "../../repository/partExpensesRepository";
import 'font-awesome/css/font-awesome.min.css';
import ImportService from "../../repository/importRepository";
import PartnerProject from "../Partner/PartnerProject";
import PartnerService from "../../repository/partnerRepository";
import ImportsTemplatesManagement from "../ImportsTemplatesManagement/ImportsTemplatesManagement";
import ExchangeRates from "../ExchangeRate/ExchangeRates";
import ExchangeRateService from "../../repository/exchangeRateRepository";


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            parts: [],
            roles: [],
            users: [],
            levels: [],
            years: [],
            QBooks: [],
            yearsNumeric: [],
            lastExportPartExpensesName: "",
            partExpenses: [],
            optionsForPartExpenses: [],
            allExistingImports: [],
            partners: [],
            usersWithRolePartner: [],
            lastProjectStructureTemplate:"",
            lastInternalImportTemplate:"",
            lastExternalImportTemplate:"",
            exchangeRates: [],
        }
    }

    componentDidMount() {
        this.loadUsers();
        this.loadLastExportPartExpense();
        this.loadRoles();
        this.loadProjects();
        this.loadParts();
        this.loadLevels();
        this.loadYears();
        this.loadQBooks();
        this.loadPartExpenses();
        this.loadAllExistingImports();
        this.loadAllPartners();
        this.loadUserWithRolePartner();
        this.loadLastExternalImportTemplate();
        this.loadLastInternalImportTemplate();
        this.loadLastProjectStructureTemplate();
        this.loadExchangeRates();
    }

    loadExchangeRates = () => {
        ExchangeRateService.fetchExchangeRateHistory()
            .then((data) => {
                this.setState({
                    exchangeRates: data.data
                })
            });
    }


    loadProjects = () => {
        ProjectService.fetchProjects()
            .then((data) => {
                this.setState({
                    projects: data.data
                })
            });
    }
    loadParts = () => {
        PartsService.fetchParts()
            .then((data) => {
                this.setState({
                    parts: data.data
                })
            });
    }
    loadLevels = () => {
        LevelsService.fetchLevels()
            .then((data) => {
                this.setState({
                    levels: data.data
                })
            });
    }

    loadRoles = () => {
        RoleService.fetchRoles().then((data) => {
            this.setState({
                roles: data.data
            })
        })
    }

    loadUsers = () => {
        UserService.fetchUsers().then((data) => {
            this.setState({
                users: data.data
            })
        })
    }

    loadPartExpenses = () => {
        ExpensesService.fetchPartExpenses().then((data) => {
            this.setState({
                partExpenses: data.data
            })
        })

    }

    getLoggedDetails = () => {
        UserService.getLoggedDetails().then((data) => {
            localStorage.setItem('username', data.data.username);
            localStorage.setItem('email', data.data.email);
            localStorage.setItem('role', data.data.role);
            // window.location.href = '/';

        })


    }
    loadYears = () => {
        YearsService.fetchYears().then((data) => {
            this.setState({
                years: data.data
            });
            let updateYearsNumeric = [...this.state.yearsNumeric];
            for (var i = 0; i < data.data.length; i++) {
                const fromDateYear = data.data[i].fromDate.substring(0, 4);
                const toDate = data.data[i].toDate.substring(0, 4);
                if (!updateYearsNumeric.includes(fromDateYear)) {
                    updateYearsNumeric.push(fromDateYear);
                }
                if (!updateYearsNumeric.includes(toDate)) {
                    updateYearsNumeric.push(toDate);
                }
            }
            this.setState({yearsNumeric: updateYearsNumeric});
        })
    }
    loadQBooks = () => {
        MappingService.fetchQBooks().then((data) => {
            this.setState({
                QBooks: data.data
            })
        })
    }

    loadLastExportPartExpense = () => {
        PartsService.getLastPartExpenseExport().then((snapshot) => {
            this.setState({
                lastExportPartExpensesName: snapshot.data
            })
        })
    }

    loadAllExistingImports = () => {
        ImportService.getAllExistingImports().then((snapshot) => {
            this.setState({
                allExistingImports: snapshot.data
            })
        })
    }


    loadAllPartners = () => {
        PartnerService.fetchPartners().then((snapshot) => {
            this.setState({
                partners: snapshot.data
            });
        });
    }

    loadUserWithRolePartner = () => {
        UserService.getAllUsersWithRolePartner().then((snapshot) => {
            this.setState({
                usersWithRolePartner: snapshot.data
            })
        })
    }

    loadLastExternalImportTemplate = () => {
        ImportService.getLastExternalExpensesTemplate().then((snapshot)=>{
            this.setState({
                lastExternalImportTemplate:snapshot.data
            })
        })
    }

    loadLastInternalImportTemplate = () => {
        ImportService.getLastInternalExpensesTemplate().then((snapshot)=>{
            this.setState({
                lastInternalImportTemplate:snapshot.data
            })
        })
    }

    loadLastProjectStructureTemplate = () => {
        ImportService.getLastProjectStructureTemplate().then((snapshot)=>{
            this.setState({
                lastProjectStructureTemplate:snapshot.data
            })
        })
    }


    render() {

        let routes = (
            <Router>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/reset/password" exact component={ResetPassword}/>
                    <Route path="/register" exact component={Register}/>
                    <Route path="/new/password" exact component={setNewPassword}/>
                    <Redirect to="/login"/>
                </Switch>
            </Router>

        );

        let userRole = localStorage.getItem('role');

        if (userRole === "ROLE_ADMIN") {
            routes = this.adminRoutes();
        } else if (userRole === "ROLE_PARTNER") {
            routes = this.partnerRoutes();
        }

        return (
            <div style={{height: "100%"}}>
                {routes}
            </div>
        )

    }


    adminRoutes = () => {
        return (
            <Router>
                <NavigationBar/>
                <SideBar/>
                <main>

                    <Route path={"/projects"} exact render={() =>
                        <ProjectAll projects={this.state.projects}/>}/>
                    <Route path={"/parts"} exact render={() =>
                        <Parts parts={this.state.parts}/>}/>
                    <Route path={"/roles"} exact render={() =>
                        <RolesAll roles={this.state.roles}/>}/>
                    <Route path={"/users"} exact render={() =>
                        <UsersAll users={this.state.users}/>}/>
                    <Route exact path="/parts/project/:projectId?" component={Parts}/>
                    <Route path={"/import"} exact render={() =>
                        <Import projects={this.state.projects} existingImports={this.state.allExistingImports}
                                lastInternalImportTemplate={this.state.lastInternalImportTemplate} lastExternalImportTemplate={this.state.lastExternalImportTemplate}
                                lastProjectStructureTemplate={this.state.lastProjectStructureTemplate}
                        />}/>
                    <Route path={"/mapping"} exact render={() =>
                        <Mapping QBooks={this.state.QBooks}/>}/>
                    <Route path={"/years"} exact render={() =>
                        <Years years={this.state.years}/>}/>
                    <Route path={"/levels"} exact render={() =>
                        <Levels levels={this.state.levels}/>}/>
                    <Route path={"/exchange-rates"} exact render={() =>
                        <ExchangeRates exchangeRates={this.state.exchangeRates}/>}/>
                    <Route path={"/mapping/key"} exact render={() =>
                        <Key/>}/>

                    <Route path={"/validate"} exact render={() =>
                        <PartValidation projects={this.state.projects}/>}/>

                    <Route path={"/balance"} exact render={() =>
                        <PartBalance lastExport={this.state.lastExportPartExpensesName} projects={this.state.projects}
                                     years={this.state.yearsNumeric.sort()}/>}/>
                    <Route path={"/expenses"} exact render={() =>
                        <PartExpenses projects={this.state.projects}/>}/>
                    <Route path={"/partners/project"} exact render={() =>
                        <PartnerProject projects={this.state.projects} partners={this.state.partners}
                                        users={this.state.usersWithRolePartner}/>}/>
                    <Route path={"/importsTemplatesManagement"} exact render={() =>
                        <ImportsTemplatesManagement/>}/>

                </main>
            </Router>
        );
    };

    partnerRoutes = () => {
        return (
            <Router>
                <NavigationBar/>
                <SideBar/>
                <main>
                    <Route path={"/projects"} exact render={() =>
                        <ProjectAll projects={this.state.projects}/>}/>
                    <Route path={"/import"} exact render={() =>
                        <Import projects={this.state.projects} existingImports={this.state.allExistingImports}/>}/>
                    <Route path={"/expenses"} exact render={() =>
                        <PartExpenses projects={this.state.projects}/>}/>
                </main>
            </Router>
        );
    };

}

export default App;
