import {Card, CardBody} from "reactstrap";
import React from "react";
import UserService from "../../repository/userRepository";


const EditUser = (props) => {
    const [formData, updateForData] = React.useState({
        email: props.user.email,
        username: props.user.username,
        role: props.userRole.id

    })

    const handleChange = (e) => {

        updateForData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const closeSlidedEditUser = () => {
        props.closeSlidedEditUser();
        clearFields();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const username = formData.username === undefined ? props.user.username : formData.username;
        const email = formData.email === undefined ? props.user.email : formData.email;
        const role = formData.role === undefined ? props.userRole.id : formData.role;
        const id = props.user.id;
        UserService.editUser(id, email, username, role).then(() => {
            props.closeSlidedEditUser()
            props.refreshContent();
            props.showAlertMessage("You successfully edited a user!");
            clearFields();
        })
    }

    const clearFields = () => {
        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        })
        document.querySelectorAll("select").forEach((item) => {
            item.value = "";
        })
        updateForData({
            formData: {
                email: "",
                username: "",
                role: ""
            }
        })
    }

    return (
        <div className="container Main_add_Project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody>
                            <div className="Header header_add_project">
                                <h1>Edit User</h1>
                            </div>
                            <form>
                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Email
                                        </label>
                                        <input
                                            name="email"
                                            type="text"
                                            className="form_add_project form-control"
                                            aria-describedby="underText"
                                            placeholder="Enter email"
                                            onChange={handleChange}
                                            value={formData.email !== undefined ? formData.email : props.user.email}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Username
                                        </label>
                                        <input
                                            name="username"
                                            type="text"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter username"
                                            onChange={handleChange}
                                            value={formData.username !== undefined ? formData.username : props.user.username}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="label_add_project ">
                                            Role
                                        </label>
                                        <select name="role" className="form_add_project form-control"
                                                onChange={handleChange}>
                                            {props.roles.map((role) => {
                                                if (role.id === props.userRole.id) {
                                                    return (<option value={role.id} selected> {role.name}</option>)
                                                } else {
                                                    return (<option value={role.id}>{role.name}</option>)
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <span id="createCancel">
                                        <button
                                            onClick={onFormSubmit}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="create_button"
                                        >
                                            Confirm
                                        </button>

                                        <button
                                            onClick={closeSlidedEditUser}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="cancel_button"
                                        >
                                            Cancel
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default EditUser;