import instance from "../custom-axios/axios";
//role
const RoleService = {
    fetchRoles:()=>{
        return instance.get(`/roles`);
    },
    getOneRole:(id)=>{
        return instance.get(`/roles/${id}`);
    },
    addRole:(name)=>{
        return instance.post(`/roles`,{name:name});
    },
    editRole:(id,name)=>{
        return instance.post(`/roles/edit/${id}`,{id:id,name:name});
    },
    deleteRole:(id) =>{
        return instance.post(`/roles/delete/${id}`);
    }
}
export default RoleService;