import instance from "../custom-axios/axios";
import axios from "../custom-axios/axios";

const YearsService = {
    fetchYears:()=>{
        return instance.get("/years");
    },

    editYear:(id,number,fromDate,toDate)=>{
        return axios.post(`/years/edit/${id}`,{
            id:id,
            number:number,
            fromDate:fromDate,
            toDate:toDate
        })
    },
    addYear:(number,fromDate,toDate)=>{
        return instance.post(`/years`,{
            number:number,
            fromDate:fromDate,
            toDate:toDate
        })
    },
    getOneYear:(id)=>{
        return axios.get(`/years/${id}`);
    },
    deleteYear:(id)=> {
        return instance.post(`/years/delete/${id}`);
    }
}

export default YearsService;