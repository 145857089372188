import CardHeader from "@material-ui/core/CardHeader";
import React, {useEffect} from "react";
import {
    Card, Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {CardBody} from "reactstrap";
import "./PartValidation.css"
import PartsService from "../../repository/partsRepository";
import LevelsService from "../../repository/levelsRepository";
import ConfirmModal from "./ConfirmModal";
import ProjectsService from "../../repository/projectsRepository";
import {AiOutlineCheck} from "react-icons/ai";
import {BiImport} from "react-icons/bi";

const PartValidation = (props) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [fakePartsForValidation, setFakePartsForValidation] = React.useState([])
    const [levels, setLevels] = React.useState([]);
    const [triedToGetParts, setTriedToGetParts] = React.useState(false);
    if (levels.length === 0) {
        LevelsService.fetchLevels().then((snap) => {
            setLevels(snap.data);
        });
    }
    // if (fakePartsForValidation.length === 0 && triedToGetParts === false) {
    //     PartsService.getPartValidation().then((snap) => {
    //         setFakePartsForValidation(snap.data)
    //     })
    //     setTriedToGetParts(true)
    // }
    const [selectedProjectId, setSelectedProjectId] = React.useState(0);
    const [refreshed, setRefreshed] = React.useState([])
    const [allProjects, setAllProjects] = React.useState([]);
    const selectProject = async (e) => {

        const id = e.target.value;
        setSelectedProjectId(id);
        setRefreshed([]);
        await PartsService.getPartValidation(id).then((snap) => {
            setFakePartsForValidation(snap.data)
        })
    }
    const [currentChildId, setCurrentChildId] = React.useState(0);
    const selectChild = (e) => {
        const first = Date.now();
        const child_id = e.target.value;
        setCurrentChildId(child_id);
        document.querySelectorAll(".notNameCell").forEach((row) => {
            row.style.opacity = 0.5
        })
    }
    const selectParent = (e) => {
        const parent_id = e.target.id;
        document.querySelectorAll(".notNameCell").forEach(row => {
            row.style.opacity = 1
        })
        if (currentChildId !== 0) {
            PartsService.setParentForPartForValidation(parseInt(currentChildId), parseInt(parent_id)).then(() => {
                setCurrentChildId(0)
                refreshContent();
            })


        }
    }
    const getAllProjects = () => {
        ProjectsService.fetchProjects().then(p => {
            setAllProjects(p.data);
        })
    }

    useEffect(() => {
        // if (refreshed.length === 0 && selectedProjectId!==0 && fakePartsForValidation.length === 0) {
        //     refreshContent();
        // }
        if (allProjects.length === 0) {
            getAllProjects();
            refreshContent();

        }
    })


    const refreshContent = () => {
        PartsService.getPartValidation(selectedProjectId).then((snap) => {
            setRefreshed(snap.data);
        })

    }
    const rowConfirmed = (e) => {
        const part_id = e.target.value;
        PartsService.setConfirmedForPartForValidation(part_id).then(() => {
            refreshContent();
        });

    }

    const selectLevel = (e) => {
        const part_id = e.target.id;
        const level = e.target.value;
        PartsService.setLevelForPartForValidation(parseInt(part_id), parseInt(level)).then(() => {
            refreshContent();
        });

    }

    const removeParent = (e) => {
        const part_id = e.target.id;
        PartsService.removeParentPartValidation(part_id).then(() => {
            refreshContent();
        });
    }

    const finalizeValidation = () => {
        PartsService.finalizeValidation(selectedProjectId).then(() => {
            handleCloseConfirmModal();
            refreshContent();
        });
    }

    const importProjectStructure = () => {
        PartsService.readPartValidation(selectedProjectId).then(() => {
            // window.location.href = "/validate";
            handleCloseConfirmModal();
            refreshContent();
        });
    }

    const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
    const [id, setId] = React.useState();

    const handleCloseConfirmModal = () => {
        setOpenConfirmModal(false);
    };
    const handleOpenConfirmModal = (e) => {
        setId(e.target.id);
        setOpenConfirmModal(true);
    }

    const confirmModal = (
        <ConfirmModal projectId={selectedProjectId} id={id} handleCloseConfirmModal={handleCloseConfirmModal}
                      importProjectStructure={importProjectStructure} finalizeValidation={finalizeValidation}
        />
    );


    const filterTable = (e) => {
        const value = e.target.value;
        if (value === null || value === "") {
            setRefreshed(fakePartsForValidation);
        } else {
            let uniList = [];
            fakePartsForValidation.map(uni => {
                if ((uni.description !== null && uni.description.toLowerCase().includes(value.toLowerCase()))
                    || (uni.ifesCode !== null && uni.ifesCode.toLowerCase().includes(value.toLowerCase()))
                    || (uni.code !== null && uni.code.toLowerCase().includes(value.toLowerCase()))
                ) {
                    uniList.push(uni)
                }
            })
            if (uniList.length > 0) {
                setRefreshed(uniList);
            } else {
                setRefreshed([""]);
            }
        }
        setPage(0);
    }


    return (
        <div id="table">
            <Modal
                style={{width: "50%", margin: "auto"}}
                open={openConfirmModal}
                onClose={handleCloseConfirmModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">

                {confirmModal}
            </Modal>

            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded" id="mainFormAddPart">
                        <div className="first_line">
                            <CardHeader title={"Import of project structure"} id="list_projects"/>

                        </div>

                        <div className="col-6">
                            <label>Select project</label> <br/>
                            <select className="form-select" onChange={selectProject}>
                                <option value={0} selected>---------</option>
                                {allProjects.map((project) => {

                                    return (
                                        <option value={project.id}>{project.name}</option>
                                    )

                                })}
                            </select>
                        </div>

                        <div style={{display: "flex", float: "right", alignItems: "center"}}>
                            <button style={{marginRight: 10}} className="btn btn-lg btn-outline-success"
                                    id="importProjectStructure" onClick={handleOpenConfirmModal}><BiImport/> Import
                                project structure
                            </button>
                        </div>
                        <div style={{display: "flex", float: "right", alignItems: "center"}}>
                            <button style={{marginRight: 10}} className="btn btn-lg btn-outline-danger"
                                    id="finalizeValidation" onClick={handleOpenConfirmModal}><AiOutlineCheck/> Finalize
                                validation
                            </button>
                        </div>
                        <br/>
                        <CardBody>
                            <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>
                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>

                                                    <TableCell data-priority="3">Part Code</TableCell>
                                                    <TableCell data-priority="3">Part Id</TableCell>
                                                    <TableCell data-priority="3">Part Name</TableCell>
                                                    <TableCell data-priority="3">IFES Key</TableCell>
                                                    <TableCell data-priority="3">Level</TableCell>
                                                    <TableCell data-priority="3">Parent</TableCell>
                                                    <TableCell data-priority="3">Confirm</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {refreshed.length > 0 ? refreshed.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((part) => {
                                                    return (
                                                        <TableRow id={part.id} className="validationRow"
                                                                  style={part.confirmed === true ? {opacity: "0.5"} : {}}>
                                                            <TableCell className="notNameCell">
                                                                {part.code}
                                                            </TableCell>
                                                            <TableCell className="notNameCell">
                                                                {part.id}
                                                            </TableCell>
                                                            <TableCell id={part.id} className="nameCell"
                                                                       onClick={selectParent}>
                                                                {part.description}
                                                            </TableCell>
                                                            <TableCell className="notNameCell">
                                                                {part.ifesKey}
                                                            </TableCell>
                                                            <TableCell className="notNameCell">
                                                                <select onChange={selectLevel} id={part.id}
                                                                        className="form-control">

                                                                    {part.levelId === null ? <option selected
                                                                                                     value={0}>---------</option> : ''}
                                                                    {levels.map((level) => {

                                                                        return (
                                                                            <option value={level.id}
                                                                                    selected={part.levelId !== null ? level.id === part.levelId : false}>{level.description}</option>
                                                                        )
                                                                    })}

                                                                </select>
                                                            </TableCell>
                                                            <TableCell className="notNameCell">
                                                                {part.parentId === null ?
                                                                    <button className="btn btn-outline-success"
                                                                            onClick={selectChild} value={part.id}>Select
                                                                    </button> :
                                                                    <div className="parentIdDiv" id={part.id}
                                                                         onClick={removeParent}>
                                                                        {part.parentId}</div>
                                                                }

                                                            </TableCell>

                                                            <TableCell className="notNameCell">
                                                                {part.confirmed !== true ?
                                                                    <button onClick={rowConfirmed} value={part.id}
                                                                            className="btn btn-outline-success">Confirm
                                                                    </button> :
                                                                    <button onClick={rowConfirmed} value={part.id}
                                                                            style={{width: 82.7}}
                                                                            className="btn btn-outline-warning">Undo
                                                                    </button>
                                                                }
                                                            </TableCell>

                                                        </TableRow>)
                                                }) : fakePartsForValidation.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((part) => {
                                                    return (
                                                        <TableRow id={part.id} className="validationRow"
                                                                  style={part.confirmed === true ? {opacity: "0.5"} : {}}
                                                                  onClick={selectParent}>
                                                            <TableCell className="notNameCell">
                                                                {part.code}
                                                            </TableCell>
                                                            <TableCell className="notNameCell">
                                                                {part.id}
                                                            </TableCell>
                                                            <TableCell id={part.id} className="nameCell"
                                                                       onClick={selectParent}>
                                                                {part.description}
                                                            </TableCell>
                                                            <TableCell className="notNameCell">
                                                                {part.ifesKey}
                                                            </TableCell>
                                                            <TableCell className="notNameCell">
                                                                <select id={part.id} onChange={selectLevel}
                                                                        className="form-control">
                                                                    {part.levelId === null ? <option selected
                                                                                                     value={0}>---------</option> : ''}
                                                                    {levels.map((level) => {

                                                                        return (
                                                                            <option value={level.id}
                                                                                    selected={part.levelId !== null ? level.id === part.levelId : false}>{level.description}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </TableCell>
                                                            <TableCell className="notNameCell">
                                                                {part.parentId === null ?
                                                                    <button className="btn btn-outline-success"
                                                                            onClick={selectChild} value={part.id}>Select
                                                                    </button> :
                                                                    <div className="parentIdDiv" id={part.id}
                                                                         onClick={removeParent}>
                                                                        {part.parentId}</div>
                                                                }
                                                            </TableCell>

                                                            <TableCell className="notNameCell">
                                                                {part.confirmed !== true ?
                                                                    <button onClick={rowConfirmed} value={part.id}
                                                                            className="btn btn-outline-success">Confirm
                                                                    </button> :
                                                                    <button onClick={rowConfirmed} value={part.id}
                                                                            style={{width: 82.7}}
                                                                            className="btn btn-outline-warning">Undo
                                                                    </button>
                                                                }
                                                            </TableCell>

                                                        </TableRow>)
                                                })}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                            page={page}
                                            count={refreshed.length > 0 ? refreshed.length : fakePartsForValidation.length}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}
export default PartValidation