import {Card, CardBody} from "reactstrap";
import React from "react";
import YearsService from "../../repository/yearsRepository";


const DeleteYear = props => {
    const [formData] = React.useState({
        id: props.id,
    });
    
    const submitFormYear = (e) => {
        e.preventDefault();
        const id = formData.id;
        YearsService.deleteYear(id).then(() => {
            props.refreshContent();
            props.handleCloseDeleteYear();
            props.showAlertMessage("You successfully deleted a year!");
        });

    }

    const handleCloseDeleteYear = () => {
        props.handleCloseDeleteYear();
    }

    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card style={{marginTop: "10%"}}>
                        <CardBody>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <span/>
                                <h3 className="formHeader">Delete</h3>
                                <button onClick={handleCloseDeleteYear} className="btn-close"
                                        aria-label="Close"/>
                            </div>
                            <form onSubmit={submitFormYear}>
                                <div className="form-group" style={{display: "flex", justifyContent: "center"}}>
                                    Are you sure you want to delete this Year?
                                </div>
                                <br/>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <button type="submit" className="btn btn-outline-danger ">Delete</button>
                                    <br/>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DeleteYear;