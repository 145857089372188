import axios from "../custom-axios/axios";

const ImportService = {

    createImport:(form)=>{
        return axios.post(`/imports/create`, form);
    },

    createImportTemplate:(form)=>{
        return axios.post(`/imports/createTemplate`, form);
    },

    createImportDocs:(data)=>{
        return axios.post(`/docs/uploadFiles`);
    },

    getAllExistingImports:()=>{
        return axios.get(`/imports/existingImports`);
    },

    getLastInternalExpensesTemplate:()=>{
        return axios.get(`/imports/getLastInternalExpensesTemplate`);
    },
    getLastExternalExpensesTemplate:()=>{
        return axios.get(`/imports/getLastExternalExpensesTemplate`);
    },
    getLastProjectStructureTemplate:()=>{
        return axios.get(`/imports/getLastProjectStructureTemplate`);
    },




}
export default ImportService;
