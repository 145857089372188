import {Card, CardBody} from "reactstrap";
import React from "react";
import ProjectsService from "../../repository/projectsRepository";

const DeleteProject = props => {
    const [formData] = React.useState({
        id: props.id,
    });

    const submitFormProject = (e) => {
        e.preventDefault();
        const id = formData.id;
        ProjectsService.deleteProject(id).then(() => {
            props.refreshContent();
            props.handleCloseDeleteProject();
            props.showAlertMessage("You successfully deleted a project!");
        });

    }

    const handleCloseDeleteProject = () => {
        props.handleCloseDeleteProject();
    }

    return (

        <div className="container">
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card style={{marginTop: "10%"}}>
                        <CardBody>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <span/>
                                <h3 className="formHeader">Delete</h3>
                                <button onClick={handleCloseDeleteProject}
                                        className="btn-close" aria-label="Close"/>
                            </div>
                            <form onSubmit={submitFormProject}>
                                <div className="form-group" style={{display: "flex", justifyContent: "center"}}>
                                    Are you sure you want to delete this Project?
                                </div>
                                <br/>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <button type="submit" className="btn btn-outline-danger ">Delete</button>
                                    <br/>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DeleteProject;