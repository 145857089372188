import React, {useEffect} from 'react';
import {Card, CardBody} from "reactstrap";
import '../../assets/general.css'
import './ProjectAll.css'
import CardHeader from "@material-ui/core/CardHeader";
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {VscAdd} from 'react-icons/vsc';
import {FaRegEdit} from 'react-icons/fa';
import AddProject from "./AddProject";
import ProjectsService from "../../repository/projectsRepository";
import EditProject from "./EditProject";
import PartsService from "../../repository/partsRepository";
import {useHistory} from "react-router-dom";
import DeleteProject from './DeleteProject';
import Alert from "react-bootstrap/Alert";
import YearsService from "../../repository/yearsRepository";
import {AiOutlineDelete} from 'react-icons/ai';


const ProjectAll = props => {
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [showAddProjectForm, setShowAddProjectForm] = React.useState(false);
    const [showEditProjectForm, setShowEditProjectForm] = React.useState(false);
    const [filteredProjects, setFilteredProjects] = React.useState([]);
    const [allProjects, setAllProjects] = React.useState([]);
    const [alertMessage, setAlertMessage] = React.useState(false);
    const [alertMessageText, setAlertMessageText] = React.useState("");
    const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
    const showAlertMessage = (message) => {
        let a;
        setAlertMessageText(message);
        setAlertMessage(true);
        if (clearPreviousTimeout !== undefined) {
            clearTimeout(clearPreviousTimeout);
        }
        a = setTimeout(() => {
            setAlertMessage(false)
        }, 2000);
        setClearPreviousTimeout(a);
    }

    const filterTable = (e) => {
        const value = e.target.value;
        if (value === null || value === "") {
            setFilteredProjects(allProjects);
        } else {
            let uniList = []
            allProjects.map(uni => {
                if (uni.name.toLowerCase().includes(value.toLowerCase())
                    || uni.code.toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)
                }
            })
            if (uniList.length > 0) {
                setFilteredProjects(uniList);
            } else {
                setFilteredProjects([""]);
            }
        }
        setPage(0);

    }
    const [years, setYears] = React.useState([]);
    const slideAddProject = () => {
        YearsService.fetchYears().then((year) => {
            setYears(year.data);
        })
        setShowAddProjectForm(true);
        setShowEditProjectForm(false);
    }

    const closeSlidedAddProject = () => {
        setShowAddProjectForm(false);
    }

    useEffect(() => {
        if (filteredProjects.length === 0) {
            refreshContent();
        }
    })

    const refreshContent = () => {
        ProjectsService.fetchProjects().then((projects) => {
            if (projects.data.length !== 0) {
                setFilteredProjects(projects.data);
                setAllProjects(projects.data);
            }
        })
    }

    const deleteRefreshContent = () => {
        ProjectsService.fetchProjects().then((projects) => {
            if (projects.data.length !== 0) {
                setFilteredProjects(projects.data);
                setAllProjects(projects.data);
            } else {
                setFilteredProjects([])
                setAllProjects([]);
            }
        })
    }

    const [selectedProject, setSelectedProject] = React.useState({});
    const slideEditProject = async (e) => {
        let id = e.target.value;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.value;
        }
        let projectYears = [];
        await ProjectsService.getProjectYears(id).then((projectYearsSnapshot)=>{
            projectYearsSnapshot.data.forEach((snp)=>{
                if(!projectYears.includes(snp.year.id)){
                    projectYears.push(snp.year.id);
                }
            })
        })
       await YearsService.fetchYears().then((year) => {
           let yearData = year.data;
           yearData = yearData.filter(item=>!projectYears.includes(item.id));
           setYears(yearData);
           })


       await ProjectsService.getOneProject(id).then((project) => {
            setSelectedProject(project.data);
            setShowEditProjectForm(true);
            setShowAddProjectForm(false);
        })
    }

    const closeSlidedEditProject = () => {
        setShowEditProjectForm(false);
    }

    const goToProjectParts = async (e) => {
        const id = e.target.id;
        const name = e.target.name;
        await PartsService.getPartsForProject(id).then((parts) => {
            let partsData = parts.data;
            history.push({
                pathname: "/parts/project/" + id,
                state: {
                    parts: partsData,
                    projectId: id,
                    projectName: name
                }
            })
        })
    }

    const [openDeleteProject, setOpenDeleteProject] = React.useState(false);
    const [id, setId] = React.useState();

    const handleOpenDeleteProject = (e) => {
        let id = e.target.id;
        if (id === undefined || id === null || id === "") {
            id = e.target.parentNode.id;
        }
        setId(id);
        setOpenDeleteProject(true);
    };

    const handleCloseDeleteProject = () => {
        setOpenDeleteProject(false);
    };

    const projectDelete = (
        <DeleteProject id={id} handleCloseDeleteProject={handleCloseDeleteProject} refreshContent={deleteRefreshContent}
                       showAlertMessage={showAlertMessage}/>
    );

    return (
        <div id="table">
            <Modal
                style={{width: "50%", margin: "auto"}}
                open={openDeleteProject}
                onClose={handleCloseDeleteProject}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {projectDelete}
            </Modal>
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard shadow p-3 mb-5 bg-white rounded">
                        <div className="first_line">
                            <CardHeader title={"Projects"} id="list_projects"/>
                            <Alert id="alert-success" variant='success' show={alertMessage}>
                                <p>{alertMessageText}</p>
                            </Alert>
                            <button type="button" className="btn btn-outline-success" id="create"
                                    onClick={slideAddProject}>{<VscAdd id="add_icon"/>} New Project
                            </button>
                        </div>
                        <CardBody>
                            <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>
                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer id="mainTable">
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Project Code</TableCell>
                                                    <TableCell data-priority="3">Project Name</TableCell>
                                                    <TableCell data-priority="3">Edit</TableCell>
                                                    <TableCell data-priority="3">Delete</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredProjects[0] === "" && filteredProjects.length === 1 ?
                                                    <div/> :
                                                    filteredProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                        return (
                                                            <TableRow className="innerRow">
                                                                <TableCell className="innerCell">{term.code}</TableCell>
                                                                <TableCell className="innerCell">
                                                                    <a id={term.id} name={term.name}
                                                                       onClick={goToProjectParts}
                                                                       className="link_project historyAnchor">{term.name}
                                                                    </a>
                                                                </TableCell>
                                                                <TableCell className="innerCell">
                                                                    <button className="btn btn-success"
                                                                            onClick={slideEditProject} value={term.id}>
                                                                        <i className="fa fa-pencil-square-o"/>
                                                                        Edit
                                                                    </button>
                                                                </TableCell>
                                                                <TableCell className="innerCell">
                                                                    <button className="btn btn-danger"
                                                                            id={term.id}
                                                                            onClick={handleOpenDeleteProject}>
                                                                        <i className="fa fa-trash-o"/>
                                                                        Delete
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                                            count={filteredProjects[0] === "" && filteredProjects.length === 1 ? 0 : filteredProjects.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div style={showAddProjectForm === false ? {display: "none"} : {}} className='col'>
                    <AddProject years={years} closeSlidedAddProject={closeSlidedAddProject}
                                refreshContent={refreshContent} showAlertMessage={showAlertMessage}/>
                </div>
                <div style={showEditProjectForm === false ? {display: "none"} : {}} className='col'>
                    <EditProject years={years} project={selectedProject} closeSlidedEditProject={closeSlidedEditProject}
                                 refreshContent={refreshContent} showAlertMessage={showAlertMessage}/>
                </div>
            </div>
        </div>
    );
};


export default ProjectAll;