import {Card, CardBody} from "reactstrap";
import {useHistory} from "react-router-dom";
import React from "react";
import partsRepository from "../../repository/partsRepository";
import {RiExchangeDollarLine} from 'react-icons/ri'
import {Modal} from "@material-ui/core";
import Exchange from "../Exchange/Exchange";

const EditPart = (props) => {
    const history = useHistory();
    const [formData, updateForData] = React.useState({...props.parts})
    const [exchangedValue, setExchangedValue] = React.useState("")

    React.useEffect(() => {
        updateForData(props.parts);
    }, [props.parts]);


    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const closeSlidedEditPart = () => {
        props.closeSlidedEditPart();
        // clearFields();
    }
    const onFormSubmit = (e) => {
        e.preventDefault();
        const id = formData.id === undefined ? props.parts.id : formData.id;
        const description = formData.description === undefined ? props.parts.description : formData.description;
        const partCode = formData.partCode === undefined ? props.parts.partCode : formData.partCode;
        const ifesCode = formData.ifesCode === undefined ? props.parts.ifesCode : formData.ifesCode;
        const levelId = formData.level === undefined ? props.editingPartLevel.id : formData.level.id;
        const projectId = props.project.id;
        const price = formData.priceUnit === undefined ? props.parts.priceUnit : formData.priceUnit;
        const quantity = formData.quantity === undefined ? props.parts.quantity : formData.quantity;
        const unit = formData.unit === undefined ? props.parts.unit : formData.unit;
        const form = new FormData();
        form.append("id", props.parts.id);
        form.append("description", description);
        form.append("code", partCode);
        form.append("ifesKey", ifesCode);
        form.append("levelId", levelId);
        form.append("projectId", projectId);
        form.append("price", price);
        form.append("quantity", quantity);
        form.append("unit", unit);
        form.append("yearsDetails", JSON.stringify(yearsDetails));
        form.append("parentId", null);


        partsRepository.editPart(props.parts.id, form).then(() => {
            props.refreshContent(props.project.id, props.parts.id);
            props.closeSlidedEditPart();
            props.showAlertMessage("You successfully edited a part!");
            // window.location.href = "/parts/project/" + props.project.id
            // clearFields();
        });
    }


    const clearFields = () => {
        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        })
        updateForData({
            formData: {
                partCode: "",
                description: "",
                ifesCode: "",
                level: null
            }
        })
    }
    const [yearsDetails, setYearsDetails] = React.useState({})

    const handleYearDetailsChange = (e) => {
        const val = e.target.value;
        const theAtt = e.target.name.split("-")[0];
        const theId = e.target.name.split("-")[1];
        let yearDetailsUpdated = yearsDetails;
        if (theId in yearDetailsUpdated) {
            let obj = yearsDetails[theId];
            obj[theAtt] = val;
            setYearsDetails(yearDetailsUpdated);
        } else {
            yearDetailsUpdated[theId] = {"unit": "", "price": null, "quantity": null};
            let obj = yearsDetails[theId];
            obj[theAtt] = val;
            setYearsDetails(yearDetailsUpdated);
        }
    }

    const [openExchangeModal, setOpenExchangeModal] = React.useState(false)
    const [year, setYear] = React.useState()
    const exchange = (year, e) => {
        setYear(year)
        e.preventDefault()
        setOpenExchangeModal(true)
    }
    const closeExchange = () => {
        setOpenExchangeModal(false)
    }

    return (
        <div className="container Main_add_Project">
            <Modal
                style={{width: "50%", margin: "auto"}}
                open={openExchangeModal}
                onClose={closeExchange}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <Exchange closeExchange={closeExchange} partId={props.parts.id}
                          year={year} unit={props.parts.unit} closeEditPart={closeSlidedEditPart}/>
            </Modal>
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody id="mainFormAddPart">
                            <div className="Header header_add_project">
                                <h1>Edit Part</h1>
                            </div>
                            <form>
                                <div className="row">
                                    <div id="partCode">
                                        <label htmlFor="input_Part_Code" className="label_add_project">
                                            Part Code
                                        </label>
                                        <input
                                            id="code"
                                            name="partCode"
                                            type="text"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter part code"
                                            onChange={handleChange}
                                            value={formData.partCode !== null ? formData.partCode : ""}

                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="partName">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            Part Name
                                        </label>
                                        <textarea
                                            name="description"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter part name"
                                            onChange={handleChange}
                                            value={formData.description !== null ? formData.description : ""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="ifes_Key">
                                        <label htmlFor="input_Part_Key" className="label_add_project">
                                            IFES Key
                                        </label>
                                        <input
                                            name="ifesCode"
                                            type="text"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter ifes key"
                                            onChange={handleChange}
                                            value={formData.ifesCode !== null ? formData.ifesCode : ""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="level">
                                        <label htmlFor="input_Part_Level" className="label_add_project">
                                            Level
                                        </label>
                                        <select name="level" className="form_add_project form-control"
                                                onChange={handleChange}>
                                            {props.levels.map((level) => {
                                                if (props.editingPartLevel.id === level.id) {
                                                    return (<option value={level.id}
                                                                    selected>{level.description}</option>)
                                                } else {
                                                    return (<option value={level.id}>{level.description}</option>)
                                                }
                                            })}
                                        </select>
                                    </div>

                                    <div>
                                        <div className="row mt-5">
                                            <h5>Budget information:</h5>
                                            <div className="col mt-2">
                                                <input name="unit" className="field" placeholder="Unit"
                                                       onChange={handleChange}
                                                       value={formData.unit !== null ? formData.unit : ""}/>
                                            </div>
                                            <div className="col mt-2">
                                                <input name="priceUnit" className="field" placeholder="Price per unit"
                                                       onChange={handleChange}
                                                       value={formData.priceUnit !== null ? formData.priceUnit : ""}/>
                                            </div>
                                            <div className="col mt-2">
                                                <input name="quantity" className="field" placeholder="Quantity"
                                                       onChange={handleChange}
                                                       value={formData.quantity !== null ? formData.quantity : ""}/>
                                            </div>
                                        </div>

                                        <div className="mt-5 mb-5" id="perYearInformationAddPart">
                                            <h5>Budget per year information:</h5>
                                            {props.years.map((year) => {
                                                return (
                                                    <div className="row">
                                                        <h6 className="mt-2">{year.year.fromDate + " to " + year.year.toDate}
                                                            {/*<button className="btn" id="edit_part_exchange"*/}
                                                            {/*        onClick={(e) => exchange(year, e)}>*/}
                                                            {/*    <RiExchangeDollarLine size={24}/>*/}
                                                            {/*</button>*/}
                                                        </h6>
                                                        <div className="col mt-2">
                                                            <input onChange={handleYearDetailsChange}
                                                                   name={"unit-" + year.year.id} className="field"
                                                                   placeholder="Unit"
                                                                   value={formData.unit !== null ? formData.unit : ""}/>
                                                        </div>
                                                        <div className="col mt-2">
                                                            <input onChange={handleYearDetailsChange}
                                                                   name={"price-" + year.year.id} className="field"
                                                                   placeholder="Total price for period"
                                                                   defaultValue={year.existing !== null && year.existing !== undefined ? year.existing.price : ""}

                                                            />
                                                        </div>
                                                        <div className="col mt-2">
                                                            <input onChange={handleYearDetailsChange}
                                                                   name={"quantity-" + year.year.id} className="field"
                                                                   placeholder="Quantity"
                                                                   defaultValue={year.existing !== null && year.existing !== undefined ? year.existing.quantity : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>


                                </div>

                                <div className="row">
                                    <span id="createCancel">
                                        <button
                                            onClick={onFormSubmit}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="create_button"
                                        >
                                            Confirm
                                        </button>

                                        <button
                                            onClick={closeSlidedEditPart}
                                            type="button"
                                            className="btn btn-primary btn-small btn_add_part"
                                            id="cancel_button"
                                        >
                                            Cancel
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default EditPart;