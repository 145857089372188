import {Card, CardBody} from "reactstrap";
import React from "react";
import yearsRepository from "../../repository/yearsRepository";

const EditYear = (props) => {
    const [formData, updateForData] = React.useState({
        id: props.year.id,
        number: props.year.number,
        fromDate: props.year.fromDate,
        toDate: props.year.toDate


    })

    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const closeSlidedEditYear = () => {
        props.closeSlidedEditYear();
        clearFields();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const id = formData.id === undefined ? props.year.id : formData.id;
        const number = formData.number === undefined ? props.year.number : formData.number;
        const fromDate = formData.fromDate === undefined ? props.year.fromDate : formData.fromDate;
        const toDate = formData.toDate === undefined ? props.year.toDate : formData.toDate;
        yearsRepository.editYear(id, number, fromDate, toDate).then(() => {
            //Ways you can handle resolve
            // First is to just redirect:
            props.closeSlidedEditYear();
            props.refreshContent();
            props.showAlertMessage("You successfully edited a year!");
            clearFields();
            //Second is to do it asynchronously:
            // props.closeSlidedAddProject()
            // props.refreshContent();
        })
    }

    const clearFields = () => {
        document.querySelectorAll("input").forEach((item) => {
            item.value = "";
        })
        updateForData({
            formData: {
                number: "",
                fromDate: "",
                toDate: ""
            }
        })
    }

    return (
        <div className="container Main_edit_project">
            <div className="row">
                <div className="col mainCol">
                    <Card className="add_project_card shadow p-3 mb-5 bg-white rounded">
                        <CardBody>
                            <div className="Header header_add_project">
                                <h1>Edit Year</h1>
                            </div>
                            <form>
                                <div className="row">
                                    <div id="number">
                                        <label htmlFor="input_Part_Code" className="label_add_project">
                                            Number
                                        </label>
                                        <input
                                            name="number"
                                            type="text"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter number"
                                            onChange={handleChange}
                                            value={formData.number !== undefined ? formData.number : props.year.number}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="fromDate">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            From date
                                        </label>
                                        <input
                                            name="fromDate"
                                            type="date"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter date"
                                            onChange={handleChange}
                                            value={formData.fromDate !== undefined ? formData.fromDate : props.year.fromDate}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="toDate">
                                        <label htmlFor="input_Part_Name" className="label_add_project">
                                            To date
                                        </label>
                                        <input
                                            name="toDate"
                                            type="date"
                                            className="form-control form_add_project"
                                            aria-describedby="underText"
                                            placeholder="Enter date"
                                            onChange={handleChange}
                                            value={formData.toDate !== undefined ? formData.toDate : props.year.toDate}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <span id="createCancel">
                                    <button
                                        onClick={onFormSubmit}
                                        type="button"
                                        className="btn btn-primary btn-small btn_add_part"
                                        id="create_button"
                                    >
                                        Confirm
                                    </button>

                                    <button
                                        onClick={closeSlidedEditYear}
                                        type="button"
                                        className="btn btn-primary btn-small btn_add_part"
                                        id="cancel_button"
                                    >
                                        Cancel
                                    </button>
                                    </span>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default EditYear;
