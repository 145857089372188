import instance from "../custom-axios/axios";
import axios from "../custom-axios/axios";

const ExchangeRateService = {
    fetchExchangeRateHistory: () => {
        return instance.get("/exchange_rates");
    },

    getLastExchangeRate: () => {
        return instance.get("/exchange_rates/getLast");
    },

    createNewExchangeRate: (dateFrom, dateTo, chfToUsdExchangeRate) => {
        return axios.post("/exchange_rates/create", {
            dateFrom: dateFrom,
            dateTo: dateTo,
            chfToUsdExchangeRate: chfToUsdExchangeRate
        })
    },

    editExchangeRate:(id,dateFrom,dateTo,chfToUsdExchangeRate)=>{
        return axios.post(`/exchange_rates/edit/${id}`,{
            id:id,
            dateFrom:dateFrom,
            dateTo:dateTo,
            chfToUsdExchangeRate:chfToUsdExchangeRate
        })
    },

    getOneExchangeRate:(id) => {
        return axios.get(`/exchange_rates/${id}`);
    },

    deleteExchangeRate:(id) => {
        return instance.post(`/exchange_rates/delete/${id}`)
    },

    getLastExchangeRateValueByDate: (dateFrom) => {
        return axios.post("/exchange_rates/getLastExchangeRateValueByDate", {
            dateFrom: dateFrom
        })
    }
};

export default ExchangeRateService;